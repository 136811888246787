import { Component, OnInit, TemplateRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { CustodyAccountProfile, GOResponse, FormModel, Transaction, FormControls, AccountDetail, CustodyAccountVM } from '../../models/app.model';
import { AdminService } from '../../services/admin.service';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { ConfirmationDialogComponent } from '../../common/confirmation-dialog/confirmation-dialog.component';
import { DecimalPipe } from '@angular/common';
import { PaginationInstance } from 'ngx-pagination';
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';

@Component({
  selector: 'app-custody-account',
  templateUrl: './custody-account.component.html',
  styleUrls: ['./custody-account.component.css'],
  providers: [DecimalPipe]
})
export class CustodyAccountComponent implements OnInit {
  isCollapsed:boolean=false;

  TransferModalRef:BsModalRef
  account_id:string;
  acc_profile: CustodyAccountProfile;
  TransactionForm:FormGroup;
TransferFormConfig:FormModel<Transaction>;
  formControls: FormControls<Transaction>;
  isSubmitted:boolean=false;
  secondModalOpen: boolean;
  modalRef: BsModalRef;
  init_message: string;
  custody_accounts:CustodyAccountVM[]=[];
  is_superadmin: boolean=false;
  isPhone: boolean;
;
  public configSub: PaginationInstance = {
    id: 'accounts',
    itemsPerPage: 25,
    currentPage: 1
  };
  constructor(private modalService: BsModalService, private route:ActivatedRoute,private api:AdminService, private fb:FormBuilder,
    private _decimalPipe: DecimalPipe,private breakpointObserver:BreakpointObserver) {
      //  console.log(this.route)
      this.breakpointObserver.observe([
        Breakpoints.XSmall,
        Breakpoints.Small,
        Breakpoints.Medium,
        Breakpoints.Large,
        Breakpoints.XLarge
      ]).subscribe( (state: BreakpointState) => {
        //console.log(state)
        if(state.breakpoints[Breakpoints.Small] || state.breakpoints[Breakpoints.XSmall]){
             this.isPhone=true;
        }else{
          this.isPhone=false;
        }
      });

   }
  ngOnInit(): void {
    this.route.params.subscribe(x=>{
     this.account_id= x['id'];
     this.api.GetAccountDetail(this.account_id).subscribe((x:GOResponse<CustodyAccountProfile>)=>{
       this.acc_profile=x.data;
       this.is_superadmin=x?.is_superadmin
     })
    })
    
  }
  collapsed(event: any): void {
    // console.log(event);
  }
  
    expanded(event: any): void {
      // console.log(event);
    }
    TransferCancelled(){
      this.TransactionForm= null;
  this.TransferModalRef.hide();
    }
    TransferMoney(template: TemplateRef<any>){   
      this.TransferFormConfig={
        account:[''],
        amount:[0,[Validators.required,Validators.min(1)]],
        comments:['', Validators.required],
        platform:['System'],
        source:['Admin Portal'],
        type:['Custody Account Inter Transfer'],
        agree:[false],
        to:['',Validators.required],
        from:[this.account_id,Validators.required]
      }
      this.api.GetCustodyAccounts().subscribe((x:GOResponse<CustodyAccountVM[]>)=>{
        this.custody_accounts=x.data.filter(x=>x.currency==this.acc_profile.account_profile.currency&&x.id!=this.acc_profile.account_profile.id);
              })
      this.TransactionForm=this.fb.group(this.TransferFormConfig);
      this.formControls=this.TransactionForm?.controls as FormControls<Transaction>;
      this.TransferModalRef= this.modalService.show(template,{class:'modal-lg'})
      //document.querySelector('nav-tabs').classList.add('nav-justified');
        }
        InitiateAccountTopUp(template: TemplateRef<any>){
          this.TransferFormConfig={
            account:[this.account_id,Validators.required],
            amount:[0,[Validators.required,Validators.min(1)]],
            comments:['', Validators.required],
            platform:['Cash'],
            source:['Admin Portal'],
            type:['Account Topup'],
            agree:[false],
            phone:[null]
          }
          
          this.TransactionForm=this.fb.group(this.TransferFormConfig);
          this.formControls=this.TransactionForm?.controls as FormControls<Transaction>;
          this.TransferModalRef= this.modalService.show(template)
        }
Submit(){
  this.secondModalOpen=true;
  this.modalRef = this.modalService.show(ConfirmationDialogComponent, {
    class:'second_modal',
    keyboard:false,
    
    initialState: {
     
      prompt:this.formControls.type.value=='Account Topup'?`Are you sure you want to submit this Cash ${this.formControls.type.value} of <br> <b>${this.acc_profile.account_profile.currency} ${this._decimalPipe.transform(this.formControls.amount.value)}</b> to the ${this.acc_profile.account_profile.account_name}?`:`Are you sure you want to make a ${this.formControls.type.value} of <br> <b>${this.acc_profile.account_profile.currency} ${this._decimalPipe.transform(this.formControls.amount.value)}</b> <br> from ${this.acc_profile.account_profile.account_name} to ${this.custody_accounts.filter(x=>x.id==this.formControls?.to.value)[0]?.account_name}?`,
      callback: (result) => {
       if (result.confirmation == 'yes'){
this.api.RecordTopUp(this.TransactionForm.value).subscribe(x=>{

  this.TransferModalRef.hide();
  this.TransactionForm=null;
  this.secondModalOpen=false;
},(e:GOResponse<any>)=>{this.init_message=e.error.message;this.secondModalOpen=false;})
}
if (result.confirmation == 'no') {
  this.secondModalOpen=false;
}
}
},
ignoreBackdropClick:true
})
}

}
