import { Component, OnInit } from '@angular/core';
import { ReportFilter } from '../../models/app.model';
import { PrintReportService } from '../../services/print-report.service';

@Component({
  selector: 'app-payments-due-report',
  templateUrl: './payments-due-report.component.html',
  styleUrls: ['./payments-due-report.component.css']
})
export class PaymentsDueReportComponent implements OnInit {
  reportData:any;
  filter:ReportFilter;
  constructor( private printService:PrintReportService) { 

    this.reportData=printService.printTemplate.response;
    console.log("missed report",this.reportData)
    this.filter=this.printService.printTemplate.filter;
    this.printService.onDataReady();
//this.data= this.data_response.data;
  }

  ngOnInit(): void {
  }

}
