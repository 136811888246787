import { Component, OnInit } from '@angular/core';
import { ReportFilter } from '../../models/app.model';
import { PrintReportService } from '../../services/print-report.service';

@Component({
  selector: 'app-borrowers-report',
  templateUrl: './borrowers-report.component.html',
  styleUrls: ['./borrowers-report.component.css']
})
export class BorrowersReportComponent implements OnInit {
  reportData:any;
  filter:ReportFilter;
  constructor( private printService:PrintReportService) { 

    this.reportData=printService.printTemplate.response;
    this.filter=this.printService.printTemplate.filter;
    this.printService.onDataReady();
  }
  ngOnInit(): void {
  }

}
