import { UpdatedLastTransaction } from './app.model';

export class User{
    /**
     *
     */
   
    constructor(
        u?:User) 
        {
            if(u){
                Object.assign(this, u);
            }
       /*  this.first_name=first_name;
        this.last_name=last_name;
        this.other_names=other_names;
        this.username=username;
        this.date_of_birth=date_of_birth;
        this.gender=gender;   
        this.role=role;
        this.nationality=nationality;     
        this.profile_status=profile_status;
        this.primary_phone_number=primary_phone_number;
        this.general_status=general_status */
    }    
    id: string;
    first_name: string;
    last_name: string;
    other_names: string;
    date_of_birth: string;
    username: string;
    gender: string;
    nationality: string;
    profile_status: string;
    primary_phone_number: string;
    primary_phone_telecom:string;
    general_status: string;
    roles:string;
    loan_narration: LoanNarration;
    tabular_narration: Tabularnarration[];
    requires_more_collateral:boolean;
    unread_notifications:number;
    loan_reference:string;
    secondary_phone_number:string;
    secondary_phone_telecom:string;
    pending_approvals:number;
    updated_loan_narration: UpdatedLoanNarration[];
    updated_loan_percentage:{UGX:number,USD:number};
    can_get_instant_loan?:boolean

}  
export interface LoanNarration {
        due_date:string;
        general_loan_status: string;
        narration: Narration[];
        loan_id: string;
        next_payment_installation:number;
        loan_balance:number;
        percentage:number;
        loan_currency:string;
        loan_reference:string;
    }
    export interface UpdatedLoanNarration {
        account_reference: string;
        account_currency: string;
        narration: LoanNarration;
        summary:LoanNarrationSummary;
    }
    export interface LoanNarrationSummary {
        count_ongoing_loans: number;
        count_ongoing_loans_by_currency: LoanByCurrency;
        total_loan_balance: LoanByCurrency;
        loan_balance_by_currency: LoanCurrencyTransaction;
    }
    export interface UpdatedCurrencyLoanNarration {
        id:string;
        reference: string;
        balance: number;
        singular_narration: LoanNarration;
    }
    export interface LoanCurrencyTransaction{
        UGX:UpdatedCurrencyLoanNarration[];
        USD:UpdatedCurrencyLoanNarration[]
    }
    export interface LoanByCurrency {
        UGX: number;
        USD: number;
    }
    export interface Narration {
        loan_status: string;
        completion_status: string;
    }
    export interface Tabularnarration {
        time: string;
        status: string;
        sla: string;
        track_application: string;
        requires_more_collateral:boolean;
      }