import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AdminService } from '../services/admin.service';
import { GOResponse, LoanProfile } from '../models/app.model';
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';

@Component({
  selector: 'app-loan-schedule',
  templateUrl: './loan-schedule.component.html',
  styleUrls: ['./loan-schedule.component.css']
})
export class LoanScheduleComponent implements OnInit,OnChanges {
  loan_profile: LoanProfile;
 @Input('loan_id') loan_id?:string;
isPhone:boolean;
  constructor(private route:ActivatedRoute, private api:AdminService,
    private breakpointObserver:BreakpointObserver) {
      //  console.log(this.route)
      this.breakpointObserver.observe([
        Breakpoints.XSmall,
        Breakpoints.Small,
        Breakpoints.Medium,
        Breakpoints.Large,
        Breakpoints.XLarge
      ]).subscribe( (state: BreakpointState) => {
        //console.log(state)
        if(state.breakpoints[Breakpoints.Small] || state.breakpoints[Breakpoints.XSmall]){
             this.isPhone=true;
        }else{
          this.isPhone=false;
        }
      });
   }
listname:string;
  ngOnInit(): void {
    this.route.params.subscribe(x=>{
      this.listname='History';
      if(this.loan_id){
        this.getLoan(this.loan_id);
      }else{
             if(x['id']){

this.getLoan(x['id']);
      } 
      }

    })
   
  }
  ngOnChanges(changes:SimpleChanges){
this.getLoan(changes.loan_id.currentValue)
  }
  getLoan(id){
    this.api.GetLoanDetails(id).subscribe((x:GOResponse<LoanProfile>)=>{    
      this.loan_profile=x.data
    })
  }
  removeHyphen(t){
    if(t){
      return t.replace(/-/g, "").substr(1,6); 
    }
  }
  isCollapsed: boolean = true;

  collapsed(event: any): void {
    // console.log(event);
  }

  expanded(event: any): void {
    // console.log(event);
  }
}
