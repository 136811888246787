import { Component, OnInit, TemplateRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ConfirmationDialogComponent } from '../../common/confirmation-dialog/confirmation-dialog.component';
import { GOResponse, LoanProduct } from '../../models/app.model';
import { AdminService } from '../../services/admin.service';
import { AuthService } from '../../services/auth.service';
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';

@Component({
  selector: 'app-loan-products',
  templateUrl: './loan-products.component.html',
  styleUrls: ['./loan-products.component.css']
})
export class LoanProductsComponent implements OnInit {
  LoanProductsModalRef:BsModalRef;
  
  LoanProductForm:FormGroup;
  isSubmitted:boolean=false;
  products: LoanProduct[]=[];
  isSaving:boolean=false;
  message:string;
  modalRef: BsModalRef;

  editorConfig: AngularEditorConfig = {
    editable: true,
      spellcheck: true,
      height: '50vh',
      minHeight: '0',
      maxHeight: 'auto',
      width: 'auto',
      minWidth: '0',
      translate: 'yes',
      enableToolbar: true,
      showToolbar: true,
      placeholder: 'Enter text here...',
      defaultParagraphSeparator: '',
      defaultFontName: '',
      defaultFontSize: '',
      fonts: [
        {class: 'arial', name: 'Arial'},
        {class: 'times-new-roman', name: 'Times New Roman'},
        {class: 'calibri', name: 'Calibri'},
        {class: 'comic-sans-ms', name: 'Comic Sans MS'}
      ],
      customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
    uploadWithCredentials: false,
    sanitize: true,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [
      ['bold', 'italic'],
      ['fontSize']
    ]
};
  isView: boolean=false;
  current_product: any;
  isPhone: boolean;


  constructor(private fb:FormBuilder,private modalService: BsModalService, private api:AdminService,private sanitizer: DomSanitizer,
    private authService:AuthService,private breakpointObserver:BreakpointObserver) {
      //  console.log(this.route)
      this.breakpointObserver.observe([
        Breakpoints.XSmall,
        Breakpoints.Small,
        Breakpoints.Medium,
        Breakpoints.Large,
        Breakpoints.XLarge
      ]).subscribe( (state: BreakpointState) => {
        //console.log(state)
        if(state.breakpoints[Breakpoints.Small] || state.breakpoints[Breakpoints.XSmall]){
             this.isPhone=true;
        }else{
          this.isPhone=false;
        }
      });
     }

  InitLoanProduct(){
    return this.fb.group({
      id:[''],
      product_name: ["",Validators.required],
      product_description: ["",Validators.required],
      category: ["",Validators.required],
      default_monthly_interest_rate: ["",Validators.required],
      wysiwyg_content:[''],
svg_content:[''],
order_no:[''],
interest_period:['']
    })
  }
  ngOnInit(): void {
    this.loadProducts();
  }
get svgcontent(){
  return this.products?.filter(x=>x?.svg_content!='').map(x=>x.svg_content)
}
  Save():void{
  this.isSaving=true;
  if(this.LoanProductForm.get('id').value){
    this.api.UpdateLoanProduct(this.LoanProductForm.value).subscribe(x=>{this.isSaving=false;},(e:GOResponse<any>)=>{this.isSaving=false;this.message=e.error.message;},()=>{this.loadProducts();this.CancelLoanProductModal()});
  }else{
    this.api.PostProduct(this.LoanProductForm.value).subscribe(x=>{this.isSaving=false;},(e:GOResponse<any>)=>{this.isSaving=false;this.message=e.error.message;},()=>{this.loadProducts();this.CancelLoanProductModal()});
  }

  }
  RenderSvg(val){
    return this.sanitizer.bypassSecurityTrustHtml(val)
  }
  loadProducts(){
    this.products=[]
    this.api.GetProducts('admin').subscribe((x:GOResponse<LoanProduct[]>)=>{
      this.products=x.data
    })
  }
  CreateNewProduct(template: TemplateRef<any>){
    this.isSubmitted=false;
    this.LoanProductForm= this.InitLoanProduct();
    this.LoanProductsModalRef= this.modalService.show(template,{backdrop:'static',class:"modal-lg "+ this.isPhone?'': "w-50"});
  }

  CancelLoanProductModal(){
    this.isSubmitted=false;
    this.isView=false;
    this.LoanProductsModalRef.hide();
  }
 
  EditLoanProduct(product,template: TemplateRef<any>){
    this.LoanProductForm= this.InitLoanProduct();
    this.LoanProductForm.patchValue(product);
    this.LoanProductsModalRef= this.modalService.show(template,{backdrop:'static',class:"modal-lg "+ this.isPhone?'': "w-50"});
  }
  DeleteLoanProduct(product,reason){
    this.modalRef = this.modalService.show(ConfirmationDialogComponent, {
      initialState: {
        prompt: `Are you sure you want to delete <b>${product?.product_name}</b>?`,
        has_reason:true,
        callback: (result) => {
          if (result.confirmation == 'yes'){
            this.api.DeleteLoanProduct(product?.id,result?.reason).subscribe(x=>{
              this.loadProducts();
            })
          }
        }
      },
      backdrop:'static',
      keyboard:false
    })
  }
  ViewProducts(product,template: TemplateRef<any>){
    this.current_product=null;
    this.LoanProductForm=null;
     this.isView=true
     this.current_product=product
    this.LoanProductsModalRef= this.modalService.show(template,{backdrop:'static',class:"modal-lg"});
  }
get canManage(){
  if(this.authService.currentUser.roles=='Reviewer'){
    return false
  }
  if(this.authService.currentUser.roles=='Teller'){
    return false
  }else{
    return true
  }
  
}
}
