import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Utilities } from '../services/utilities.service';
import { AuthService } from '../services/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AdminService } from '../services/admin.service';

@Component({
  selector: 'app-lockscreen',
  templateUrl: './lockscreen.component.html',
  styleUrls: ['./lockscreen.component.css']
})
export class LockscreenComponent implements OnInit {
  user:any;

  isSubmitted:boolean;
  LockScreenForm:FormGroup;
  username:string;
  oldpassword:any;
  unlocking:boolean=false;
    message: string;
  token: string;
    constructor(private authService:AuthService,
      private router:Router,private adminService:AdminService,private fb:FormBuilder,private routes:ActivatedRoute) {
  this.LockScreenForm= this.fb.group({
    username:'',
    current_pin:[null],
    new_pin:[null,[Validators.required,Validators.minLength(5)]],
    confirmPin: ["",Validators.required]
        },
        {
          validator: ConfirmPasswordValidator("new_pin", "confirmPin")
        })
     }
  
    ngOnInit() {
      this.routes.params.subscribe(r=>{
      /// this.user=this.authService.currentUser? this.authService.currentUser.fullname:'name';
      this.username=r['username'];
     if(r['fullname']){
      this.user=r['fullname']?r['fullname']:'name' 
  
      this.oldpassword=atob(r['otp']);
      this.token=r['token']
     }

      })
     
    }
   checkMatch(value,pattern:string){
      let re = new RegExp(pattern);
      return re.test(value)
    }
  get ProfileLetter(){
   return this.user.match(/\b(\w)/g).join('');;
  }
  get new_pin(){
    return this.LockScreenForm.get('new_pin');
  }
  fieldTextType: boolean=false;
  fieldTextTypeConfirm: boolean=false;
  
  toggleFieldTextType() {
    this.fieldTextType = !this.fieldTextType;
  }
  toggleFieldTextTypeConfirm() {
    this.fieldTextTypeConfirm = !this.fieldTextTypeConfirm;
  }
  Send(){
    this.unlocking=true;
    this.LockScreenForm.get('username').setValue(this.username);
    if(this.token){
     
      this.LockScreenForm.get('current_pin').setValue(this.oldpassword);
      this.adminService.UserChangePasswordOnLogin(this.LockScreenForm.value,this.token).subscribe(x=>{
  
      },error=>{
        this.unlocking=false;
        if(error.error.status==400){         
          
          this.message='Unable to login. Invalid username or pin'
    
         }
     
            if (Utilities.checkNoNetwork(error)) {
   
            } else {
              const errorMessage = Utilities.getHttpResponseMessage(error);
    
              if (errorMessage) {
               
              } else {
             
              }
            }
      },()=>{
        this.unlocking=false;
        this.router.navigate(['/login'])
      })
    }else{
      this.adminService.UserChangePassword(this.LockScreenForm.value).subscribe(x=>{
  
      },error=>{
        this.unlocking=false;
        if(error.error.status==400){         
          
          this.message=error.error.message
    
         }
     
            if (Utilities.checkNoNetwork(error)) {
   
            } else {
              const errorMessage = Utilities.getHttpResponseMessage(error);
    
              if (errorMessage) {
               
              } else {
             
              }
            }
      },()=>{
        this.unlocking=false;
        this.router.navigate(['/login'])
      })
    }
  
   /*  this.authService.loginAfterLockscreen(this.LockScreenForm.controls['currentpassword'].value,this.LockScreenForm.controls['newpassword'].value).subscribe(x=>{
  
    },error=>{
      this.alertService.showStickyMessage('Unable to login', error.error.Message, MessageSeverity.error, error);
          if (Utilities.checkNoNetwork(error)) {
            this.alertService.showStickyMessage(Utilities.noNetworkMessageCaption, Utilities.noNetworkMessageDetail, MessageSeverity.error, error);
            // this.offerAlternateHost();
          } else {
            const errorMessage = Utilities.getHttpResponseMessage(error);
  
            if (errorMessage) {
              // this.alertService.showStickyMessage('Unable to login', this.mapLoginErrorMessage(errorMessage), MessageSeverity.error, error);
            } else {
              this.alertService.showStickyMessage('Unable to login', 'An error occured whilst logging in, please try again later.\nError: ' + error.Message, MessageSeverity.error, error);
            }
          }
    }) */
  }
  ResetPassword(){
    this.adminService.ResetPin(this.username).subscribe(x=>{
      this.router.navigate(['/login'])
    },error=>{
  
    },()=>{
      this.router.navigate(['/login'])
    })
  }
  allowOnlyNumbers(event) {
    if (event.key.length === 1 && /\D/.test(event.key)) {
      event.preventDefault();
    }
  }
  }
  export function ConfirmPasswordValidator(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
      let control = formGroup.controls[controlName];
      let matchingControl = formGroup.controls[matchingControlName]
      if (
        matchingControl.errors&&
        !matchingControl.errors.confirmPasswordValidator
      ) {
        return;
      }
      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ confirmPasswordValidator: true });
      } else {
        matchingControl.setErrors(null);
      }
    };
  }