import { Component, OnInit, TemplateRef } from '@angular/core';
import { Validators, FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ConfirmationDialogComponent } from '../../common/confirmation-dialog/confirmation-dialog.component';
import { FormControls, FormModel, GOResponse, UserListVM, UserProfile, Transaction, UserSummary, AccountDetail, CustodyAccountVM, CollateralVM } from '../../models/app.model';
import { AdminService } from '../../services/admin.service';

@Component({
  selector: 'app-borrowers',
  templateUrl: './borrowers.component.html',
  styleUrls: ['./borrowers.component.css']
})
export class BorrowersComponent implements OnInit {
  
  UserFormConfig:FormModel<UserProfile>={
    id:[''],
    first_name:['',Validators.required],
    last_name:['',Validators.required],
    other_names:[''],
    day:['',Validators.required],
month:['',Validators.required],
year:['',Validators.required],
    date_of_birth:[''],
    gender:['',Validators.required],
    nationality:['',Validators.required],
    nin:[''],
    passport_number:[''],
    marital_status:['',Validators.required],
    current_home_address:['',Validators.required],
    previous_home_address:[''],
    primary_phone_number:['',Validators.required],
    secondary_phone_number:[''],
    email:['',[Validators.required,Validators.email]],
    nok_name:['',Validators.required],
    nok_email:['',Validators.required],
    nok_phone_number:['',Validators.required],
    nok_relationship:['',Validators.required],
    roles:[''],
    passport_photo:['',Validators.required],
    default_currency:['UGX']
  }
  UserForm:FormGroup;

  isSubmitted:boolean=false;

  isSubmittedOther:boolean=false;

  isSubmittedNok:boolean=false;

  UserModalRef:BsModalRef;

  formControls:FormControls<UserProfile>;

  currentTab:number=0;

  days=Array.from(Array(31).keys()).filter(d=>d!=0);

  users_list:UserListVM[]=[];

  isSaving:boolean=false;

  message:string;


  months=['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
start=((new Date().getFullYear()-18));
end=(new Date().getFullYear()-80);
  years= [...Array(Math.floor((this.start - this.end)) + 1)].map((_, i) => this.end + i * 1).reverse();
  modalRef: BsModalRef;
  formControlsTransaction: FormControls<Transaction>;
  InitiateModelRef: BsModalRef;
  
  constructor(private fb:FormBuilder,private modalService:BsModalService,private api:AdminService) { }

  ngOnInit(): void {
    this.loadBorrowers();
  }
//isCollapsedTable=[];
  isCollapsed: boolean[] = [];

  collapsed(event: any): void {
    // console.log(event);
  }

  expanded(event: any): void {
    // console.log(event);
  }
CreateUser(template:TemplateRef<any>){
  this.currentTab=0;
  this.isCollapsed[this.currentTab]=true;
  this.ToggleForm(0)
this.UserForm= this.fb.group(this.UserFormConfig);
this.formControls=this.UserForm?.controls as FormControls<UserProfile>;
this.UserModalRef= this.modalService.show(template,{backdrop:'static',class:'modal-lg'})
}
Cancel(){
  this.isSubmitted=false;
  this.UserModalRef.hide();
  this.UserForm= this.fb.group(this.UserFormConfig);
  this.ToggleForm(0)
}
Save(){
  this.formControls.day.disable();
  this.formControls.month.disable();
  this.formControls.year.disable();
  if(this.formControls.id.value!=''){

    if(typeof this.formControls.passport_photo.value==='string'){
      this.formControls.passport_photo.setValue("null");
    }

    this.api.UpdatingUser(this.UserForm.value).subscribe(x=>{}, (e:GOResponse<any>)=>{this.isSaving=false;this.message=e.error.message;},
    ()=>{this.loadBorrowers();this.Cancel()})
  }else{
// this.formControls.date_of_birth.setValue(`${this.formControls.year.value}-${this.formControls.month.value}-${this.formControls.day.value}`)
this.api.PostBorrower(this.UserForm.value).subscribe(x=>{}, (e:GOResponse<any>)=>{this.isSaving=false;this.message=e.error.message;},
()=>{this.loadBorrowers();this.Cancel()})
  } 
}
loadBorrowers(){
this.api.GetUsers().subscribe((x:GOResponse<UserListVM[]>)=>{


  this.users_list=x.data;
})
}
Next(){
  this.formControls.date_of_birth.setValue(`${this.formControls.year.value}-${this.formControls.month.value}-${this.formControls.day.value}`)
this.isCollapsed[this.currentTab]=false;
this.isCollapsed[this.currentTab+1]=true;
this.currentTab=this.currentTab+1;
}
Back(){
  this.isCollapsed[this.currentTab]=false;
  this.isCollapsed[this.currentTab-1]=true;
  this.currentTab=this.currentTab-1;
  }
 ToggleForm(level:number){
   let a= Array.from(Array(4).keys())
   a.forEach(el => {
     if(el==level){
       this.isCollapsed[level]=!this.isCollapsed[level];
       this.currentTab=level;
     }else{
       this.isCollapsed[el]=false
     }
   });
 }
 onFileChange(event) {
  console.log(event)
 if (event.target.files.length > 0) {
   const file = event.target.files[0];
   
   this.UserForm.patchValue({
     passport_photo: file
   });
  
 }
}
ResetPin(user:UserListVM){
  
  this.modalRef = this.modalService.show(ConfirmationDialogComponent, {
    initialState: {
      prompt: `Are you sure you want to reset the PIN for <b>${user.first_name} ${user.last_name}</b>?`,
      callback: (result) => {
        console.log(result)
        if (result.confirmation == 'yes'){

this.api.ResetPin(user.username).subscribe(x=>{

})
        }
      }
    },
    backdrop:'static',
    keyboard:false
  })
}
EditUser(u:UserListVM,template:TemplateRef<any>){
  this.isCollapsed[this.currentTab]=true;
 this.UserForm= this.fb.group(this.UserFormConfig);
 this.formControls=this.UserForm?.controls as FormControls<UserProfile>;
  this.api.GetUser(u.id).subscribe((x:GOResponse<UserProfileDetailVM>)=>{
let d_o_b= new Date(x.data.profile.date_of_birth);
this.formControls.day.setValue(`${d_o_b.getDate()}`);
this.formControls.month.setValue(d_o_b.getMonth()+1);
this.formControls.year.setValue(`${d_o_b.getFullYear()}`);
    this.UserForm.patchValue(x.data.profile);
        console.log(this.UserForm.value)
    this.UserModalRef= this.modalService.show(template,{backdrop:'static',class:'modal-lg'})
  })
  
}
 ClientValidity(){
  return this.formControls.first_name.valid&&this.formControls.last_name.valid&&this.formControls.day.valid&&this.formControls.month.valid&&this.formControls.year.valid&&this.formControls.gender.valid&&this.formControls.nationality.valid&&this.formControls.marital_status.valid&&this.formControls.passport_photo.valid
 }

 OtherValidity(){
return this.formControls.nin.valid&&this.formControls.email.valid&& this.formControls.primary_phone_number.valid&&this.formControls.current_home_address.valid
 }

 NokValidity(){
   return this.formControls.nok_email.valid&&this.formControls.nok_name.valid&&this.formControls.nok_phone_number.valid&&this.formControls.nok_relationship.valid
 }

 ////Withdraw Initiation
 selectedUserSummary:UserSummary;
 accounts:AccountDetail[]=[];
 custody_accounts:CustodyAccountVM[]=[];
 TransactionForm:FormGroup;
 TransactionFormConfig:FormModel<Transaction>;
 init_message:string='';
 isInitiationSubmitted=false;
 InitiateCashWithdraw(username, template){
  this.accounts=[];
  this.custody_accounts=[];
  this.TransactionFormConfig={
    account:['',Validators.required],
    amount:[0,[Validators.required,Validators.min(1)]],
    comments:[''],
    platform:['Cash'],
    source:['Admin Portal'],
    type:['Withdraw'],
    custody_account:['', Validators.required],
    agree:[false]
  }
  this.api.GetAccountsofUser(username).subscribe((x:GOResponse<AccountDetail[]>)=>{
this.accounts=x.data
  })
  this.api.GetCustodyAccounts().subscribe((x:GOResponse<CustodyAccountVM[]>)=>{
    this.custody_accounts=x.data;
          })
  this.TransactionForm=this.fb.group(this.TransactionFormConfig);
  this.formControlsTransaction=this.TransactionForm?.controls as FormControls<Transaction>;
  this.InitiateModelRef= this.modalService.show(template,{backdrop:'static',keyboard:false})
}

InitiateCashDeposit(username, template){
  this.accounts=[];
  this.custody_accounts=[];
  this.TransactionFormConfig={
    account:['',Validators.required],
    amount:[0,[Validators.required,Validators.min(1)]],
    comments:[''],
    platform:['Cash'],
    source:['Admin Portal'],
    type:['Deposit'],
    custody_account:['', Validators.required],
    agree:[true]
  }
  this.api.GetAccountsofUser(username).subscribe((x:GOResponse<AccountDetail[]>)=>{
this.accounts=x.data
  })
  this.api.GetCustodyAccounts().subscribe((x:GOResponse<CustodyAccountVM[]>)=>{
    this.custody_accounts=x.data;
          })
  this.TransactionForm=this.fb.group(this.TransactionFormConfig);
  this.formControlsTransaction=this.TransactionForm?.controls as FormControls<Transaction>;
  this.InitiateModelRef= this.modalService.show(template,{backdrop:'static',keyboard:false})
}
SubmitInitiation(){
  this.modalRef = this.modalService.show(ConfirmationDialogComponent, {
    initialState: {
      prompt: `Are you sure you want to submit this cashwithdraw for approval?`,
      callback: (result) => {
  this.isInitiationSubmitted=false;
this.api.TellerInitiatingCashWithdraw(this.TransactionForm.value).subscribe(x=>{
this.CancelWidthrawModal();
},(e:GOResponse<any>)=>{this.isSaving=false;this.init_message=e.error.message;})
      }
    }
  })

}
CancelWidthrawModal(){
  this.isInitiationSubmitted=false;
  this.TransactionForm=this.fb.group(this.TransactionFormConfig);
  this.InitiateModelRef.hide();
}
}
export interface UserProfileDetailVM{
  profile:UserProfile
  collateral:CollateralVM[]
}
