import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GOResponse, LoanProduct } from '../models/app.model';
import { AdminService } from '../services/admin.service';

@Component({
  selector: 'app-product-page',
  templateUrl: './product-page.component.html',
  styleUrls: ['./product-page.component.css']
})
export class ProductPageComponent implements OnInit {
  products: LoanProduct[]=[];
  current_product:LoanProduct;
@Input('as_admin') as_admin?:boolean;
  constructor(private api:AdminService,private routes:ActivatedRoute) { }

  ngOnInit(): void {
    this.routes.params.subscribe(x=>{
        this.loadProducts(x['id']);
    })  
  }
  loadProducts(id?:any){
    this.api.GetProducts('public').subscribe((x:GOResponse<LoanProduct[]>)=>{
      this.products=x.data;
      if(id){
        this.current_product=this.products.filter(x=>x.id==id)[0];
      }else{
        this.current_product=this.products[0];
      }
      
    })
  }
}
