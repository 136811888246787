import { Component, OnInit, TemplateRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { CustodyAccountVM, GOResponse } from '../../models/app.model';
import { AdminService } from '../../services/admin.service';
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';

@Component({
  selector: 'app-accounts',
  templateUrl: './accounts.component.html',
  styleUrls: ['./accounts.component.css']
})
export class AccountsComponent implements OnInit {
  isCollapsed:boolean=false;

  CustodyAccountForm:FormGroup;
list:CustodyAccountVM[]=[];

  TransferModalRef:BsModalRef;

  CustodyModalRef:BsModalRef;

  isSubmitted:boolean=false;

  message:string;
  is_superadmin: boolean=false;
  isPhone: boolean;

    constructor(private modalService: BsModalService,private fb:FormBuilder,private api:AdminService,private breakpointObserver:BreakpointObserver) {
      //  console.log(this.route)
      this.breakpointObserver.observe([
        Breakpoints.XSmall,
        Breakpoints.Small,
        Breakpoints.Medium,
        Breakpoints.Large,
        Breakpoints.XLarge
      ]).subscribe( (state: BreakpointState) => {
        //console.log(state)
        if(state.breakpoints[Breakpoints.Small] || state.breakpoints[Breakpoints.XSmall]){
             this.isPhone=true;
        }else{
          this.isPhone=false;
        }
      }); 
    }
  ngOnInit(): void {
    this.loadCustodyAccounts()
  }

  InitCustodyAccount(){
return this.fb.group({
  "account_name": ["",Validators.required],
  "account_description": ["",Validators.required],
  "current_balance": [0],
  "currency":[''],
  "form":['', Validators.required]
})
  }
  collapsed(event: any): void {
    // console.log(event);
  }
  
    expanded(event: any): void {
      // console.log(event);
    }
    loadCustodyAccounts(){
      this.api.GetCustodyAccounts().subscribe((x:GOResponse<CustodyAccountVM[]>)=>{
this.list=x.data;
this.is_superadmin=x?.is_superadmin
      })
    }
    TransferCancelled(){
  this.TransferModalRef.hide();
    }
    TransferMoney(template: TemplateRef<any>){   
     this.CustodyAccountForm= this.InitCustodyAccount();
      this.TransferModalRef= this.modalService.show(template,{class:'modal-lg',keyboard:false,backdrop:'static'})
    //  document.querySelector('nav-tabs').classList.add('nav-justified');
        }
        NewCustody(template: TemplateRef<any>){
          this.CustodyAccountForm= this.InitCustodyAccount();
      this.CustodyModalRef= this.modalService.show(template,{class:'modal-lg',keyboard:false,backdrop:'static'})
        }
      CloseModal(){
        this.CustodyModalRef.hide();
      }
      Save(){
this.api.CreateCustodyAccount(this.CustodyAccountForm.value).subscribe(x=>{
  this.CloseModal();
  this.loadCustodyAccounts();

})
console.log(this.CustodyAccountForm.value)
      }
}

