import { Component, OnInit, ViewChild } from '@angular/core';
import { GOResponse, LoanListVM } from '../models/app.model';
import { AdminService } from '../services/admin.service';
import { Utilities } from '../services/utilities.service';
import { PaginationInstance } from 'ngx-pagination';
import {sortByKeys} from '../sort-by-key';
import { SearchBoxComponent } from '../common/search-box/search-box.component';
import { AuthService } from '../services/auth.service';
import { Breakpoints, BreakpointState, BreakpointObserver } from '@angular/cdk/layout';

@Component({
  selector: 'app-all-loans',
  templateUrl: './all-loans.component.html',
  styleUrls: ['./all-loans.component.css']
})
export class AllLoansComponent implements OnInit {

  
  loans:LoanListVM[]=[]

  status_list:any[]=[
    {name:'All',value:null},
    {name:'Pending Approval', value:'Pending'},
    {name:'Pending Payment', value:'Pending Payment'},    
    {name:'Paid', value:'Paid'},    
    {name:'Rejected', value:'Rejected'},
    {name:'Bad Debt', value:'Bad Debt'},
    {name:'Frozen',value:'Frozen'}
  ]
  loan_status_fiter:any= this.status_list[1];
  rowsCache: LoanListVM[]=[];

  public configSub: PaginationInstance = {
    id: 'all-loans',
    itemsPerPage: 10,
    currentPage: 1
  };
  isloaded:boolean=false;
  @ViewChild('loanSearch',{static:false}) loan_search:SearchBoxComponent;
  isPhone: boolean;

  constructor(private api:AdminService,private authService:AuthService,private breakpointObserver:BreakpointObserver) {
    this.breakpointObserver.observe([
      Breakpoints.XSmall,
      Breakpoints.Small,
      Breakpoints.Medium,
      Breakpoints.Large,
      Breakpoints.XLarge
    ]).subscribe( (state: BreakpointState) => {
      //console.log(state)
      if(state.breakpoints[Breakpoints.Small] || state.breakpoints[Breakpoints.XSmall]){
           this.isPhone=true;
      }else{
        this.isPhone=false;
      }
    });

   }

  ngOnInit(): void {
    this.loadLoanHistory();
  }
loadLoanHistory(){
  this.isloaded=false;
  this.api.GetLoanHistory(true,this.loan_status_fiter?this.loan_status_fiter.value:null).subscribe((x:GOResponse<LoanListVM[]>)=>{
this.loans=x.data;
this.rowsCache=x.data;
this.isloaded=true;
  })
}
get filteredStatuses():any[]{
  return this.status_list.filter(x=>x.value!==(this.loan_status_fiter?this.loan_status_fiter.value:null));
}
selectStatus(s){
  this.loan_status_fiter=s;
  this.loans=[];
  this.configSub.currentPage=1
  this.resetKeys();
  this.loadLoanHistory();
  this.loan_search.clear();
}
removeHyphen(t){
  if(t){
    return t.replace(/-/g, "").substr(1,6); 
  }
 
}
onSearchChanged(value: string) {
  //this.resetKeys();
  this.loans = this.rowsCache.filter(r => Utilities.searchArray(value, false, r.borrower.first_name, r.borrower.last_name,r.loan_id));
}
selectedKeys:any[]=[];
selectedIndex:number;
resetKeys(){
  this.selectedKeys=[];
}
sortSelected(keys:string[],index){
  if(index!=this.selectedIndex){
     this.selectedKeys=[];
  }
 
  if(keys.length==1){
        if(this.selectedKeys[index]){
         // console.log('selected key is '+this.selectedKeys[index])
  keys.forEach((key,i) => {
  
    keys[i]='-'+key
    this.selectedKeys[index]=false
 
  });
  }else{
    this.selectedKeys[index]=true
  
  }
}else{
  this.selectedKeys[index]=true

}
  console.log(...keys)
this.loans= sortByKeys(this.loans,...keys);
this.selectedIndex=index;
}
get currentId(){
 return this.authService.currentUser.id
}
}
