import { Component, OnInit, TemplateRef } from '@angular/core';
import { FormModel, UserProfile, FormControls, UserListVM, GOResponse, ProfileEdit } from '../models/app.model';
import { Validators, FormGroup, FormBuilder } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { UserProfileDetailVM } from '../admin/borrowers/borrowers.component';
import { AdminService } from '../services/admin.service';
import { AuthService } from '../services/auth.service';
import { Router } from '@angular/router';
import { OtpDialogComponent } from '../common/otp-dialog/otp-dialog.component';
import { ConfirmationService } from '../services/confirmation.service';
import { HttpEvent, HttpEventType } from '@angular/common/http';
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';

@Component({
  selector: 'app-profile-edit',
  templateUrl: './profile-edit.component.html',
  styleUrls: ['./profile-edit.component.css']
})
export class ProfileEditComponent implements OnInit {
  UserFormConfig:FormModel<ProfileEdit>={
    id:[''],
    first_name:['',[Validators.required]],
    last_name:['',Validators.required],
    other_names:[''],
    day:['',Validators.required],
month:['',Validators.required],
year:['',Validators.required],
    date_of_birth:[''],
    gender:['',Validators.required],
    nationality:['',Validators.required],
    nin:[''],
    passport_number:[''],
    marital_status:['',Validators.required],
    current_home_address:['',Validators.required],
    previous_home_address:[''],
    primary_phone_number:['',[Validators.required,Validators.maxLength(12),Validators.minLength(12)]],
    primary_phone_number_input:[''],
    secondary_phone_number:['',[Validators.maxLength(12),Validators.minLength(12)]],
    secondary_phone_number_input:[''],
    email:['',[Validators.required,Validators.email]],
    nok_name:['',Validators.required],
    nok_email:['',Validators.required],
    nok_phone_number:['',Validators.required],
    nok_phone_number_input:[''],
    nok_relationship:['',Validators.required],
    roles:[''],
    passport_photo:['',Validators.required],
    default_currency:['UGX'],
    bank_country:[''],
    bank_name:[''],
    bank_branch:[''],
    bank_account_name:[''],
    bank_account_number:[null],
    otp:['']
  }
  UserForm:FormGroup;

  isSubmitted:boolean=false;

  isSubmittedOther:boolean=false;

  isSubmittedNok:boolean=false;

  isSubmittedBank:boolean=false;

  UserModalRef:BsModalRef;

  formControls:FormControls<ProfileEdit>;

  currentTab:number=1;

  days=Array.from(Array(31).keys()).filter(d=>d!=0);
  isSaving:boolean=false;

  message:string;

  roles:any[]=[
    { name: 'Admin'},{name:'Approver'},{name:'Reviewer'},{name:	'Teller' },{name:	'Borrower' }]


  months=['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
start=((new Date().getFullYear()-18));
end=(new Date().getFullYear()-80);
  years= [...Array(Math.floor((this.start - this.end)) + 1)].map((_, i) => this.end + i * 1).reverse();
  modalRef: BsModalRef; 
  rowsCache: UserListVM[]=[];
  isCollapsed: boolean[] = [];
  isPhone: boolean;
  
  collapsed(event: any): void {
    // console.log(event);
  }

  expanded(event: any): void {
    // console.log(event);
  }

  telOptions = {initialCountry: 'ug', preferredCountries: ['ug','ke', 'tz','rw'],separateDialCode:true,autoHideDialCode: false};

  constructor(private fb:FormBuilder,private api:AdminService,private authService:AuthService, private router:Router,
    private modalService:BsModalService, private confirmationService:ConfirmationService,private breakpointObserver:BreakpointObserver) {

      this.breakpointObserver.observe([
        Breakpoints.XSmall,
        Breakpoints.Small,
        Breakpoints.Medium,
        Breakpoints.Large,
        Breakpoints.XLarge
      ]).subscribe( (state: BreakpointState) => {
        //console.log(state)
        if(state.breakpoints[Breakpoints.Small] || state.breakpoints[Breakpoints.XSmall]){
             this.isPhone=true;
           //  this.sidebarMinimized=true;
        }else{
          this.isPhone=false;
        }
      }); }

  ngOnInit(): void {
    this.EditUser();
  }
  EditUser(){    
 /*   this.formControls.first_name.disable();
   this.formControls.last_name.disable();
   this.formControls.other_names.disable();
   this.formControls.roles.disable();
   this.formControls.gender.disable();
   this.formControls.nationality.disable();
   this.formControls.marital_status.disable(); */

     this.isCollapsed[this.currentTab]=true;
    this.UserForm= this.fb.group(this.UserFormConfig);
    this.formControls=this.UserForm?.controls as FormControls<ProfileEdit>;
   // this.formControls.primary_phone_number_input.disable();
     this.api.GetUser(this.authService.currentUser.id).subscribe((x:GOResponse<UserProfileDetailVM>)=>{
   let d_o_b= new Date(x.data.profile.date_of_birth);
   this.formControls.day.setValue(`${d_o_b.getDate()}`);
   this.formControls.month.setValue(d_o_b.getMonth()+1);
   this.formControls.year.setValue(`${d_o_b.getFullYear()}`);
       this.UserForm.patchValue(x.data.profile);
   
       this.formControls.primary_phone_number_input.setValue(x.data.profile.primary_phone_number);
       if(x.data.profile.secondary_phone_number){
   this.formControls.secondary_phone_number_input.setValue(x.data.profile.secondary_phone_number)
   
       }
       this.formControls.nok_phone_number_input.setValue(x.data.profile.nok_phone_number);
       
          // console.log(this.UserForm.value)
      // this.UserModalRef= this.modalService.show(template,{backdrop:'static',class:'modal-lg'})
     })  
   }
   Next(){
    this.formControls.date_of_birth.setValue(`${this.formControls.year.value}-${this.formControls.month.value}-${this.formControls.day.value}`)
  this.isCollapsed[this.currentTab]=false;
  this.isCollapsed[this.currentTab+1]=true;
  this.currentTab=this.currentTab+1;
  }
  Back(){
    this.isCollapsed[this.currentTab]=false;
    this.isCollapsed[this.currentTab-1]=true;
    this.currentTab=this.currentTab-1;
    }
   ToggleForm(level:number){
     let a= Array.from(Array(5).keys())
     a.forEach(el => {
       if(el==level){
         this.isCollapsed[level]=!this.isCollapsed[level];
         this.currentTab=level;
       }else{
         this.isCollapsed[el]=false
       }
     });
   }
   onFileChange(event) {
    console.log(event)
   if (event.target.files.length > 0) {
     const file = event.target.files[0];
     
     this.UserForm.patchValue({
       passport_photo: file
     });
    
   }
  }
  ClientValidity(){
    return this.formControls.first_name.valid&&this.formControls.last_name.valid&&this.formControls.day.valid&&this.formControls.month.valid&&this.formControls.year.valid&&this.formControls.gender.valid&&this.formControls.nationality.valid&&this.formControls.marital_status.valid&&this.formControls.passport_photo.valid
   }
  
   OtherValidity(){
  return this.formControls.nin.valid&&this.formControls.email.valid&& this.formControls.primary_phone_number.valid&&this.formControls.current_home_address.valid
   }
  
   NokValidity(){
     return this.formControls.nok_email.valid&&this.formControls.nok_name.valid&&this.formControls.nok_phone_number.valid&&this.formControls.nok_relationship.valid
   }
   objIntelObj:any;
telInputObject(obj) {
  //console.log(obj.getValue());

this.objIntelObj=obj;
 // obj.setCountry('ug');
  
}
getNumber($event){
  console.log($event);
  
  this.formControls.primary_phone_number.setValue($event.replace("+",""))
}
getNum(){
  this.objIntelObj.getNumber();
}
telInputObjectSec(obj){
  console.log(obj.getNumber());
  //this.formControls.secondary_phone_number.setValue(obj.getNumber())
}
getNumberSec($event:string){
 console.log($event);
 
  this.formControls.secondary_phone_number.setValue($event.replace("+",""))
}
getNumberNok($event:string){
  this.formControls.nok_phone_number.setValue($event.replace("+",""))
}
isInteger(event,id:string,field:string) {

  var ctl = document.getElementById(id.toString());
  var startPos = ctl['selectionStart'];

  if (startPos == 0 && String.fromCharCode(event.which) == '0') {
   // this.UserForm.get(field.toString()).updateValueAndValidity();
    return false
    
  }
  if(event.target.value.length==3){
    this.UserForm.get(field.toString()).setValue(event.target.value+' ');
   // this.UserForm.get(field.toString()).updateValueAndValidity();
  }

}
onCountryChange($event){
  this.formControls.primary_phone_number.setValue($event.dialCode)
}
onSecCountryChange($event){
  this.formControls.secondary_phone_number.setValue($event.dialCode)
}
onNokNumberChange($event){
  this.formControls.nok_phone_number.setValue($event.dialCode);
}
isBackspace(event,field:string){
  if((event.keyCode || event.charCode)==8){
    console.log(this.UserForm.get(field.replace('_input','').toString()).value)
    console.log(this.UserForm.get(field.toString()).value);
    
   if(this.UserForm.get(field.replace('_input','').toString()).value){
    this.UserForm.get(field.replace('_input','').toString()).setValue(event.target.value); 
    //this.UserForm.get(field.toString()).updateValueAndValidity();
   }
    
  }
}
get canManageStaff(){
return this.authService.currentUser.roles!='Reviewer'
}
GetOTP(){
  this.api.GetUpdateProfileOTP().subscribe(x=>{
      this.modalRef = this.modalService.show(OtpDialogComponent, {
    initialState: {
      prompt: `Please enter 5-digit One-Time Password(OTP) sent to you on SMS and email`,
      callback: (result) => {
        if (result.confirmation == 'yes'){
this.formControls.otp.setValue(result.reason);
this.Save();
        }
        if (result.confirmation == 'resend'){
          this.api.GetUpdateProfileOTP().subscribe(x=>{});
        }
      }
    },
    backdrop:'static',
    keyboard:false
  })
  })
}
Save(){

  this.formControls.primary_phone_number_input.disable();
  this.formControls.secondary_phone_number_input.disable();
  this.formControls.nok_phone_number_input.disable();
  this.formControls.day.disable();
  this.formControls.month.disable();
  this.formControls.year.disable();
  if(typeof this.formControls.passport_photo.value==='string'){
    this.formControls.passport_photo.setValue("null");
  }
this.confirmationService.SetCurrentStatus({item:'loading',status:null})
  this.api.UserPostProfileUpdates(this.UserForm.value).subscribe((body:any)=>{

if(body.status==200){
  this.confirmationService.SetCurrentStatus({item:'OK',status:true,message:body?.message})
}   
  }, (e:GOResponse<any>)=>{this.isSaving=false;this.message=e.error.message;this.confirmationService.SetCurrentStatus({item:'ERROR',status:false,message:e.error.message})},
  ()=>{this.router.navigate(['settings'])})
}
banks:any[]=[
  {
    "name": "ABC Capital Bank Uganda Limited"
  },
  {
    "name": "Absa Bank Uganda Limited"
  },
  {
    "name": "Afriland First Bank Uganda Limited"
  },
  {
    "name": "Bank of Africa Uganda Limited"
  },
  {
    "name": "Bank of Baroda Uganda Limited"
  },
  {
    "name": "Bank of India Uganda Limited"
  },
  {
    "name": "BRAC Bank Limited"
  },
  {
    "name": "Cairo International Bank"
  },
  {
    "name": "Centenary Rural Development Bank Limited"
  },
  {
    "name": "Citibank Uganda Limited"
  },
  {
    "name": "DFCU Bank"
  },
  {
    "name": "Diamond Trust Bank Limited"
  },
  {
    "name": "Ecobank Uganda Limited"
  },
  {
    "name": "Equity Bank Uganda Limited"
  },
  {
    "name": "Exim Bank Uganda"
  },
  {
    "name": "Finance Trust Bank"
  },
  {
    "name": "Guaranty Trust Bank Uganda Limited"
  },
  {
    "name": "Housing Finance Bank Limited"
  },
  {
    "name": "KCB Bank Uganda Limited"
  },
  {
    "name": "NCBA Bank Uganda"
  },
  {
    "name": "Opportunity Bank Uganda Limited"
  },
  {
    "name": "Orient Bank Limited"
  },
  {
    "name": "PostBank Uganda Limited"
  },
  {
    "name": "Pride Microfinance Limited (MDI)"
  },
  {
    "name": "Stanbic Bank Uganda Limited"
  },
  {
    "name": "Standard Chartered Bank Uganda Limited"
  },
  {
    "name": "Top Finance Bank (TFB)"
  },
  {
    "name": "Tropical Bank Limited"
  },
  {
    "name": "United Bank for Africa"
  }
 ];
}
