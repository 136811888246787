import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-fail-svg',
  templateUrl: './fail-svg.component.html',
  styleUrls: ['./fail-svg.component.css']
})
export class FailSvgComponent implements OnInit {

  @Input('text') text;
  constructor() { }

  ngOnInit(): void {
  }

}
