import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { AuthService } from './services/auth.service';
import { LocalStoreManager } from './services/local-store-manager.service';
import { PrintReportService } from './services/print-report.service';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';

@Component({
  // tslint:disable-next-line
  selector: 'body',
  templateUrl: "./app.component.html"
})
export class AppComponent implements OnInit {
  isUserLoggedIn: boolean;
  timedOut = false;
  lastPing?: Date = null;
  idleState: any;
  constructor(private router: Router,private authService:AuthService,private localStoreManager:LocalStoreManager,
    public printService:PrintReportService,private idle: Idle, private keepalive: Keepalive) { 
this.localStoreManager.initialiseStorageSyncListener();
   // sets an idle timeout
   idle.setIdle(600);
   // sets a timeout period of 5 seconds. after 10 seconds of inactivity, the user will be considered timed out.
   idle.setTimeout(600);
   // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
   idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
   idle.onIdleStart.subscribe(() => {
   // this.idleState = 'You\'ve gone idle!'
    console.log(this.idleState);
    this.authService.logout();
    this.authService.redirectLogoutUser();
    this.router.navigate(['/login']);
});

idle.onTimeoutWarning.subscribe((countdown) => {
  this.idleState = 'You will time out in ' + countdown + ' seconds!'
  console.log(this.idleState);
  

});
    // sets the ping interval to 15 seconds
    keepalive.interval(15);

    keepalive.onPing.subscribe(() => this.lastPing = new Date());
  }
/* 0782504627
0781112290
0772353987 */
  ngOnInit() {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
    this.authService.getLoginStatusEvent().subscribe(isLoggedIn => {
      this.isUserLoggedIn = isLoggedIn;
      if (isLoggedIn) {
        this.idle.watch()
        this.timedOut = false;
      } else {
        this.idle.stop();
      }
console.log(this.isUserLoggedIn)
      setTimeout(() => {
        if (!this.isUserLoggedIn) {
       //  this.alertService.showMessage('Session Ended!', '', MessageSeverity.default);
        }
      }, 500);
    });
  }
}
