import { Component, OnInit, Input } from '@angular/core';
import { AdminService } from '../services/admin.service';
import { LastTransaction, GOResponse } from '../models/app.model';
import { ActivatedRoute } from '@angular/router';
import { PaginationInstance } from 'ngx-pagination';
import { Utilities } from '../services/utilities.service';
import { DatePipe } from '@angular/common';
import * as moment from 'moment/moment'
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { ConfirmationDialogComponent } from '../common/confirmation-dialog/confirmation-dialog.component';
import { BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-transaction-history',
  templateUrl: './transaction-history.component.html',
  styleUrls: ['./transaction-history.component.css']
})
export class TransactionHistoryComponent implements OnInit {
  transactions: LastTransaction[]=[];
  transactionsCache: LastTransaction[]=[];
borrower_name:string;
@Input('user') user_id?:string;
@Input('currency') currency:string;
public configSub: PaginationInstance = {
  id: 't-history',
  itemsPerPage: 10,
  currentPage: 1
};
filter='All'
filters:any[]=[];
daterangepickerModel:Date[]=[];
@Input('account') account_id?: string;
one_month= moment().subtract(1,'M').format('DD-MM-yyyy')
  isPhone: boolean;
  placement:string;
  modalRef: any;
  constructor(private api:AdminService,private route:ActivatedRoute,private breakpointObserver:BreakpointObserver, private modalService:BsModalService,private datePipe:DatePipe) {
    //  console.log(this.route)
    this.breakpointObserver.observe([
      Breakpoints.XSmall,
      Breakpoints.Small,
      Breakpoints.Medium,
      Breakpoints.Large,
      Breakpoints.XLarge
    ]).subscribe( (state: BreakpointState) => {
      //console.log(state)
      if(state.breakpoints[Breakpoints.Small] || state.breakpoints[Breakpoints.XSmall]){
           this.isPhone=true;
      }else{
        this.isPhone=false;
         this.placement=(this.isPhone?'bottom left':'bottom right')
      }
    });
this.route.params.subscribe(x=>{
  this.user_id=x['user_id'];
  this.account_id=x['account_id'];
})

   }

  ngOnInit(): void {
    console.log(this.one_month)
    this.daterangepickerModel[0]=new Date(moment().subtract(1,'M').toString());
    this.daterangepickerModel[1]=new Date();

/* if(this.user_id){


      this.api.GettingTransactionHistory(this.user_id,this.account_id,moment().format('DD-MM-yyyy'),this.one_month).subscribe((x:GOResponse<LastTransaction[]>)=>{
        this.transactions=x.data;
        this.transactionsCache=x.data;
this.filters= Array.from(new Set(this.transactions.map(x=>x.transaction_type)))
          })
     }else{
      this.GetHistory();
     } */
  
 
  }
GetHistory(){
  this.api.GettingTransactionHistory().subscribe((x:GOResponse<LastTransaction[]>)=>{
this.transactions=x.data;
this.transactionsCache=x.data;
this.filters= Array.from(new Set(this.transactions.map(x=>x.transaction_type)))
  })
}

SearchFilter(value){
  this.filter=value;
  if(value){
 this.transactions= this.transactionsCache.filter(r => Utilities.searchArray(value, false, r.transaction_type));  
  }else{
    this.transactions=this.transactionsCache
  }  
}
dateChange(){
 // console.log(this.user_id,this.account_id,new DatePipe('en-US').transform(this.daterangepickerModel[1],'dd-MM-yyyy').valueOf(), new DatePipe('en-US').transform(this.daterangepickerModel[0].valueOf(),'dd-MM-yyyy'))

  this.api.GettingTransactionHistory(this.user_id,this.account_id,new DatePipe('en-US').transform(this.daterangepickerModel[1],'dd-MM-yyyy').valueOf(), new DatePipe('en-US').transform(this.daterangepickerModel[0].valueOf(),'dd-MM-yyyy')).subscribe((x:GOResponse<LastTransaction[]>)=>{
    this.transactions=x.data;
    this.transactionsCache=x.data;
this.filters= Array.from(new Set(this.transactions.map(x=>x.transaction_type)))
      })

 }
 ReverseTransaction(transaction){
  console.log(transaction)
  this.modalRef = this.modalService.show(ConfirmationDialogComponent, {
    initialState: {
      prompt: `Are you sure you want to reverse the <b>deposit</b> amount ${this.currency} ${Number(transaction?.credit).toLocaleString()} recorded on ${this.datePipe.transform(transaction.initiation_time,'medium')}?`,
      callback: (result) => {
        if (result.confirmation == 'yes'){
          
          let obj:any = {
            "transaction_id": transaction.id,
            "reason": result?.reason,
            //"custody_account":this.custody_accounts.filter(c=>c.currency==this.account_viewed.account_type&&c.form=='TELLER')[0]?.id
        }     
       
        console.log(obj);
        /*  */this.api.InitiatingTransactionReversal(obj).subscribe((x:GOResponse<any>)=>{
          this.GetHistory();
          this.SaveError(x.message);
        },(err:GOResponse<any>)=>{ this.SaveError(err.error.message)});
        }
      },
      has_reason:true
    },
    backdrop:'static',
    keyboard:false,
    
  })
} 
SaveError(error:string){
  this.modalRef = this.modalService.show(ConfirmationDialogComponent, {
    initialState: {
      prompt: error,
      has_reason: false,
      is_alert:true,
      callback: (result) => {
        if (result.confirmation == 'yes') {
         
         
        }
        if (result.confirmation == 'no') {
        
        }
      }
    },
    backdrop: 'static',
    keyboard: false
  })
}
}
