import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-yah-svg',
  templateUrl: './yah-svg.component.html',
  styleUrls: ['./yah-svg.component.css']
})
export class YahSvgComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
