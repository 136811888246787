import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ConfirmationService {
  status= new Subject<{item:string,status?:boolean,message?:string}>();
current_status
  constructor() { }
  SetCurrentStatus(status:{item:string,status?:boolean,message?:string}){
    this.current_status=status;
this.status.next(status);
  }
  ResetConfirmation(){
    this.current_status=null;
    this.status.next(null);
  }
}
