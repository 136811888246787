import { Component, OnInit, TemplateRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ConfirmationDialogComponent } from '../../common/confirmation-dialog/confirmation-dialog.component';
import { LoanProduct, GOResponse, CollateralType, FormModel, CollateralTypeDTO, CollateralTypeViewModel, CollateralTypeLoanProduct, CollateralTypeCaptureDetail } from '../../models/app.model';
import { AdminService } from '../../services/admin.service';
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';

@Component({
  selector: 'app-collateral-types',
  templateUrl: './collateral-types.component.html',
  styleUrls: ['./collateral-types.component.css']
})
export class CollateralTypesComponent implements OnInit {
  CollateralTypeModalRef:BsModalRef;
  
  CollateralTypeForm:FormGroup;
  isSubmitted:boolean=false;
  collateralTypes: CollateralTypeViewModel[]=[];
  isSaving:boolean=false;
  message:string;
  products: LoanProduct[]=[];
  modalRef: BsModalRef;
  collateral_type: CollateralTypeDTO;
  isPhone: boolean;
  constructor(private fb:FormBuilder,private modalService: BsModalService, private api:AdminService,private breakpointObserver:BreakpointObserver) {
    //  console.log(this.route)
    this.breakpointObserver.observe([
      Breakpoints.XSmall,
      Breakpoints.Small,
      Breakpoints.Medium,
      Breakpoints.Large,
      Breakpoints.XLarge
    ]).subscribe( (state: BreakpointState) => {
      //console.log(state)
      if(state.breakpoints[Breakpoints.Small] || state.breakpoints[Breakpoints.XSmall]){
           this.isPhone=true;
      }else{
        this.isPhone=false;
      }
    });
   }
  
  CollateralLoanProductConfig:FormModel<CollateralTypeLoanProduct>={
    loan_product_id:['',Validators.required],
    max_loan_amount:['', Validators.required],
    currency:['',Validators.required]
        }
        CollateralTypeCaptureDetailConfig:FormModel<CollateralTypeCaptureDetail>={
          title: ['',Validators.required],
          type: ['',Validators.required],
          field:[''],
          required:[false]
        }

   CollateralTypeConfig:FormModel<CollateralTypeDTO> = {
      id:[''],
      collateral_description:['',Validators.required],
      collateral_type:['',Validators.required],
      short_name:[''],
      loan_products:this.fb.array([]),      
      collateral_captured_details:this.fb.array([]),
      has_fsv:[false],
fsv_percentage:[null, [Validators.min(0),Validators.max(100)]]
    }
   
  ngOnInit(): void {
    this.loadCollateralTypes();
  }
  loadProducts(){
    this.api.GetProducts('admin').subscribe((x:GOResponse<LoanProduct[]>)=>{
      this.products=x.data
    })
  }

  Save():void{
  this.isSaving=true;
  if(this.CollateralTypeForm.get('id').value){
    this.api.UpdateCollateralType(this.CollateralTypeForm.value).subscribe(x=>{this.isSaving=false;},(e:GOResponse<any>)=>{this.isSaving=false;this.message=e.error.message;},()=>{this.loadCollateralTypes();this.CancelCollateralTypeModal()});
  }else{
    this.api.PostCollateralType(this.CollateralTypeForm.value).subscribe(x=>{this.isSaving=false;},(e:GOResponse<any>)=>{this.isSaving=false;this.message=e.error.message;},()=>{this.loadCollateralTypes();this.CancelCollateralTypeModal()});
  }

  }
  loadCollateralTypes(){
    this.api.GetCollateralTypes('admin').subscribe((x:GOResponse<CollateralTypeViewModel[]>)=>{
      this.collateralTypes=x.data
    })
  }
  AddLoanProduct(){
    let loanProductArray:FormArray= <FormArray>this.CollateralTypeForm.get('loan_products');   
      loanProductArray.push(this.fb.group(this.CollateralLoanProductConfig));
  }
  RemoveLoanProduct(i){
    let loanProductArray:FormArray= <FormArray>this.CollateralTypeForm.get('loan_products'); 
    loanProductArray.removeAt(i)
  }
  NewField(){
    let collateralDetailsArray:FormArray= <FormArray>this.CollateralTypeForm.get('collateral_captured_details');
    collateralDetailsArray.push(this.fb.group(this.CollateralTypeCaptureDetailConfig));
  }
  RemoveField(i){
    let collateralDetailsArray:FormArray= <FormArray>this.CollateralTypeForm.get('collateral_captured_details');
    collateralDetailsArray.removeAt(i)
  }
  CreateNewCollaterlaType(template: TemplateRef<any>){
    this.isSubmitted=false;
    this.loadProducts();
    this.CollateralTypeForm= this.fb.group(this.CollateralTypeConfig);
    let loanProductArray:FormArray= <FormArray>this.CollateralTypeForm.get('loan_products');
    if(loanProductArray.length==0){
     loanProductArray.push(this.fb.group(this.CollateralLoanProductConfig));  
    }
   
   this.NewField();
    console.log(this.CollateralTypeForm.value)
    this.CollateralTypeModalRef= this.modalService.show(template,{backdrop:'static',class:"modal-lg "+ this.isPhone?'': "w-100"});
  }

  CancelCollateralTypeModal(){
    this.isSubmitted=false;
    this.CollateralTypeModalRef.hide();
  }
Edit(c_t:CollateralTypeDTO,template: TemplateRef<any>){
  this.isSubmitted=false;
  this.loadProducts();
  this.CollateralTypeForm= this.fb.group(this.CollateralTypeConfig);
  let loanProductArray:FormArray= <FormArray>this.CollateralTypeForm.get('loan_products');
  loanProductArray.clear();
  c_t.loan_products.forEach((t,i)=>{
   loanProductArray.push(this.fb.group(this.CollateralLoanProductConfig));  
   loanProductArray.at(i).patchValue(t)
  })  
  this.CollateralTypeForm.patchValue(c_t);
      let collateralDetailsArray:FormArray= <FormArray>this.CollateralTypeForm.get('collateral_captured_details');
    collateralDetailsArray.clear();
  c_t.collateral_captured_details.forEach((c_d,i)=>{
    collateralDetailsArray.push(this.fb.group(this.CollateralTypeCaptureDetailConfig));
collateralDetailsArray.at(i).patchValue(c_d)
  })
  this.CollateralTypeModalRef= this.modalService.show(template,{backdrop:'static',class:"modal-lg "+ this.isPhone?'': ""});
}
ViewCollateralType(c_t:CollateralTypeDTO){
  this. collateral_type= c_t;

}
DeleteCollateralType(c_t:CollateralTypeDTO){
  this.modalRef = this.modalService.show(ConfirmationDialogComponent, {
    initialState: {
      prompt: `Are you sure you want to delete <b>${c_t?.collateral_type}</b>?`,
      has_reason:true,
      callback: (result) => {
        if (result.confirmation == 'yes'){
          this.api.DeleteCollateralType(c_t?.id,result?.reason).subscribe(x=>{
            this.loadCollateralTypes();
          })
        }
      }
    },
    backdrop:'static',
    keyboard:false
  })
}
}
