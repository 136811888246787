import { Component, Input, OnInit, Output,EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { CollateralTypeDTO, GOResponse } from '../../models/app.model';
import { FieldModel } from '../../models/custom-form.base.model';
import { AdminService } from '../../services/admin.service';
import { FormControlService } from '../../services/form-control.service';

@Component({
  selector: 'app-collateral',
  templateUrl: './collateral.component.html',
  styleUrls: ['./collateral.component.css']
})
export class CollateralComponent implements OnInit {

  @Input('group') CollateralSubmissionForm:FormGroup;

  @Output('setCustomFields') setCustomFields:any=new EventEmitter<any>();

  @Input('custom_fields') CollatForm: FormGroup;

  @Input('isSubmitted') isRequestSubmitted:boolean

  collateralTypes: CollateralTypeDTO[]=[];

  banks:any[]=[
    {
      "name": "ABC Capital Bank Uganda Limited"
    },
    {
      "name": "Absa Bank Uganda Limited"
    },
    {
      "name": "Afriland First Bank Uganda Limited"
    },
    {
      "name": "Bank of Africa Uganda Limited"
    },
    {
      "name": "Bank of Baroda Uganda Limited"
    },
    {
      "name": "Bank of India Uganda Limited"
    },
    {
      "name": "BRAC Bank Limited"
    },
    {
      "name": "Cairo International Bank"
    },
    {
      "name": "Centenary Rural Development Bank Limited"
    },
    {
      "name": "Citibank Uganda Limited"
    },
    {
      "name": "DFCU Bank"
    },
    {
      "name": "Diamond Trust Bank Limited"
    },
    {
      "name": "Ecobank Uganda Limited"
    },
    {
      "name": "Equity Bank Uganda Limited"
    },
    {
      "name": "Exim Bank Uganda"
    },
    {
      "name": "Finance Trust Bank"
    },
    {
      "name": "Guaranty Trust Bank Uganda Limited"
    },
    {
      "name": "Housing Finance Bank Limited"
    },
    {
      "name": "KCB Bank Uganda Limited"
    },
    {
      "name": "NCBA Bank Uganda"
    },
    {
      "name": "Opportunity Bank Uganda Limited"
    },
    {
      "name": "Orient Bank Limited"
    },
    {
      "name": "PostBank Uganda Limited"
    },
    {
      "name": "Pride Microfinance Limited (MDI)"
    },
    {
      "name": "Stanbic Bank Uganda Limited"
    },
    {
      "name": "Standard Chartered Bank Uganda Limited"
    },
    {
      "name": "Top Finance Bank (TFB)"
    },
    {
      "name": "Tropical Bank Limited"
    },
    {
      "name": "United Bank for Africa"
    }
   ];
  
  constructor(private api:AdminService,private formControlService: FormControlService) {
    this.loadCollateralTypes();
   }

  ngOnInit(): void {
  }

  loadCollateralTypes(){
    this.api.GetCollateralTypes('public').subscribe((x:GOResponse<CollateralTypeDTO[]>)=>{
      this.collateralTypes=x.data
    })
  }
  onFileChange(event) {
    console.log(event)
   if (event.target.files.length > 0) {
     const file = event.target.files[0];
     
     this.CollateralSubmissionForm.patchValue({
       collateral_file: file
     });
    
   }
 }
  SetCustomFields(t){
    let col:CollateralTypeDTO=this.collateralTypes.filter(x=>x.id==t)[0]
   this.fields=[];
   col.collateral_captured_details.forEach(c_f=>{
   this.fields.push( new FieldModel({label:c_f.title, id:`${c_f.title.toLocaleLowerCase().replace(/ /g,"_")}`,controlType:c_f.type,required:c_f.required?c_f.required:false,errorMessage:`${c_f.title} is required`}))
   })
   console.log(this.fields);
   this.CollatForm = this.formControlService.getFormGroupObject(this.fields);
   this.setCustomFields.emit(this.CollatForm)
   }
   fields: FieldModel[];
   }


