import { Component, OnInit, TemplateRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ConfirmationDialogComponent } from '../common/confirmation-dialog/confirmation-dialog.component';
import { CollateralDto, CollateralProfileVM, CollateralReview, CollateralTypeDTO, CollateralVM, CustodyAccountVM, GOResponse, LoanApproval, LoanProfile, UserProfile } from '../models/app.model';
import { FieldModel } from '../models/custom-form.base.model';
import { AdminService } from '../services/admin.service';
import { FormControlService } from '../services/form-control.service';
import { Utilities } from '../services/utilities.service';
import { AuthService } from '../services/auth.service';
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';

@Component({
  selector: 'app-loan-application',
  templateUrl: './loan-application.component.html',
  styleUrls: ['./loan-application.component.css']
})
export class LoanApplicationComponent implements OnInit {

isESVSubmitted:boolean=false;

  EstimatedValueForm:FormGroup;

  loan_id:string;

  isEstimatedValueSaved:any[]=[];
  isSubmitCollapsed:boolean=true;

  loan_profile:LoanProfile;

  isColCollapsed:boolean[]=[]
  modalRef: any;
  custody_accounts: CustodyAccountVM[]=[];
  collateralView:(CollateralProfileVM);
  CollateralViewModalRef: BsModalRef;
  NewCollateralModalRef: BsModalRef;
  selectedIndex: number;
  col:FormGroup;
  isRequestSubmitted:boolean=false;
  collateralTypes: CollateralTypeDTO[];
  isSaving: boolean;
  collateral_message: string;
  collaterals: CollateralVM[];
  modalRefCol: BsModalRef;
  secondModalOpen:boolean=false;
  approval_message:string;
  isPhone: boolean;
  constructor(private fb:FormBuilder,private route:ActivatedRoute,private api:AdminService, private modalService:BsModalService,private formControlService: FormControlService,
    private authService:AuthService,private breakpointObserver:BreakpointObserver) {
      this.breakpointObserver.observe([
        Breakpoints.XSmall,
        Breakpoints.Small,
        Breakpoints.Medium,
        Breakpoints.Large,
        Breakpoints.XLarge
      ]).subscribe( (state: BreakpointState) => {
        //console.log(state)
        if(state.breakpoints[Breakpoints.Small] || state.breakpoints[Breakpoints.XSmall]){
             this.isPhone=true;
        }else{
          this.isPhone=false;
        }
      });
  
    this.EstimatedValueForm= this.initEstimatedValue();
    
   }

  initEstimatedValue(){
return this.fb.group({
 // has_fsv:['',Validators.required],
  actual_value:[0],
 // forced_sale_value:[''],
  comments:['']
})
  }
  initCollateralSubmission(){
    return this.fb.group({
      id:[''],
      loan:[''],
      collateral_type:['',Validators.required],     
      check_form:['', Validators.requiredTrue],
     /*  leaf_number:[''],
      cheque_name:[''],
      bank:[''],
      number_plate:[''],
      car_picture:[''],
      location:[''], */
      collateral_description:[''],
      collateral_identification_number:[''],
      collateral_value:['',Validators.required],
      collateral_file:[''],
      custom_information:this.fb.array([]),
      collateral_bank:[''],
      isSaved:[false]
    })
  }
  loadCustodyAccounts(){
    this.ConfirmationForm=this.initConfirmationForm();
    if(this.loan_profile.profile.loan_status!='Pending Approval'){
      this.ConfirmationForm.disable();
    }
    this.api.GetCustodyAccounts().subscribe((x:GOResponse<CustodyAccountVM[]>)=>{
      this.custody_accounts=x.data.filter(x=>x.currency==this.loan_profile.profile.loan_account&&x.form=='MAIN');
            })
  }
  ngOnInit(): void {
    this.route.params.subscribe(x=>{
      this.api.GetLoanDetails(x['loan_id']).subscribe((x:GOResponse<LoanProfile>)=>{
        this.loan_id=x['loan_id'];
        this.loan_profile=x.data
      })
    })
  }
getLoan(load_accounts?:boolean){
  this.api.GetLoanDetails(this.loan_profile.profile.id).subscribe((x:GOResponse<LoanProfile>)=>{    
    this.loan_profile=x.data
  },r=>{},()=>{
    if(load_accounts){
      this.loadCustodyAccounts();
    }
  })
}

getFullname(u:UserProfile){
  return Utilities.getFullname(u)
}
removeHyphen(t){
  if(t){
    return t.replace(/-/g, "").substr(1,6); 
  }
 
}
currentTab=0;
ToggleForm(level:number,template?:any){
  this.selectedIndex=level;
  if(this.loan_profile.collateral?.length){
    let a= Array.from(Array(this.loan_profile.collateral?.length).keys())
  a.forEach(el => {
   /*  if(this.loan_profile.collateral[el].review_status=='Approved'&&this.isCollateralApproved()){ */
this.View(this.loan_profile.collateral[level],template)
    /* } else{
      if(el==level){
      this.isColCollapsed[level]=!this.isColCollapsed[level];
      this.currentTab=level;
    }else{
      this.isColCollapsed[el]=false
    }  
    }*/
  
  });
  }
 
}
ApproveCollateral(){

  this.collateral_message='';
  this.secondModalOpen=true;
 let obj:LoanApproval = {
    "id": this.loan_profile.profile.id,
    "loan_status": "Pending Approval",
    "comments": "The collateral meets the criteria"
}
let s = null;

switch(this.SubmissionForm.get('action').value){
  case 'Approve':
    s=`Are you sure you want to submit this loan for approval ?`;
 
    break;
  case 'Reject':
    s=`Are you sure you want to submit this loan for rejection ?`;
    break;
    case 'Notify':
      s=`Are you sure you want to send this message to the borrower ?`;
      break;
}

this.modalRef = this.modalService.show(ConfirmationDialogComponent, {
  initialState: {
    prompt: s,
    has_reason:false,
    callback: (result) => {
      if (result.confirmation == 'yes'){
        
this.api.SubmitLoanForApproval(this.SubmissionForm.value).subscribe(x=>{
  this.getLoan();
  this.SubmissionForm= this.initSubmission();
  this.CloseSubmissionModal();
  this.secondModalOpen=false;
},(e:GOResponse<any>)=>{
  this.isSaving=false;
  this.collateral_message=e.error.message;
  this.secondModalOpen=false;
})
}
if (result.confirmation == 'no'){
this.secondModalOpen=false;
}
}
},
backdrop:'static',
keyboard:false
})
}
Next(){

}
ApproveSingleCollateral(id,i,status?){
this.collateral_message='';
  let obj:CollateralReview={
    "id": id,
    "review_status": status?"Rejected":"Approved",
    actual_value:this.EstimatedValueForm.get('actual_value').value,
    "comments": this.EstimatedValueForm.get('comments').value
}
console.log(obj,i);
if(this.loan_profile.collateral.length>1){
  this.secondModalOpen=true;
}
this.modalRefCol = this.modalService.show(ConfirmationDialogComponent, {
  backdrop: true,
  class:this.loan_profile.collateral.length>1?'second_modal':'',
  ignoreBackdropClick: true,
  initialState: {
    prompt: `Are you sure you want to ${status?'reject':'approve'} this collateral ?`,
    has_reason: false,
    callback: (result) => {
      if (result.confirmation == 'yes') {
this.api.ReviewCollateral(obj).subscribe(x=>{
  this.isEstimatedValueSaved[i]=true;
  this.CloseCollateralViewModel();

  this.EstimatedValueForm= this.initEstimatedValue();
  if((i+1)<this.loan_profile.collateral.length){
   // this.ToggleForm(i+1);
  }
  this.getLoan();
  if(this.loan_profile.collateral.length>1){
    this.secondModalOpen=false;
  }
},(e:GOResponse<any>)=>{
  if(this.loan_profile.collateral.length>1){
    this.secondModalOpen=false;
  }
  this.isSaving=false;this.collateral_message=e.error.message;
})
///if the last collateral is approved it will automatically aprrove all collateral
/* if(this.loan_profile.collateral.filter(x=>x.review_status=='Approved').length==this.loan_profile.collateral.length){
  this.ApproveCollateral();
} */
}
if (result.confirmation == 'no') {
  this.collateral_message="";
  setTimeout(() => {
  this.secondModalOpen=false;  
  }, 300);
  
}
}
},
keyboard: false
})

}
SendCommenttoBorrower(){
  
}

isCollateralApprovedOrRejected(){
  if(this.loan_profile){
    return this.loan_profile.collateral.filter(x=>x.review_status=='Approved'||x.review_status=='Rejected').length>0; 
  }else{
    return false
  }
 
}
custody_account_id;
ConfirmationForm:FormGroup;
initConfirmationForm(){
  return this.fb.group({
    start:[''],
    "id": [this.loan_profile.profile.id],
    "loan_status": ["Approved"],
    "custody_account": [''],
    "comments": [""]
  })
}
ApproveLoan(reject?){
/*   let confirmation={
    "id": this.loan_id,
    "loan_status": "Approved",
    "custody_account": this.custody_account_id,
    "comments": "The loan has been approved"
} */
this.approval_message='';
if(reject){
  this.ConfirmationForm.get('loan_status').setValue('Rejected');
}else{
  this.ConfirmationForm.get('loan_status').setValue('Approved');
}



  this.modalRef = this.modalService.show(ConfirmationDialogComponent, {
    initialState: {
      prompt: reject?`Are you sure you want to reject this application?<br> This action will cancel the loan application`:`Are you sure you want to approve this application? This action will credit the applicant's wallet?`,
      has_reason: false,
      callback: (result) => {
        if (result.confirmation == 'yes') {
          this.api.ApproveLoanApplication(this.ConfirmationForm.value).subscribe(x => {
            this.getLoan(true);
             this.secondModalOpen=false;
          },(e:GOResponse<any>)=>{
            this.approval_message=e.error.message
          })
         
        }
        if (result.confirmation == 'no') {
          this.secondModalOpen=false;
        }
      }
    },
    backdrop: 'static',
    keyboard: false
  })
}
setStart(){
  this.ConfirmationForm.get('start').setValue(true)
}
getSum(){
 return this.loan_profile.collateral.filter(x=>x.review_status=='Approved').reduce((sum, current) => sum + current.collateral_value, 0);
}
View(c:(CollateralProfileVM),template: TemplateRef<any>){
  this.collateralView=null
  this.collateralView=c; 
  if(!this.CollateralViewModalRef){
     this.CollateralViewModalRef= this.modalService.show(template,{backdrop:'static',keyboard:false,class:"modal-lg"+this.isPhone?'':" w-50"});
  }
 
}
CloseCollateralViewModel(){
  this.collateral_message='';
  if(this.loan_profile.collateral?.length>1){
   this.CollateralViewModalRef.hide();
  this.CollateralViewModalRef=null; 
  this.secondModalOpen=false;

  }
  
}
getFieldName(name){
  return'name' // this.collateralView.collateral_type.collateral_captured_details.filter(x=>x.title.toLocaleLowerCase().replace(/ /g,"_")==name)[0]?.title
  }
/* getFieldName(name){
  return  this.loan_profile.collateral[0]..collateral_type.collateral_captured_details.filter(x=>x.title.toLocaleLowerCase().replace(/ /g,"_")==name)[0]?.title
  } */
  AddNewColl(template: TemplateRef<any>){
    this.loadCollateralTypes();
    this.col= this.initCollateralSubmission();
    if(!this.NewCollateralModalRef){
      this.NewCollateralModalRef= this.modalService.show(template,{backdrop:'static',keyboard:false,class:"modal-lg"+this.isPhone?'':" w-50"});
   }
  
  }
  loadCollateralTypes(){
    this.api.GetCollateralTypes('public').subscribe((x:GOResponse<CollateralTypeDTO[]>)=>{
      this.collateralTypes=x.data
    })
  }
  CloseNew(){
    this.NewCollateralModalRef.hide();
    this.NewCollateralModalRef=null;
    this.col= this.initCollateralSubmission();
   }
   SaveCol(col){
    if (col.get('collateral_bank').value) {

      this.api.UseExistingCollateral(this.loan_profile.profile.id, col.get('collateral_bank').value).subscribe((x: any) => {


      }, (e: GOResponse<any>) => { this.isSaving = false; this.collateral_message = e.error.message; console.log(e); },
        () => {
         // col.get('isSaved').setValue(true);

        /*   if (addNewCol) {
            this.isColCollapsed[this.getCollaterals.length - 1] = false
            this.getCollaterals.push(this.initCollateralSubmission())
            this.isColCollapsed[this.getCollaterals.length - 1] = true;
            this.currentTab = this.getCollaterals.length - 1
          } */

        })
    } else {

      if(this.CollatForm?.value){
       col.get('custom_information').push(this.CollatForm) 
      }
      
      let colObj: CollateralDto = {
id:col.get('id').value,
        loan: this.loan_profile.profile.id,
        collateral_description: col.get('collateral_description').value,
        collateral_file: col.get('collateral_file').value,
        collateral_identification_number: '123456',
        collateral_type: col.get('collateral_type').value,
        collateral_value: col.get('collateral_value').value,
        custom_information: this.CollatForm?.value?JSON.stringify([this.CollatForm.value]):''
      }
      console.log(colObj)
      col.get('isSaved').setValue(true);
      this.api.PostCollateral(colObj).subscribe((x: any) => {
        if(x?.body){
          col.get('id').setValue(x?.body?.data?.loan_collateral_id);
          this.getLoan();
          this.CloseNew();
        }       
       
        console.log(x);
      }, (e: GOResponse<any>) => { this.isSaving = false; this.collateral_message = e.error.message; console.log(e) },
        () => {
         /*  if (addNewCol) {
            this.isColCollapsed[this.getCollaterals.length - 1] = false;
            this.getCollaterals.push(this.initCollateralSubmission())
            this.isColCollapsed[this.getCollaterals.length - 1] = true;
            this.currentTab = this.getCollaterals.length - 1
          } */

        })
    }
   }
   onFileChange(event, col) {
    console.log(event)
    if (event.target.files.length > 0) {
      const file = event.target.files[0];

      col.patchValue({
        collateral_file: file
      });

    }
  }
   SetCustomFields(t,collateral_custom_fields?:any[]){

    if(t!='Existing'){  
   let col:CollateralTypeDTO=this.collateralTypes.filter(x=>x.id==t)[0]
  
  this.fields=[]
  if(col.collateral_captured_details?.length>0){
    col.collateral_captured_details.forEach((c_f,index)=>{
     const c_o=collateral_custom_fields?.length>0?collateral_custom_fields[0]:null
    
     const t= c_f.title.toLocaleLowerCase().replace(/ /g,"_");
     console.log(c_o)
  if(collateral_custom_fields){
    console.log(c_o[t])
  }
  this.fields.push( new FieldModel(
    {label:c_f.title, id:`${c_f.title.toLocaleLowerCase().replace(/ /g,"_")}`,
    controlType:c_f.type,required:c_f.required?c_f.required:false,
    errorMessage:`${c_f.title} is required`,
  value:c_o?c_o[t]:''
  }))
  })
  this.CollatForm = this.formControlService.getFormGroupObject(this.fields);
  }
  
  //console.log(this.fields);
  
  }else{
    this.loadCollaterals();
    this.fields=[]
  }
  }
  fields: FieldModel[]=[];
  CollatForm: FormGroup;
  loadCollaterals(){
    this.api.GetCollateral().subscribe((x:GOResponse<CollateralVM[]>)=>{
  this.collaterals=x.data;
    })
  }
  initSubmission(){
    return this.fb.group({
      "id": [''],
      'action':[''],
      "loan_status": [''],
      "comments": [''],
      "to_borrower":[false],
      reviewer_notes_to_borrower:[''],
      requires_more_collateral:[false]
    })
  }
  SubmissionForm:FormGroup;
  submissionRef;
  isSubmission:boolean=false;
  OpenSubmissionModal(submissionTemplateRef,action:string){
    this.SubmissionForm= this.initSubmission();
    this.setNotifyValidation();

this.isSubmission=false;
    this.SubmissionForm.get("id").setValue(this.loan_profile.profile.id)
this.SubmissionForm.get('action').setValue(action)
if(action=='Notify'){
  this.SubmissionForm.get('to_borrower').setValue(true);
  this.SubmissionForm.get('comments').setValue('N/A');
  this.SubmissionForm.get("loan_status").setValue("Pending Collateral Review");


}else{
  this.SubmissionForm.get('to_borrower').setValue(false);
  if(action=='Approve'){

    this.SubmissionForm.get("loan_status").setValue("Pending Approval")
  }
  if(action=='Reject'){
    this.SubmissionForm.get("loan_status").setValue("Pending Rejection Approval")
  }
}
this.submissionRef= this.modalService.show(submissionTemplateRef)

  }
  setNotifyValidation(){
   const revComment= this.SubmissionForm.get('reviewer_notes_to_borrower');
   const toBorrower= this.SubmissionForm.get('to_borrower');
   toBorrower.valueChanges.subscribe(x=>{
     if(x==true){
      revComment.setValidators([Validators.required]);
     }else{
       revComment.setValidators([null]);
     }
   })
   revComment.updateValueAndValidity();
  }
  CloseSubmissionModal(){
    this.submissionRef.hide();
    this.collateral_message="";
  }

  CancelLoanApplication(){

  }
  ReturnToReviewer(){
   
    this.modalRef = this.modalService.show(ConfirmationDialogComponent, {
      initialState: {
        prompt: `Are you sure you want to return this loan application for review?`,
        has_reason:true,
        callback: (result) => {
          if (result.confirmation == 'yes'){
        let obj = {
        "id": this.loan_profile.profile.id,
      "loan_status": "Pending Collateral Review",
      "comments": result?.reason,
      "reviewer_notes_to_borrower": "N/A"
    }     
    this.api.SubmitLoanForApproval(obj).subscribe(x=>{
      this.getLoan();
      ;
    },(e:GOResponse<any>)=>{this.isSaving=false;this.collateral_message=e.error.message;
      this.secondModalOpen=false;
    })
   
  }
  if (result.confirmation == 'no') {
    this.secondModalOpen=false;
  }
    
    }
    },
    backdrop:'static',
    keyboard:false
    })
   
  }
get CanApproveOrReview(){
  return this.authService.currentUser.roles!='Teller'
}
get canApprove(){
  return this.authService.currentUser.roles!='Reviewer'
}

}
