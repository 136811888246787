import { Component, OnInit, Input, TemplateRef, ViewChild, ViewContainerRef, ComponentFactoryResolver, ElementRef } from '@angular/core';
import { UserSummary, GOResponse, LoanProfile, PaymentSchedule, Transaction, FormModel, FormControls } from '../models/app.model';
import { AdminService } from '../services/admin.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { SuccessSvgComponent } from '../common/success-svg/success-svg.component';
import { FailSvgComponent } from '../common/fail-svg/fail-svg.component';
import { FormGroup, Validators, FormBuilder, FormControlName, FormControlDirective } from '@angular/forms';

/* const originFormControlNgOnChanges = FormControlDirective.prototype.ngOnChanges;
FormControlDirective.prototype.ngOnChanges = function () {
    this.form.nativeElement = this.valueAccessor._elementRef.nativeElement;
    return originFormControlNgOnChanges.apply(this, arguments);
};
const originFormControlNameNgOnChanges = FormControlName.prototype.ngOnChanges;
FormControlName.prototype.ngOnChanges = function () {
    const result = originFormControlNameNgOnChanges.apply(this, arguments);
    this.control.nativeElement = this.valueAccessor._elementRef.nativeElement;
    return result;
}; */
@Component({
  selector: 'app-pay',
  templateUrl: './pay.component.html',
  styleUrls: ['./pay.component.css']
})

export class PayComponent implements OnInit {
@Input('btn-text') button_text?:string;
  @Input('summary') summary:UserSummary;
  @Input() callbackFunction: (args?: any) => void;
  @Input() viewed_account?:string;
  loan_profile: LoanProfile;
  selectedInstallment:PaymentSchedule;
  PayModalRef: BsModalRef;
  tabs:boolean[]=[];
isAmountSelected:boolean=false;
isSuccess: boolean=false;
  isFailed: boolean=false;
  error_message: string;
  text: string;
;
isPlatformSelected:boolean=false;


  @ViewChild('messagecontainer', { read: ViewContainerRef }) entry: ViewContainerRef;
  @ViewChild('myAmount') myAmount:ElementRef;
  componentRef: any;

  TransactionForm:FormGroup;
UserFormConfig:FormModel<Transaction>;
  formControls: FormControls<Transaction>;

  constructor(private api:AdminService, private modalService:BsModalService,private resolver: ComponentFactoryResolver,
    private fb:FormBuilder) {
      this.InitiateMyWalletToMomo();
     }

  ngOnInit(): void {
    if(this.summary.user_profile.loan_reference){
      this.getLoan(this.summary.user_profile.loan_reference)
    }
  }
  getLoan(id){
    this.api.GetLoanDetails(id).subscribe((x:GOResponse<LoanProfile>)=>{    
      this.loan_profile=x.data
      this.selectedInstallment=this.loan_profile.payment_schedule[0];
    })
  }
  getToday(){
    return new Date();
  }
  InitiateMyWalletToMomo(){
    this.UserFormConfig={
      account:[''],
      amount:[0,[Validators.required,Validators.min(1)]],
      comments:[''],
      platform:[null],
      source:['Admin Portal'],
      type:['Loan Repayment'],
      agree:[null],
      phone:[null]
    }
    
    this.TransactionForm=this.fb.group(this.UserFormConfig);
    this.formControls=this.TransactionForm?.controls as FormControls<Transaction>;
  }
  SetPlatform(plat,phone){
this.formControls.platform.setValue(plat);
this.formControls.phone.setValue(phone);
  }
  current_tab:number=0;
  View(template: TemplateRef<any>){ 
    this.tabs=[];
    this.current_tab=0
  this.tabs[0]=true;
  this.InitiateMyWalletToMomo();
    this.PayModalRef= this.modalService.show(template,{backdrop:'static',class:"modal-lg"});
 setTimeout(() => {
   console.log(this.myAmount)
   // this.myAmount.nativeElement.focus();
 }, 1000);
 this.pload("assets/mtn_image.png","assets/airtel_money.png");
  }
  NextTab(){
    this.isAmountSelected=false;
    if(this.current_tab==1){
      if(this.formControls.platform.value==null){
        this.isPlatformSelected=true;
      }else{
        this.isPlatformSelected=false;
        this.tabs[this.current_tab]=false;
    this.current_tab=this.current_tab+1;
    this.tabs[this.current_tab]=true;
      }
    }else{
      this.isPlatformSelected=false;
       this.tabs[this.current_tab]=false;
    this.current_tab=this.current_tab+1;
    this.tabs[this.current_tab]=true; 
    } 
    if(this.current_tab==2){
this.setCheckBoxValidation(true)
    }else{
      this.setCheckBoxValidation(false)   
    }
   if(this.current_tab==3){
     this.Submit();
   }
    //  this.createComponent(true);
    
  }
  setCheckBoxValidation(set:boolean){
    if(set){
      this.formControls.agree.setValidators([Validators.requiredTrue]);
      this.formControls.agree.updateValueAndValidity();
    }else{
      
        this.formControls.agree.setValidators([]);
      this.formControls.agree.updateValueAndValidity();
      
      
    }

  }
  BackTab(){ 
   
    this.tabs[this.current_tab]=false;
    this.current_tab=this.current_tab-1;
    this.tabs[this.current_tab]=true;
    if(this.current_tab==2){
      this.setCheckBoxValidation(true)
          }else{
            this.setCheckBoxValidation(false)   
          }
  }

  CloseModal(){
    this.PayModalRef.hide();
    
  
  }
  createComponent(success:boolean) {
   if(this.entry){
     this.entry.clear();
   }    
    const factory = this.resolver.resolveComponentFactory(success?SuccessSvgComponent:FailSvgComponent);
    this.componentRef = this.entry.createComponent(factory);
   // this.componentRef.instance.message = message;
}
destroyComponent() {
    this.componentRef.destroy();
}
Submit(){
  this.isFailed= false;
  this.isSuccess = false;
 this.formControls.account.setValue(this.summary.accounts.filter(x=>x.account_type==this.loan_profile.profile.loan_account)[0].id);
  this.api.UserInitiatingMomoWithdraw(this.TransactionForm.value).subscribe((x:GOResponse<any>)=>{
    if(x.status==200){
      
      this.text= x.message;
    
      this.callbackFunction();
      this.isSuccess=true;
    }    
  },(e:GOResponse<any>)=>{
 
    this.error_message=e.error.message;   
    this.isFailed=true;
  })
}


imgs=[];
pload(...args: any[]):void {
for (var i = 0; i < args.length; i++) {
  this.imgs[i] = new Image();
  this.imgs[i].src = args[i];
  console.log('loaded: ' + args[i]);
}
}
getIcon(network):string{
 if(network){
  switch(network){
    case 'MTN':
      return this.imgs[0].src
      
    case 'Airtel':
      return this.imgs[1].src
    
  }
 }else{
return null;
 }


}
}
