import { Component, OnInit } from '@angular/core';
import { AdminService } from '../services/admin.service';
import { PaginationInstance } from 'ngx-pagination';
import { GOResponse, TransactionApproval } from '../models/app.model';
import { ConfirmationDialogComponent } from '../common/confirmation-dialog/confirmation-dialog.component';
import { BsModalService } from 'ngx-bootstrap/modal';
import { UserSummaryService } from '../services/user-summary.service';
import { AuthService } from '../services/auth.service';
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';

@Component({
  selector: 'app-approvals',
  templateUrl: './approvals.component.html',
  styleUrls: ['./approvals.component.css']
})
export class ApprovalsComponent implements OnInit {

  public configSub: PaginationInstance = {
    id: 'approvals',
    itemsPerPage: 10,
    currentPage: 1
  };
  approvals:any[]=[];
  filter='Pending';
  modalRef: any;
  isPhone: boolean;
  constructor(private api:AdminService,private modalService:BsModalService,private userService:UserSummaryService,private authService:AuthService,private breakpointObserver:BreakpointObserver) {
    //  console.log(this.route)
    this.breakpointObserver.observe([
      Breakpoints.XSmall,
      Breakpoints.Small,
      Breakpoints.Medium,
      Breakpoints.Large,
      Breakpoints.XLarge
    ]).subscribe( (state: BreakpointState) => {
      //console.log(state)
      if(state.breakpoints[Breakpoints.Small] || state.breakpoints[Breakpoints.XSmall]){
           this.isPhone=true;
      }else{
        this.isPhone=false;
      }
    }); }

  ngOnInit(): void {
    this.loadTransactionApprovals();
  }
loadTransactionApprovals(){
  this.api.GetCombinedApprovals(this.filter).subscribe((x:GOResponse<any[]>)=>{
this.approvals=x.data
  })
}
ApproveReject(id,reject,approval_type?){

  this.modalRef = this.modalService.show(ConfirmationDialogComponent, {
    initialState: {
      prompt: !reject?`Are you sure you want to reject this transaction?<br> This action will cancel the transaction`:`Are you sure you want to approve this transaction? This action will credit the ${approval_type=='End of day transfer'?'Main Custody Account':"borrower's wallet"} ?`,
      has_reason: true,
      callback: (result) => {
        if (result.confirmation == 'yes') {
          let obj:TransactionApproval={
            id:id,
            comments:result.reason,
            status:!reject?'Rejected':'Approved'
              }
              console.log(obj)
          this.api.ApproveOrRejectTransaction(obj).subscribe(x => {
            this.loadTransactionApprovals();
            this.userService.SaveSummary();
            
          },(err:GOResponse<any>)=>{ this.SaveError(err.error.message)})
         
        }
        if (result.confirmation == 'no') {
        
        }
      }
    },
    backdrop: 'static',
    keyboard: false
  })
}
get currentId(){
  return this.authService.currentUser.id
 }
 SaveError(error:string){
  this.modalRef = this.modalService.show(ConfirmationDialogComponent, {
    initialState: {
      prompt: error,
      has_reason: false,
      is_alert:true,
      callback: (result) => {
        if (result.confirmation == 'yes') {
         
         
        }
        if (result.confirmation == 'no') {
        
        }
      }
    },
    backdrop: 'static',
    keyboard: false
  })
}
 }

