import { Component, OnInit } from '@angular/core';
import { GOResponse, LoanHistoryVM, LoanListVM } from '../models/app.model';
import { AdminService } from '../services/admin.service';

@Component({
  selector: 'app-loan-history',
  templateUrl: './loan-history.component.html',
  styleUrls: ['./loan-history.component.css']
})
export class LoanHistoryComponent implements OnInit {
  loans: LoanHistoryVM[]=[];

  constructor(private api:AdminService) { }

  ngOnInit(): void {
    this.loadHistory()
  }
  loadHistory(){
    this.api.GetLoanHistory(false).subscribe((x:GOResponse<LoanHistoryVM[]>)=>{
      this.loans=x.data
        })
  }
  removeHyphen(t){
    if(t){
      return t.replace(/-/g, "").substr(1,6); 
    }
  }
}

