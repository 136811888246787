import { Component, OnInit, TemplateRef } from '@angular/core';
import { PaginationInstance } from 'ngx-pagination';
import { AdminService } from '../services/admin.service';
import { GOResponse, LastTransaction, CustodyAccountVM, FormModel, Transaction, FormControls, EndOfDaySummary } from '../models/app.model';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { BsModalService } from 'ngx-bootstrap/modal';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { ConfirmationDialogComponent } from '../common/confirmation-dialog/confirmation-dialog.component';
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';

@Component({
  selector: 'app-end-of-day',
  templateUrl: './end-of-day.component.html',
  styleUrls: ['./end-of-day.component.css']
})
export class EndOfDayComponent implements OnInit {

  list:CustodyAccountVM[]=[];
  public configSub: PaginationInstance = {
    id: 'end-of-day',
    itemsPerPage: 10,
    currentPage: 1
  };
  usd_list: LastTransaction[]=[];
  ugx_list: LastTransaction[]=[];
  TransferFormConfig:FormModel<Transaction>;
  formControls: FormControls<Transaction>;
  today:Date= new Date();
  summary: EndOfDaySummary;
  TransferModalRef: any;
  TransactionForm:FormGroup;
  secondModalOpen:boolean=false;
  isSubmitted:boolean=false;
  need_reason:boolean=false;
  init_message: string;
  selected_currency;
  modalRef: any;
  isPhone: boolean;
  constructor(private api:AdminService, private modalService:BsModalService, private fb:FormBuilder,private breakpointObserver:BreakpointObserver) {

    this.breakpointObserver.observe([
      Breakpoints.XSmall,
      Breakpoints.Small,
      Breakpoints.Medium,
      Breakpoints.Large,
      Breakpoints.XLarge
    ]).subscribe( (state: BreakpointState) => {
      //console.log(state)
      if(state.breakpoints[Breakpoints.Small] || state.breakpoints[Breakpoints.XSmall]){
           this.isPhone=true;
         //  this.sidebarMinimized=true;
      }else{
        this.isPhone=false;
      }
    });

   }

  ngOnInit(): void {
this.loadAccounts();

  }
  loadAccounts(){
    this.api.GetCustodyAccounts().subscribe((x:GOResponse<CustodyAccountVM[]>)=>{
this.list=x.data.filter(c=>c.form=='TELLER');
this.list.forEach(t=>{
  this.loadList(t.id,t.currency);
})
    })
  }
  loadList(id,currency){
    this.api.GetEndOfDay(id).subscribe((x:GOResponse<LastTransaction[]>)=>{
      if(currency=='USD'){
      this.usd_list= x.data;  
      }
      if(currency=='UGX'){
        this.ugx_list=  x.data
        this.summary = x.summary;
      }

    })
  }
  SubmitAccountability(template: TemplateRef<any>,currency){   
    this.TransferFormConfig={
      account:[''],
      amount:[0,[Validators.required]],
      comments:['', Validators.required],
      platform:['System'],
      source:['Admin Portal'],
      type:['Custody Account Inter Transfer'],
      agree:[false],
      to:['',Validators.required],
      from:['',Validators.required]
    }
   
     this.selected_currency=currency;       
    this.TransactionForm=this.fb.group(this.TransferFormConfig);
    this.formControls=this.TransactionForm?.controls as FormControls<Transaction>;
    this.TransferModalRef= this.modalService.show(template,{class:'modal-lg'});
    this.formControls.amount.valueChanges.pipe(
      debounceTime(1000),
        distinctUntilChanged()
      )
      .subscribe(res=> {
        this.isSubmitted=false
        if(res!=this.summary.closing_balance){
this.need_reason=true;
this.formControls.comments.setValidators([Validators.required])
this.formControls.comments.updateValueAndValidity();
        }else{
          this.need_reason=false;
          this.formControls.comments.setValidators([])
          this.formControls.comments.updateValueAndValidity();
        }
      });
      this.api.GetCustodyAccounts().subscribe((x:GOResponse<CustodyAccountVM[]>)=>{
      //this.custody_accounts=x.data.filter(x=>x.currency==this.acc_profile.account_profile.currency&&x.id!=this.acc_profile.account_profile.id);
      this.formControls.from.setValue(x.data.filter(a=>a.form=='TELLER'&&a.currency==currency)[0].id);
      this.formControls.to.setValue(x.data.filter(a=>a.form=='MAIN'&&a.currency==currency)[0].id);
      
               })
    //document.querySelector('nav-tabs').classList.add('nav-justified');
      }
      Submit(){
        this.secondModalOpen=true;
        this.modalRef = this.modalService.show(ConfirmationDialogComponent, {
          class:'second_modal',
          keyboard:false,
          
          initialState: {
           
            prompt:`Are you sure you want to submit this End of Day Closing Balance for approval?`,
            callback: (result) => {
             if (result.confirmation == 'yes'){
      this.api.RecordTopUp(this.TransactionForm.value).subscribe(x=>{
      
        this.TransferModalRef.hide();
        this.TransactionForm=null;
        this.secondModalOpen=false;
      },(e:GOResponse<any>)=>{this.init_message=e.error.message;this.secondModalOpen=false;})
      }
      if (result.confirmation == 'no') {
        this.secondModalOpen=false;
      }
      }
      },
      ignoreBackdropClick:true
      })
      }
      TransferCancelled(){
        this.TransactionForm= null;
    this.TransferModalRef.hide();
      }
}
