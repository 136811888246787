import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root'
})
export class PublicService extends BaseService {
  get ProductsUrl(){
    return `${this.baseUrl}/products/`
  }
  constructor(http: HttpClient,authService:AuthService) {
    super(http,authService)
      }

      GetProducts(access:string){
        const url =`${this.ProductsUrl}?access=${access}`;
        return this .http.get(url,this.requestHeaders)
      }
}
