import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { LocationStrategy, HashLocationStrategy, CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { NgIdleKeepaliveModule } from '@ng-idle/keepalive'; // this includes the core NgIdleModule but includes keepalive providers for easy wireup
import { MomentModule } from 'angular2-moment'; // optional, provides moment-style pipes for date formatting

import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

import { AppComponent } from './app.component';

// Import containers
import { DefaultLayoutComponent } from './containers';

import { P404Component } from './views/error/404.component';
import { P500Component } from './views/error/500.component';
import { LoginComponent } from './views/login/login.component';
import { RegisterComponent } from './views/register/register.component';

const APP_CONTAINERS = [
  DefaultLayoutComponent
];

import {
  AppAsideModule,
  AppBreadcrumbModule,
 // AppHeaderModule,
  AppFooterModule,
  AppSidebarModule,
 // AppHeaderComponent

} from '@coreui/angular';

// Import routing module
import { AppRoutingModule } from './app.routing';

// Import 3rd party components
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import {NgxPaginationModule} from 'ngx-pagination';
import { NguiAutoCompleteModule, NguiAutoCompleteComponent } from '@ngui/auto-complete';
import {Ng2TelInputModule} from 'ng2-tel-input';
import {NgxExtendedPdfViewerModule} from 'ngx-extended-pdf-viewer';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { AlertModule} from 'ngx-bootstrap/alert';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { ChartsModule } from 'ng2-charts';
import { HomeComponent } from './home/home.component';
import { StatusComponent } from './status/status.component';
import { TransactionsComponent } from './transactions/transactions.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { LoansComponent } from './loans/loans.component';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { ActiveLoansComponent } from './active-loans/active-loans.component';
import { LoanScheduleComponent } from './loan-schedule/loan-schedule.component';
import { LoanHistoryComponent } from './loan-history/loan-history.component';
import { NavbarBarComponent } from './navbar-bar/navbar-bar.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxCurrencyModule } from 'ngx-currency';
import { WalletComponent } from './wallet/wallet.component';
import { SupportComponent } from './support/support.component';
import { TransactionHistoryComponent } from './transaction-history/transaction-history.component';
import { LoanTrackerComponent } from './loan-tracker/loan-tracker.component';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { AllLoansComponent } from './all-loans/all-loans.component';
import { LoanApplicationComponent } from './loan-application/loan-application.component';
import { WalletTransferComponent } from './wallet-transfer/wallet-transfer.component';
import { UsersComponent } from './admin/users/users.component';
import { BorrowersComponent } from './admin/borrowers/borrowers.component';
import { LoanProductsComponent } from './admin/loan-products/loan-products.component';
import { ReportsComponent } from './admin/reports/reports.component';
import { AccountsComponent } from './admin/accounts/accounts.component';
import { ProfileComponent } from './profile/profile.component';
import { CollateralTypesComponent } from './admin/collateral-types/collateral-types.component';
import { LoanCollateralComponent } from './loan-collateral/loan-collateral.component';
import { ConfirmationDialogComponent } from './common/confirmation-dialog/confirmation-dialog.component';
import { FieldComponent } from './common/field/field.component';
import { CollateralComponent } from './forms/collateral/collateral.component';
import { CustodyAccountComponent } from './admin/custody-account/custody-account.component';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { ProductPageComponent } from './product-page/product-page.component';
import { SafeHtml } from './app.pipe';
import { ApplyComponent } from './apply/apply.component';
import { YahSvgComponent } from './yah-svg/yah-svg.component';
import { StaticLoadingComponent } from './common/static-loading/static-loading.component';
import { SuccessSvgComponent } from './common/success-svg/success-svg.component';
import { FailSvgComponent } from './common/fail-svg/fail-svg.component';
import { PayComponent } from './pay/pay.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { ApprovalsComponent } from './approvals/approvals.component';
import { PreventDoubleClickDirective } from './prevent-double-click.directive';
import { SidebarNavCustomComponent } from './sidebar-nav-custom/sidebar-nav-custom.component';
import { SearchBoxComponent } from './common/search-box/search-box.component';
import { BorrowerDetailComponent } from './borrower-detail/borrower-detail.component';
import { BorrowerFindComponent } from './borrower-find/borrower-find.component';
import { LockscreenComponent } from './lockscreen/lockscreen.component';
import { EndOfDayComponent } from './end-of-day/end-of-day.component';
import { ProfileEditComponent } from './profile-edit/profile-edit.component';
import { ReportComponent } from './admin/report/report.component';
import { PrintLayoutComponent } from './common/print-layout/print-layout.component';
import { InterestReportComponent } from './report-layout/interest-report/interest-report.component';
import { MissedPaymentsReportComponent } from './report-layout/missed-payments/missed-payments.component';
import { DebtAgingReportComponent } from './report-layout/debt-aging-report/debt-aging-report.component';
import { PaymentsDueReportComponent } from './report-layout/payments-due-report/payments-due-report.component';
import { BorrowersReportComponent } from './report-layout/borrowers-report/borrowers-report.component';
import { OtpDialogComponent } from './common/otp-dialog/otp-dialog.component';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { HelpComponent } from './help/help.component';
import { ErrorInterceptor } from './services/error.interceptor';
import { ActiveLoansReportComponent } from './report-layout/active-loans-report/active-loans-report.component';
import { TabOrderDirective } from './tab-order.directive';
import { LayoutModule } from '@angular/cdk/layout';
import { MobileProfileComponent } from './mobile-profile/mobile-profile.component';

@NgModule({
    imports: [
        CommonModule,
        NgxExtendedPdfViewerModule,
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        AppAsideModule,
        AppBreadcrumbModule.forRoot(),
        AppFooterModule,
        // AppHeaderModule,
        AppSidebarModule,
        PerfectScrollbarModule,
        ProgressbarModule.forRoot(),
        BsDropdownModule.forRoot(),
        TabsModule.forRoot(),
        ChartsModule,
        ModalModule.forRoot(),
        CollapseModule.forRoot(),
        PopoverModule.forRoot(),
        ReactiveFormsModule,
        FormsModule,
        AngularEditorModule,
        NgxCurrencyModule,
        HttpClientModule,
        NgxPaginationModule,
        NguiAutoCompleteModule,
        BsDatepickerModule.forRoot(),
        AlertModule.forRoot(),
        Ng2TelInputModule,
        NgIdleKeepaliveModule.forRoot(),
        MomentModule,
        LayoutModule
    ],
    declarations: [
        AppComponent,
        ...APP_CONTAINERS,
        P404Component,
        P500Component,
        LoginComponent,
        RegisterComponent,
        HomeComponent,
        SafeHtml,
        StatusComponent,
        TransactionsComponent,
        LoansComponent,
        TabOrderDirective,
        NavbarBarComponent, ActiveLoansComponent, LoanScheduleComponent, LoanHistoryComponent, NavbarBarComponent, WalletComponent, SupportComponent, TransactionHistoryComponent, LoanTrackerComponent, AllLoansComponent, LoanApplicationComponent, WalletTransferComponent, UsersComponent, BorrowersComponent, LoanProductsComponent, ReportsComponent, AccountsComponent, ProfileComponent, CollateralTypesComponent, LoanCollateralComponent, ConfirmationDialogComponent, FieldComponent, CollateralComponent, CustodyAccountComponent, ProductPageComponent, ApplyComponent, YahSvgComponent, StaticLoadingComponent, SuccessSvgComponent, FailSvgComponent, PayComponent, NotificationsComponent, ApprovalsComponent, PreventDoubleClickDirective, SidebarNavCustomComponent, SearchBoxComponent, BorrowerDetailComponent, BorrowerFindComponent, LockscreenComponent, EndOfDayComponent, ProfileEditComponent, ReportComponent, PrintLayoutComponent, InterestReportComponent, MissedPaymentsReportComponent, DebtAgingReportComponent, PaymentsDueReportComponent, BorrowersReportComponent, OtpDialogComponent, HelpComponent, ActiveLoansReportComponent, MobileProfileComponent
    ],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true }, {
            provide: LocationStrategy,
            useClass: HashLocationStrategy
        }
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
