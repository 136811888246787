import { Component, OnInit, ViewChild, AfterViewInit, TemplateRef, ElementRef, OnDestroy } from '@angular/core';
import { UserListVM, GOResponse, UserSummary, AccountDetail, LoanProfile, CustodyAccountVM, FormModel, Transaction, FormControls, LoanHistoryVM, LoanProduct, CollateralVM } from '../models/app.model';
import { ModalDirective, BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { AdminService } from '../services/admin.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { ConfirmationDialogComponent } from '../common/confirmation-dialog/confirmation-dialog.component';
import { UserProfileDetailVM } from '../admin/borrowers/borrowers.component';
import { AuthService } from '../services/auth.service';
import { OtpDialogComponent } from '../common/otp-dialog/otp-dialog.component';
import { ConfirmationService } from '../services/confirmation.service';
import { Narration, LoanNarration } from '../models/user.model';
import { BreakpointState, Breakpoints, BreakpointObserver } from '@angular/cdk/layout';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-borrower-detail',
  templateUrl: './borrower-detail.component.html',
  styleUrls: ['./borrower-detail.component.css'],
  providers:[DatePipe]
})
export class BorrowerDetailComponent implements OnInit,AfterViewInit,OnDestroy {
  selected: string;

BorrowerModalRef
@ViewChild('modal', { static: false }) modal: ModalDirective;
summary:UserSummary;
loan_profile:LoanProfile;
loan_profiles:{loan:LoanProfile,summary:LoanNarration}[]=[];
isCollapsed:boolean;
isLoanCollapsed:boolean[]=[]
account_viewed:AccountDetail;
  modalRef: BsModalRef;
  formControlsTransaction: FormControls<Transaction>;
  InitiateModelRef: BsModalRef;
  isSaving: boolean=false;
  user_profile: any;
 
  view_list=[
    {name:'Active Loans',icon:'icon-wallet icons'},
    {name:'MyWallet',icon:'cui-credit-card'},
    {name:'Loan History',icon:'cui-layers icons'},
    {name:'Profile',icon:'icon-user'},
    {name:'Loan Collateral', icon:'icon-drawer'}
  ];
  selected_view= this.view_list[0];
  loans: LoanHistoryVM[]=[];
  products: any[];
  user_collateral: CollateralVM[]=[];
  CollateralViewModalRef: BsModalRef;
  isPhone: boolean;

  constructor(private api:AdminService,private route:ActivatedRoute,private router:Router,private modalService:BsModalService,
    private fb:FormBuilder,private authService:AuthService, private confirmationService:ConfirmationService,
    private elem: ElementRef,private breakpointObserver:BreakpointObserver,private datePipe:DatePipe) {
  //  console.log(this.route)
  this.breakpointObserver.observe([
    Breakpoints.XSmall,
    Breakpoints.Small,
    Breakpoints.Medium,
    Breakpoints.Large,
    Breakpoints.XLarge
  ]).subscribe( (state: BreakpointState) => {
    //console.log(state)
    if(state.breakpoints[Breakpoints.Small] || state.breakpoints[Breakpoints.XSmall]){
         this.isPhone=true;
    }else{
      this.isPhone=false;
    }
  });
   }
   loadProducts(){
    this.api.GetProducts('public').subscribe((x:GOResponse<LoanProduct[]>)=>{
      this.products=x.data
    })
  }
  ngOnInit(): void {
//console.log('borrower component has opened')
    //  console.log($event)
    //  this.selected=$event;
   
    /**/this.route.params.subscribe(x=>{
      this.selected=x['id'];
    })       
  }
  ngOnDestroy(){
   if (this.modalRef){
     this.modalRef.hide();
   }
   if(this.modal.isShown){
     this.modal.hide();
   }
  }
  ngAfterViewInit(){
  //  this.modal.show();
    this.api.GetUserSummary(this.selected).subscribe((x:GOResponse<UserSummary>)=>{
      this.summary=null;
      this.account_viewed=null;
      this.loan_profile=null;
      this.summary= x.data;
      this.getUserDetail(this.summary.user_profile.id)
      if(this.summary.user_profile?.general_status=='New'){
this.loadProducts();
      }
      if(this.summary.user_profile?.general_status=='Existing'){
        this.loan_profiles=[];
        this.summary.user_profile.updated_loan_narration[0].summary.loan_balance_by_currency.UGX.forEach((l,i)=>{
        //  console.log(l)
         this.getLoan(l.reference,true,l.singular_narration,i);
         
        })
        this.summary.user_profile.updated_loan_narration[0].summary.loan_balance_by_currency.USD.forEach((l,i)=>{
          // console.log(l)
          this.getLoan(l.reference,true,l.singular_narration,this.summary.user_profile.updated_loan_narration[0].summary.loan_balance_by_currency.UGX.length+i);
          this.isLoanCollapsed[0]=true
         })
        if(this.summary.user_profile.updated_loan_narration[0].summary.count_ongoing_loans==1){
          this.isLoanCollapsed[0]=true
        }
      //  this.getLoan(this.summary.user_profile.loan_reference);       
      }
      if(this.summary.user_profile.loan_narration.loan_currency){
        this.account_viewed=x.data.accounts.filter(a=>a.account_type==this.summary.user_profile.loan_narration.loan_currency)[0] 
      }else{
       this.account_viewed=x.data.accounts[0] 
      }
      
    },r=>{},()=>{
      if(this.summary.user_profile.updated_loan_narration[0].summary.count_ongoing_loans>1){
      setTimeout(() => {
       
        console.log(document.getElementsByClassName('nav-pills'))
        let d = document.getElementsByClassName('nav-pills')
        if(d){
         document.getElementsByClassName('nav-pills')[0].classList.add('justify-content-center') 
        }
    
        })
      }
      
    //  document.querySelector('nav-pills').classList.add('justify-content-center')
     // document.getElementsByClassName('nav-pills')[0].classList.add('justify-content-center')
      });
    
    console.log("call back executed");
  
    /* for (var i = 0; i < document.getElementsByClassName('nav-pills').length; i++) {
      document.getElementsByClassName('nav-pills')[i]?.classList.add('justify-content-center');
    } */
   // document.getElementsByClassName('nav-pills')?.classList.add('justify-content-center')
  }
  getLoan(id,form?,narration?,i?){
   this.api.GetLoanDetails(id).subscribe((x:GOResponse<LoanProfile>)=>{ 
     if(form){
       
       this.loan_profiles[i]= {loan:x.data,summary:narration}
//this.loan_profiles.push({loan:x.data,summary:narration})
     }  else{this.loan_profile=x.data}
     console.log(i)
     console.log(this.loan_profiles)
   })
 }
 getUserDetail(id){
   this.api.GetUser(id).subscribe((x:GOResponse<UserProfileDetailVM>)=>{
     this.user_profile=x.data.profile
     this.user_collateral=x.data.collateral;
   })

 }
 selectAcount(acc:AccountDetail){
  this.account_viewed=acc;
}
get getNotselected():AccountDetail[]{
return this.summary.accounts.filter(x=>x.id!=this.account_viewed.id)
}
CloseModal(){
  //this.BorrowerModalRef.hide();
  this.modal.hide();
 
    //this.router.navigate(['/all-loans',{outlets: {modal: null}}]);
}
FlagBadDebt(id){
  this.modalRef = this.modalService.show(ConfirmationDialogComponent, {
    initialState: {
      prompt: `Are you sure you want to flag this loan as <b>Bad Debt</b>?`,
      callback: (result) => {
        if (result.confirmation == 'yes'){
          let obj:any = {
            "id": id,
            "loan_status": "Bad Debt",
            "reason_narration": result?.reason,
            "reason_category":"can't pay"
        }     
        this.api.SubmitLoanForApproval(obj).subscribe(x=>{
          this.ngAfterViewInit();
        });
        }
      },
      has_reason:true
    },
    backdrop:'static',
    keyboard:false,
    
  })
}
FreezeLoan(id){
  this.modalRef = this.modalService.show(ConfirmationDialogComponent, {
    initialState: {
      prompt: `Are you sure you want to freeze this loan?`,
      callback: (result) => {
        if (result.confirmation == 'yes'){
          let obj:any = {
            "id": id,
            "loan_status": "Frozen",
            "reason_narration": result?.reason,
            "reason_category":'Cannot Pay'
        }     
        this.api.SubmitLoanForApproval(obj).subscribe(x=>{
          this.ngAfterViewInit();
        });
        }
      },      
    has_reason:true
    },
    backdrop:'static',
    keyboard:false
  })
}
////Withdraw Initiation
selectedUserSummary:UserSummary;
accounts:AccountDetail[]=[];
custody_accounts:CustodyAccountVM[]=[];
TransactionForm:FormGroup;
TransactionFormConfig:FormModel<Transaction>;
init_message:string='';
isInitiationSubmitted=false;
secondModalOpen:boolean=false;
InitiateCashWithdraw(username, template){
 this.custody_accounts=[];
 this.TransactionFormConfig={
   account:['',Validators.required],
   amount:[0,[Validators.required,Validators.min(1)]],
   comments:[''],
   platform:['Cash'],
   source:['Admin Portal'],
   type:['Withdraw'],
   custody_account:[''],
   agree:[true],
   otp:['']
 
 }
// this.accounts= this.selectedUserSummary.accounts
/*  this.api.GetAccountsofUser(username).subscribe((x:GOResponse<AccountDetail[]>)=>{
this.accounts=x.data
 }) */
  this.api.GetCustodyAccounts().subscribe((x:GOResponse<CustodyAccountVM[]>)=>{
   this.custody_accounts=x.data;
         })
 this.TransactionForm=this.fb.group(this.TransactionFormConfig);
 this.formControlsTransaction=this.TransactionForm?.controls as FormControls<Transaction>;
 this.InitiateModelRef= this.modalService.show(template,{backdrop:'static',keyboard:false,class:'second_modal'})
}

InitiateCashDeposit(username, template){
 this.custody_accounts=[];
 this.TransactionFormConfig={
   account:[''],
   amount:[0,[Validators.required,Validators.min(1)]],
   comments:[''],
   platform:['Cash'],
   source:['Admin Portal'],
   type:['Deposit'],
   custody_account:[''],
   agree:[true],
   transaction_reason:[''],
   loan_application:[''],
   effective_payment_date:['']
 }
 /* this.api.GetAccountsofUser(username).subscribe((x:GOResponse<AccountDetail[]>)=>{
this.accounts=x.data

 }) */
 this.api.GetCustodyAccounts().subscribe((x:GOResponse<CustodyAccountVM[]>)=>{
   this.custody_accounts=x.data;  
         })
 this.TransactionForm=this.fb.group(this.TransactionFormConfig);
 this.formControlsTransaction=this.TransactionForm?.controls as FormControls<Transaction>;
 
 
 this.updateAccountValidation();
 this.InitiateModelRef= this.modalService.show(template,{backdrop:'static',keyboard:false,class:'second_modal'})
}
SubmitInitiation(){
  if(this.formControlsTransaction.type.value=='Withdraw'){

    this.api.SendTellerWithdrawOTP(this.summary.user_profile.id).subscribe(x=>{
      this.WithDrawOtp();
    })
  }else{

  
  this.secondModalOpen=true;
 this.modalRef = this.modalService.show(ConfirmationDialogComponent, {
   class:'second_modal',
   keyboard:false,
   
   initialState: {
    
     prompt: this.formControlsTransaction.type.value=='Deposit'?`This transaction will credit the Teller Cash Account.<br> Are you sure you want to submit this Cash ${this.formControlsTransaction.type.value} for approval?`:`This transaction will debit the borrower's wallet.<br> Are you sure you want to continue?`,
     callback: (result) => {
      if (result.confirmation == 'yes'){
 this.isInitiationSubmitted=false;
 if(this.formControlsTransaction.transaction_reason?.value=='Loan Repayment'){
   if(this.loan_profiles.length==1){
     this.formControlsTransaction.account.setValue(this.summary.accounts.filter(x=>x.account_type==this.loan_profiles[0].loan.profile.loan_account)[0].id)
   }
   let account_currency=this.summary.accounts.filter(a=>a.id==this.formControlsTransaction.account.value)[0].account_type
   console.log("selected currency is ",account_currency);
 this.formControlsTransaction.custody_account.setValue(this.custody_accounts.filter(c=>c.currency==account_currency&&c.form=='TELLER')[0]?.id);
}else{
  let account_currency=this.summary.accounts.filter(a=>a.id==this.formControlsTransaction.account.value)[0].account_type
  console.log("selected currency is ",account_currency);
this.formControlsTransaction.custody_account.setValue(this.custody_accounts.filter(c=>c.currency==account_currency&&c.form=='TELLER')[0]?.id);

  }
/**/
this.api.TellerInitiatingCashWithdraw(this.TransactionForm.value).subscribe(x=>{
  this.secondModalOpen=false;
this.CancelWidthrawModal();
this.ngAfterViewInit();
},(e:GOResponse<any>)=>{this.isSaving=false;this.init_message=e.error.message;this.secondModalOpen=false;})
      }
      if (result.confirmation == 'no') {
        this.secondModalOpen=false;
      }
     }
   },
   ignoreBackdropClick:true
 })
}
}
setCurrencyAndCustodyAccount($event){
let lp=  this.loan_profiles.filter(x=>x.loan.profile.id==$event)[0]
this.formControlsTransaction.custody_account.setValue(this.custody_accounts.filter(c=>c.currency==lp.loan.profile.loan_account&&c.form=='TELLER')[0]?.id);
this.formControlsTransaction.account.setValue(this.summary.accounts.filter(x=>x.account_type==lp.loan.profile.loan_account)[0].id)
}
WithDrawOtp(){
  this.secondModalOpen=true;
  this.modalRef = this.modalService.show(OtpDialogComponent, {
    class:'second_modal',
    keyboard:false,    
    initialState: {
     
      prompt: this.formControlsTransaction.type.value=='Deposit'?`This transaction will credit the Teller Cash Account.<br> Are you sure you want to submit this Cash ${this.formControlsTransaction.type.value} for approval?`:`This transaction will debit the borrower's wallet.<br> Please enter 5-digit One-Time Password(OTP) sent to you on SMS and email`,
      callback: (result) => {
       if (result.confirmation == 'yes'){
        this.formControlsTransaction.otp.setValue(result.reason);
  this.isInitiationSubmitted=false;
  if(this.formControlsTransaction.transaction_reason?.value=='Loan Repayment'){
   this.formControlsTransaction.account.setValue(this.summary.accounts.filter(x=>x.account_type==this.loan_profile.profile.loan_account)[0].id)
 }
 let account_currency=this.summary.accounts.filter(a=>a.id==this.formControlsTransaction.account.value)[0].account_type
 this.formControlsTransaction.custody_account.setValue(this.custody_accounts.filter(c=>c.currency==account_currency&&c.form=='TELLER')[0]?.id);
 this.confirmationService.SetCurrentStatus({item:'loading',status:null})
 this.api.TellerInitiatingCashWithdraw(this.TransactionForm.value).subscribe(x=>{
  this.confirmationService.SetCurrentStatus({item:'OK',status:true,message:x?.message})
   this.secondModalOpen=false;
 this.CancelWidthrawModal();
 this.ngAfterViewInit();
 },(e:GOResponse<any>)=>{
   this.isSaving=false;
  /*  this.init_message=e.error.message; */
  this.confirmationService.SetCurrentStatus({item:'ERROR',status:false,message:e.error.message})
   this.secondModalOpen=false;})
       }
       if (result.confirmation == 'no') {
         this.secondModalOpen=false;
       }
       if (result.confirmation == 'resend'){
        this.api.SendTellerWithdrawOTP(this.summary.user_profile.id).subscribe(x=>{});
       }
      }
    },
    ignoreBackdropClick:true
  })
}

CancelWidthrawModal(){
 this.isInitiationSubmitted=false;
 this.TransactionForm=this.fb.group(this.TransactionFormConfig);
 this.InitiateModelRef.hide();
}
get SelectedBalance(){
  if(this.formControlsTransaction.account.value&&this.formControlsTransaction.type.value=='Withdraw'){
  let acc=  this.summary.accounts.filter(x=>x.id==this.formControlsTransaction.account.value)[0]
  return acc.actual_balance
  }else{
    return null
  }  
}
getSelectedLoanBallance(){
  if(this.formControlsTransaction.loan_application.value&&this.formControlsTransaction.type.value=='Deposit'){
    let loan = this.loan_profiles.filter(x=>x.loan.profile.id==this.formControlsTransaction.loan_application.value)[0];
    return loan.summary.loan_balance
  }else{
    return null;
  }
}
updateAccountValidation(){
  this.formControlsTransaction.transaction_reason.valueChanges.subscribe(r=>{
    if(r=='Wallet Credit'){
      this.formControlsTransaction.account.setValidators([Validators.required])
    }
    if(r=='Loan Repayment'){
      this.formControlsTransaction.account.setValidators([])
      if(this.loan_profiles.length>1){
        this.formControlsTransaction.loan_application.setValidators([Validators.required])
         }else{
          this.formControlsTransaction.loan_application.setValidators([])
          if(this.loan_profiles.length==1){
            this.formControlsTransaction.loan_application.setValue(this.loan_profiles[0].loan.profile.id)
          }
          
         }
    }
    this.formControlsTransaction.account.updateValueAndValidity();
  })
}
view_transaction:boolean=false;
ResetTransctionView(){
this.view_transaction=false;;
}
loadHistory(){
  this.api.GetLoanHistory(true,null,this.summary.user_profile.id).subscribe((x:GOResponse<LoanHistoryVM[]>)=>{
    this.loans=x.data
      })
}
selectView(v){
  if(v.name=='Loan History'){
    this.loadHistory();
  }
  this.selected_view=v;
}
selected_loan_id:string;
ViewLoanDetails(id){
  
 
  this.selected_loan_id=null;
   this.selected_loan_id=id;
 this.modal.show();
 
 
}
collateralView:CollateralVM;
View(c:CollateralVM,template: TemplateRef<any>){
  this.collateralView=c;
  this.CollateralViewModalRef= this.modalService.show(template,{backdrop:'static',class:"modal-lg"+this.isPhone?'':" w-50"});
}
CloseCollateralViewModel(){
  this.CollateralViewModalRef.hide();
}
get canApply(){
return this.authService.currentUser.roles!='Teller'
}
get canDepositWithdraw(){
  return this.authService.currentUser.roles=='Teller'|| this.authService.currentUser.roles=='Reviewer'||this.authService.currentUser.roles=='Approver'
}
get canFlag(){
  return this.authService.currentUser.roles=='Admin'||this.authService.currentUser.roles=='Super User'
}
summaryCallbackFunction = (args?: any): void => {}
getFieldName(name){
  return  this.collateralView.collateral_type.collateral_captured_details.filter(x=>x.title.toLocaleLowerCase().replace(/ /g,"_")==name)[0]?.title
  }
  ReverseTransaction(transaction){
    console.log(transaction)
    this.modalRef = this.modalService.show(ConfirmationDialogComponent, {
      initialState: {
        prompt: `Are you sure you want to reverse the <b>deposit</b> amount ${this.account_viewed.account_type} ${Number(transaction?.credit).toLocaleString()} recorded on ${this.datePipe.transform(transaction.initiation_time,'medium')}?`,
        callback: (result) => {
          if (result.confirmation == 'yes'){
            
            let obj:any = {
              "transaction_id": transaction.id,
              "reason": result?.reason,
              //"custody_account":this.custody_accounts.filter(c=>c.currency==this.account_viewed.account_type&&c.form=='TELLER')[0]?.id
          }     
          console.log(this.custody_accounts)
          console.log(obj);
          /*  */this.api.InitiatingTransactionReversal(obj).subscribe((x:GOResponse<any>)=>{
            this.ngAfterViewInit();
            this.SaveError(x.message);
          },(err:GOResponse<any>)=>{ this.SaveError(err.error.message)});
          }
        },
        has_reason:true
      },
      backdrop:'static',
      keyboard:false,
      
    })
  }
  SaveError(error:string){
    this.modalRef = this.modalService.show(ConfirmationDialogComponent, {
      initialState: {
        prompt: error,
        has_reason: false,
        is_alert:true,
        callback: (result) => {
          if (result.confirmation == 'yes') {
           
           
          }
          if (result.confirmation == 'no') {
          
          }
        }
      },
      backdrop: 'static',
      keyboard: false
    })
  }
   }


function instanceOfUser(data: any): data is UserListVM {
  return 'id' in data;
}


