import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { PrintTemplate } from '../models/app.model';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PrintReportService {
  isPrinting = false;
  printTemplate:PrintTemplate;
  fileTypes=new Subject<string>();
  fileType:string;

  constructor(private router: Router) { }

  printDocument(printData:PrintTemplate,report_type:string,filetype?:string) {
    this.isPrinting = true;
    this.printTemplate= printData;
    this.fileType=filetype;
    
    if(filetype){
      
      this.router.navigate(['/',
      { outlets: {
        'print': ['print','interest','pdf']
      }}]);
    }else{
      this.router.navigate(['/',
      { outlets: {
        'print': ['print',report_type]
      }}]);
    }
   
  }

  onDataReady() {
      
    setTimeout(() => {
    if(!this.fileType){
         window.print();
 }
         this.isPrinting = false;
      this.router.navigate([{ outlets: { print: null }}]);
     
     
      
    });
  }
}
