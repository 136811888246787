import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FieldModel } from '../../models/custom-form.base.model';

@Component({
  selector: 'app-field',
  templateUrl: './field.component.html',
  styleUrls: ['./field.component.css']
})
export class FieldComponent implements OnInit {
  @Input() field: FieldModel;
  @Input() form: FormGroup;
  get isValid() { 
    return !this.form.controls[this.field.id]?.touched || this.form.controls[this.field.id]?.valid; 
  }
  constructor() { }

  ngOnInit() {
  }

}
