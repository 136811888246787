import { Component, OnInit } from '@angular/core';
import { AdminService } from '../services/admin.service';
import { GOResponse, UserListVM } from '../models/app.model';
import { Router } from '@angular/router';
import { Utilities } from '../services/utilities.service';
import { fromEvent, Subject, Subscription } from 'rxjs';
import { debounceTime, map, distinctUntilChanged } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';
import { Breakpoints, BreakpointState, BreakpointObserver } from '@angular/cdk/layout';

@Component({
  selector: 'app-borrower-find',
  templateUrl: './borrower-find.component.html',
  styleUrls: ['./borrower-find.component.css']
})
export class BorrowerFindComponent implements OnInit {
  borrowers: any[]=[];
  borrowersCache: any[]=[];

  private modelChanged: Subject<string> = new Subject<string>();
  private subscription: Subscription;
  debounceTime = 1500;
  currentUserId: string;
  isPhone: boolean;


  constructor(private api:AdminService,private router:Router,private authService:AuthService,private breakpointObserver:BreakpointObserver) {
    this.currentUserId= this.authService.currentUser.id;
    this.breakpointObserver.observe([
      Breakpoints.XSmall,
      Breakpoints.Small,
      Breakpoints.Medium,
      Breakpoints.Large,
      Breakpoints.XLarge
    ]).subscribe( (state: BreakpointState) => {
      //console.log(state)
      if(state.breakpoints[Breakpoints.Small] || state.breakpoints[Breakpoints.XSmall]){
           this.isPhone=true;
      }else{
        this.isPhone=false;
      }
    })
   }

  ngOnInit(): void {
    this.loadBorrowers();
  /*  this.subscription = this.modelChanged
    .pipe(
      debounceTime(this.debounceTime)//,
    )
    .subscribe((x) => {
     // this.functionToBeCalled();
     if(x){
        this.api.SearchingForUsersByName(x).subscribe((x:GOResponse<UserListVM[]>) => {
      this.borrowers=x.data;
      this.borrowersCache=x.data;
     });
     }
    
    });*/
  }
  loadBorrowers(){
    this.api.GetUsers().subscribe((x:GOResponse<UserListVM[]>)=>{             
      this.borrowers=x.data;
      this.borrowersCache=x.data;
    })
    }
    gotoBorrower(){
      this.router.navigate([]);
    }
    onValueChange($event:any){
     // this.modelChanged.next($event);
//console.log($event.value)
        this.borrowers = this.borrowersCache.filter(r => Utilities.searchArray($event, false, r.first_name, r.last_name,r.username,r.email,r.primary_phone_number));
     /*  this.api.SearchingForUsersByName($event)
    //   .pipe(map((event: Event) => (event.target as HTMLInputElement).value))
       .pipe(debounceTime(3000))
       .pipe(distinctUntilChanged())
       .subscribe((x:GOResponse<UserListVM[]>) => {
        this.borrowers=x.data;
        this.borrowersCache=x.data;
       });*/
       }
       ngOnDestroy(): void {
       // this.subscription.unsubscribe();
      }
}
