import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';

@Component({
  selector: 'app-search-box',
  templateUrl: './search-box.component.html',
  styleUrls: ['./search-box.component.css']
})
export class SearchBoxComponent {
  @Input()
  placeholder: string = "Search...";

  @Input('isinvalid') isinvalid?:boolean=false;
  @Output()
  searchChange = new EventEmitter<string>();

  @ViewChild("searchInput")
  searchInput: ElementRef;


  onValueChange(value: string) {
      setTimeout(() => this.searchChange.emit(value));
  }


  clear() {
      this.searchInput.nativeElement.value = '';
      this.onValueChange(this.searchInput.nativeElement.value);
  }
}
