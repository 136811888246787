import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MenuService {
  //stream controlling change of menu
  private menu = new BehaviorSubject<any>(0);

  constructor() { }

  setMenu(value){
   this.menu.next({menulink:value})
  }

  resetMenu(){
    this.menu.next(0)
   }

  getMenu(): Observable<any> {
    return this.menu.asObservable();
  }
}
