import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.css']
})
export class ConfirmationDialogComponent implements OnInit {
  prompt
  reason:any;
  reason_required:boolean=false;
  has_reason:boolean=false;
  is_alert:boolean=false;
  isSubmitted:boolean=false;
  
  constructor(public bsModalRef: BsModalRef) { 
 //  this.prompt=this.bsModalRef.content.prompt
 //this.bsModalRef
  }
 
  ngOnInit() {
    
  }
 
  confirm() {
    this.isSubmitted=false;
    if(this.reason_required&&!this.reason){
      this.isSubmitted=true;
    }else{
      if (this.bsModalRef.content.callback != null){
      this.bsModalRef.content.callback({confirmation:'yes',reason:this.reason});
      //this.bsModalRef.content.reason=this.reason
      this.bsModalRef.hide();
    } 
    }
   
  }
 
  decline() {
    this.isSubmitted=false;
    if (this.bsModalRef.content.callback != null){
      this.bsModalRef.content.callback({confirmation:'no',reason:''});
      this.bsModalRef.hide();
    }
}
close(){
  if (this.bsModalRef.content.callback != null){
  this.bsModalRef.hide();
  }
}

}
