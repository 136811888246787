import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { AccountDetail, FormControls, FormModel, GOResponse, Transaction } from '../models/app.model';
import { AdminService } from '../services/admin.service';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'app-wallet-transfer',
  templateUrl: './wallet-transfer.component.html',
  styleUrls: ['./wallet-transfer.component.css']
})
export class WalletTransferComponent implements OnInit {
transaction:any;
to:any;
TransactionForm:FormGroup;
UserFormConfig:FormModel<Transaction>;

accounts: AccountDetail[]=[];
  formControls: FormControls<Transaction>;
  message: { validity: boolean; info: string; };
  isLoading:boolean
  constructor(private activeroute:ActivatedRoute,private api:AdminService,private authService:AuthService, private fb:FormBuilder) {
    this.activeroute.params.subscribe(x=>{
     this.transaction=x['transaction'];
     this.to=x['to']
    })
   }

  ngOnInit(): void {
  this.loadAccounts();
  if(this.to=='momo'&&this.transaction=='withdraw'){
    this.InitiateMyWalletToMomo();
  }
  }
  loadAccounts(){
    this.api.GetAccounts(this.authService.currentUser.username).subscribe((x:GOResponse<AccountDetail[]>)=>{
      this.accounts=x.data
    })
  }
  InitiateMyWalletToMomo(){
    this.UserFormConfig={
      account:['',Validators.required],
      amount:[0,[Validators.required,Validators.min(1)]],
      comments:[''],
      platform:['Mobile Money'],
      source:['Admin Portal'],
      type:['Withdraw'],
      agree:[null,Validators.requiredTrue]
    }
    
    this.TransactionForm=this.fb.group(this.UserFormConfig);
    this.formControls=this.TransactionForm?.controls as FormControls<Transaction>;
  }
  Send(){
    this.isLoading=true;
    if(this.to=='momo'&&this.transaction=='withdraw'){
      this.api.UserInitiatingMomoWithdraw(this.TransactionForm.value).subscribe(x=>{
        this.message={
          validity:true,
          info:'Transaction was successfull'
        }
        this.isLoading=false;
      })
    }
  }
getBallance(id){
  if(this.accounts.length>0&&id){
    let a=this.accounts.filter(x=>x.id==id)[0];
    return `${a.account_type} ${a.actual_balance}`
  }else{
    return null
  }
}
}
