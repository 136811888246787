import { Component, OnInit, TemplateRef, ViewChild, NgZone,OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BsModalRef, BsModalService, ModalDirective } from 'ngx-bootstrap/modal';
import { ConfirmationDialogComponent } from '../../common/confirmation-dialog/confirmation-dialog.component';
import { FormControls, FormModel, GOResponse, UserListVM, UserProfile } from '../../models/app.model';
import { AdminService } from '../../services/admin.service';
import { UserProfileDetailVM } from '../borrowers/borrowers.component';
import { PaginationInstance } from 'ngx-pagination';
import { Utilities } from '../../services/utilities.service';
import { Ng2TelInput } from 'ng2-tel-input';
import { AlertComponent } from 'ngx-bootstrap/alert/ngx-bootstrap-alert';
import { BsDropdownModule, BsDropdownDirective } from 'ngx-bootstrap/dropdown';
import { AuthService } from '../../services/auth.service';
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css'],
  providers:[BsDropdownDirective]
})
export class UsersComponent implements OnInit,OnDestroy {
  @ViewChild('modal',{static:true}) modal:ModalDirective;
  UserFormConfig:FormModel<UserProfile>={
    id:[''],
    first_name:['',Validators.required],
    last_name:['',Validators.required],
    other_names:[''],
    day:['',Validators.required],
month:['',Validators.required],
year:['',Validators.required],
    date_of_birth:[''],
    gender:['',Validators.required],
    nationality:['',Validators.required],
    nin:[''],
    passport_number:[''],
    marital_status:['',Validators.required],
    current_home_address:['',Validators.required],
    previous_home_address:[''],
    primary_phone_number:['',[Validators.required,Validators.maxLength(12),Validators.minLength(12)]],
    primary_phone_number_input:[''],
    secondary_phone_number:['',[Validators.maxLength(12),Validators.minLength(12)]],
    secondary_phone_number_input:[''],
    email:['',[Validators.required,Validators.email]],
    nok_name:['',Validators.required],
    nok_email:['',Validators.required],
    nok_phone_number:['',Validators.required],
    nok_phone_number_input:[''],
    nok_relationship:['',Validators.required],
    roles:[''],
    passport_photo:['',Validators.required],
    default_currency:['UGX'],
    is_active:[true],
    send_sms:[false],
    send_email:[false],
    can_get_instant_loan:[false],
    maximum_instant_loan_amount:[500000],
    can_apply_for_loan:[true]
  }
  UserForm:FormGroup;

  isSubmitted:boolean=false;

  isSubmittedOther:boolean=false;

  isSubmittedNok:boolean=false;

  isSubmittedSettings:boolean=false;

  UserModalRef:BsModalRef;

  formControls:FormControls<UserProfile>;

  currentTab:number=0;

  days=Array.from(Array(31).keys()).filter(d=>d!=0);

  users_list:UserListVM[]=[];

  isSaving:boolean=false;

  message:string;

  roles:any[]=[
    { name: 'Admin'},{name:'Approver'},{name:'Reviewer'},{name:	'Teller' },{name:	'Borrower' }]


  months=['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
start=((new Date().getFullYear()-18));
end=(new Date().getFullYear()-80);
  years= [...Array(Math.floor((this.start - this.end)) + 1)].map((_, i) => this.end + i * 1).reverse();
  modalRef: BsModalRef;
  public configSub: PaginationInstance = {
    id: 'users',
    itemsPerPage: 10,
    currentPage: 1
  };
  filter='Borrowers';
  rowsCache: UserListVM[]=[];

  telOptions = {initialCountry: 'ug', preferredCountries: ['ug','ke', 'tz','rw'],separateDialCode:true,autoHideDialCode: false};
  viewed_user: UserListVM;
  isPhone: boolean;

  constructor(private fb:FormBuilder,private modalService:BsModalService,private api:AdminService,private zone:NgZone,
    private authService:AuthService,private ngZone:NgZone,private breakpointObserver:BreakpointObserver) {
      //  console.log(this.route)
      this.breakpointObserver.observe([
        Breakpoints.XSmall,
        Breakpoints.Small,
        Breakpoints.Medium,
        Breakpoints.Large,
        Breakpoints.XLarge
      ]).subscribe( (state: BreakpointState) => {
        //console.log(state)
        if(state.breakpoints[Breakpoints.Small] || state.breakpoints[Breakpoints.XSmall]){
             this.isPhone=true;
        }else{
          this.isPhone=false;
        }
      });

     }

  ngOnInit(): void {
    this.loadBorrowers('borrower');
  }
  onCountryChange($event){
    this.formControls.primary_phone_number.setValue($event.dialCode)
  }
  get isImage(){
  return typeof(this.formControls?.passport_photo.value)!='string';
  }
  onSecCountryChange($event){
    this.formControls.secondary_phone_number.setValue($event.dialCode)
  }
  onNokNumberChange($event){
    this.formControls.nok_phone_number.setValue($event.dialCode);
  }
//isCollapsedTable=[];
  isCollapsed: boolean[] = [];

  collapsed(event: any): void {
    // console.log(event);
  }

  expanded(event: any): void {
    // console.log(event);
  }
CreateUser(template:TemplateRef<any>){
  this.isCollapsed[this.currentTab]=true;
  
this.UserForm= this.fb.group(this.UserFormConfig);
this.formControls=this.UserForm?.controls as FormControls<UserProfile>;
this.onInstantLoanChange();
if(this.formControls.primary_phone_number.disabled){
  this.formControls.primary_phone_number.enable()
}
this.formControls.secondary_phone_number_input.enable();
this.formControls.nok_phone_number_input.enable();
if(this.authService.currentUser.roles=='Reviewer'){
  this.formControls.roles.setValue('Borrower')
}
//this.formControls.primary_phone_number.setValue('256')
//this.formControls.secondary_phone_number.setValue('256')
this.ngZone.run(() => {
this.UserModalRef= this.modalService.show(template,{backdrop:'static',class:'modal-lg'})
});
}
Cancel(){
  this.UserForm=null;
  this.isSubmitted=false;
  this.UserModalRef.hide();
  this.UserModalRef=null;
  //this.UserForm= this.fb.group(this.UserFormConfig);
  this.ToggleForm(0)
}
Save(){
  this.formControls.secondary_phone_number_input.disable();
  this.formControls.nok_phone_number_input.disable();
  this.formControls.day.disable();
  this.formControls.month.disable();
  this.formControls.year.disable();
  if(this.formControls.id.value!=''){

    if(typeof this.formControls.passport_photo.value==='string'){
      this.formControls.passport_photo.setValue("null");
    }
    this.api.UpdatingUser(this.UserForm.value).subscribe(x=>{}, (e:GOResponse<any>)=>{this.isSaving=false;this.message=e.error.message;},
    ()=>{this.loadBorrowers('borrower');this.filter='Borrowers';this.Cancel();this.successAlert()})
  }else{
// this.formControls.date_of_birth.setValue(`${this.formControls.year.value}-${this.formControls.month.value}-${this.formControls.day.value}`)
this.api.PostBorrower(this.UserForm.value).subscribe(x=>{}, (e:GOResponse<any>)=>{this.isSaving=false;this.message=e.error.message;},
()=>{this.loadBorrowers('borrower');this.filter='Borrowers';this.Cancel()})
  } 
}
loadBorrowers(staff?){
  if(staff){
    this.api.GetUsers(staff).subscribe((x:GOResponse<UserListVM[]>)=>{


      this.users_list=x.data;
      this.rowsCache=x.data;
      this.configSub.currentPage=1;
    })
  }else{
    this.api.GetUsers().subscribe((x:GOResponse<UserListVM[]>)=>{


      this.users_list=x.data;
      this.rowsCache=x.data;
      this.configSub.currentPage=1;
    })
  }

}
onSearchChanged(value: string) {
  //this.resetKeys();
  this.users_list = this.rowsCache.filter(r => Utilities.searchArray(value, false, r.first_name, r.last_name));
}
Next(){
  this.formControls.date_of_birth.setValue(`${this.formControls.year.value}-${this.formControls.month.value}-${this.formControls.day.value}`)
this.isCollapsed[this.currentTab]=false;
this.isCollapsed[this.currentTab+1]=true;
this.currentTab=this.currentTab+1;
if(this.formControls?.id?.value){
  document.querySelector(".user-form-cont").scrollTo({top:0,behavior:'smooth'});
}else{
 window.scrollTo({top:1000,behavior:'smooth'});
 console.log("scroll",document.querySelector(".modal"))
 document.querySelector(".user-form-cont").scrollTo({top:0,behavior:'smooth'});
 document.querySelector("body").scrollTo({top:0,behavior:'smooth'});
}

}
Back(){
  this.isCollapsed[this.currentTab]=false;
  this.isCollapsed[this.currentTab-1]=true;
  this.currentTab=this.currentTab-1;
  }
 ToggleForm(level:number){
   let a= Array.from(Array(5).keys())
   a.forEach(el => {
     if(el==level){
       this.isCollapsed[level]=!this.isCollapsed[level];
       this.currentTab=level;
     }else{
       this.isCollapsed[el]=false
     }
   });
 }
 onFileChange(event) {
  console.log(event)
 if (event.target.files.length > 0) {
   const file = event.target.files[0];
   
   this.UserForm.patchValue({
     passport_photo: file
   });
  
 }
}
ResetPin(user:UserListVM){
  this.modalRef = this.modalService.show(ConfirmationDialogComponent, {
    initialState: {
      prompt: `Are you sure you want to reset the PIN for <b>${user.first_name} ${user.last_name}</b>?`,
      callback: (result) => {
        if (result.confirmation == 'yes'){

this.api.ResetPin(user.username).subscribe(x=>{})
        }
      }
    },
    backdrop:'static',
    keyboard:false
  })
}
EditUser(u:UserListVM,template?:TemplateRef<any>){ 
  
 this.alerts=[];

  this.isCollapsed[this.currentTab]=true;
 this.UserForm= this.fb.group(this.UserFormConfig);
 this.formControls=this.UserForm?.controls as FormControls<UserProfile>;
 this.onInstantLoanChange();
 if(this.authService.currentUser.roles!='Admin'){
   this.formControls.primary_phone_number_input.disable();
 }
 
  this.api.GetUser(u.id).subscribe((x:GOResponse<UserProfileDetailVM>)=>{
let d_o_b= new Date(x.data.profile.date_of_birth);
this.formControls.day.setValue(`${d_o_b.getDate()}`);
this.formControls.month.setValue(d_o_b.getMonth()+1);
this.formControls.year.setValue(`${d_o_b.getFullYear()}`);
    this.UserForm.patchValue(x.data.profile);

    this.formControls.primary_phone_number_input.setValue(x.data.profile.primary_phone_number);
    if(x.data.profile.secondary_phone_number){
this.formControls.secondary_phone_number_input.setValue(x.data.profile.secondary_phone_number)

    }
    this.formControls.nok_phone_number_input.setValue(x.data.profile.nok_phone_number);
    
       // console.log(this.UserForm.value)
       if(template){
         this.ngZone.run(() => {
    this.UserModalRef= this.modalService.show(template,{backdrop:'static',class:'modal-dialog modal-lg modal-dialog-scrollable'})
       })
       }
       
  })  
}
ViewUser(u:UserListVM){
  this.viewed_user=u;
  this.isCollapsed[this.currentTab]=true;
 this.UserForm= this.fb.group(this.UserFormConfig);
 this.formControls=this.UserForm?.controls as FormControls<UserProfile>; 
 this.onInstantLoanChange();
  this.api.GetUser(u.id).subscribe((x:GOResponse<UserProfileDetailVM>)=>{
let d_o_b= new Date(x.data.profile.date_of_birth);
this.formControls.day.setValue(`${d_o_b.getDate()}`);
this.formControls.month.setValue(d_o_b.getMonth()+1);
this.formControls.year.setValue(`${d_o_b.getFullYear()}`);
    this.UserForm.patchValue(x.data.profile);
    this.modal.show();
    
  })
}
 ClientValidity(){
  return this.formControls.first_name.valid&&this.formControls.last_name.valid&&this.formControls.day.valid&&this.formControls.month.valid&&this.formControls.year.valid&&this.formControls.gender.valid&&this.formControls.nationality.valid&&this.formControls.marital_status.valid&&this.formControls.passport_photo.valid
 }

 OtherValidity(){
return this.formControls.nin.valid&&this.formControls.email.valid&& this.formControls.primary_phone_number.valid&&this.formControls.current_home_address.valid
 }

 NokValidity(){
   return this.formControls.nok_email.valid&&this.formControls.nok_name.valid&&this.formControls.nok_phone_number.valid&&this.formControls.nok_relationship.valid
 }
 alerts:any[]=[];
 successAlert(): void {
  this.alerts.push({
    type: 'success',
    msg: `The user details have successfully been updated`
  });
}
onClosed(dismissedAlert: AlertComponent): void {
  this.alerts = this.alerts.filter(alert => alert !== dismissedAlert);
}
objIntelObj:any;
telInputObject(obj) {
  //console.log(obj.getValue());

this.objIntelObj=obj;
 // obj.setCountry('ug');
  
}
getNumber($event){
  console.log($event);
  
  this.formControls.primary_phone_number.setValue($event.replace("+",""))
}
getNum(){
  this.objIntelObj.getNumber();
}
telInputObjectSec(obj){
  console.log(obj.getNumber());
  //this.formControls.secondary_phone_number.setValue(obj.getNumber())
}
getNumberSec($event:string){
 console.log($event);
 
  this.formControls.secondary_phone_number.setValue($event.replace("+",""))
}
getNumberNok($event:string){
  this.formControls.nok_phone_number.setValue($event.replace("+",""))
}
isInteger(event,id:string,field:string) {
  var ctl = document.getElementById(id.toString());
  var startPos = ctl['selectionStart'];
  if (startPos == 0 && String.fromCharCode(event.which) == '0') {
   // this.UserForm.get(field.toString()).updateValueAndValidity();
    return false
    
  }
  if(event.target.value.length==3){
    this.UserForm.get(field.toString()).setValue(event.target.value+' ');
   // this.UserForm.get(field.toString()).updateValueAndValidity();
  }

}
isBackspace(event,field:string){
  if((event.keyCode || event.charCode)==8){
    console.log(this.UserForm.get(field.replace('_input','').toString()).value)
    console.log(this.UserForm.get(field.toString()).value);
    
   if(this.UserForm.get(field.replace('_input','').toString()).value){
    this.UserForm.get(field.replace('_input','').toString()).setValue(event.target.value); 
    //this.UserForm.get(field.toString()).updateValueAndValidity();
   }
    
  }
}
BlockUnBlock(user:UserListVM){
  this.modalRef = this.modalService.show(ConfirmationDialogComponent, {
    initialState: {
      prompt: `Are you sure you want to ${user.is_active?'Block':'Unblock'} <b>${user.first_name} ${user.last_name}</b>?`,
      callback: (result) => {
        if (result.confirmation == 'yes'){
          /* this.UserForm= this.fb.group(this.UserFormConfig);
         
          this.api.GetUser(user.id).subscribe((x:GOResponse<UserProfileDetailVM>)=>{
             let d_o_b= new Date(x.data.profile.date_of_birth);
            this.formControls.day.setValue(`${d_o_b.getDate()}`);
            this.formControls.month.setValue(d_o_b.getMonth()+1);
            this.formControls.year.setValue(`${d_o_b.getFullYear()}`);
            this.UserForm.patchValue(x.data.profile);
            
               this.formControls.primary_phone_number_input.setValue(x.data.profile.primary_phone_number);
                if(x.data.profile.secondary_phone_number){
            this.formControls.secondary_phone_number_input.setValue(x.data.profile.secondary_phone_number)
            
                }
                this.formControls.nok_phone_number_input.setValue(x.data.profile.nok_phone_number); 
                this.UserForm.get('is_active').setValue(!user.is_active);
                this.api.UpdatingUser(this.UserForm.value).subscribe(x=>{}, (e:GOResponse<any>)=>{this.isSaving=false;this.message=e.error.message;},
                ()=>{this.loadBorrowers();this.Cancel();this.successAlert()})
            
              })   */
              this.alerts=[]
              this.api.GetUser(user.id).subscribe((x:GOResponse<UserProfileDetailVM>)=>{
                let obj = x.data;
                obj.profile.is_active=!obj.profile.is_active;
                if(typeof obj.profile.passport_photo==='string'){
                  obj.profile.passport_photo="null";
                }
                this.api.UpdatingUser(obj.profile).subscribe(x=>{}, (e:GOResponse<any>)=>{this.isSaving=false;this.message=e.error.message;},
                ()=>{this.loadBorrowers();this.successBlockUnblockAlert(user)})
              })
              
              

              
        }
      }
    },
    backdrop:'static',
    keyboard:false
  })
}
successBlockUnblockAlert(user:UserListVM): void {
  this.alerts.push({
    type: 'success',
    msg: `${user.first_name} ${user.last_name} has successfully been ${user.is_active?'blocked':'unblocked'}`
  });
}
get canManageStaff(){
return this.authService.currentUser?.roles!='Reviewer'
}
onInstantLoanChange(){
  this.UserForm.get('can_get_instant_loan').valueChanges.subscribe(x=>{
    this.isSubmittedSettings=false;
    if(x==true){
      
      this.formControls.maximum_instant_loan_amount.setValidators([Validators.required,Validators.min(1)])
    }else{
      this.formControls.maximum_instant_loan_amount.setValidators([])
    }
    this.formControls.maximum_instant_loan_amount.updateValueAndValidity();
  })
}
ngOnDestroy(){
  if(this.modal.isShown){
    this.modal.hide();
  }
  if(this.UserModalRef){
    this.UserModalRef.hide();
  }
}
}
