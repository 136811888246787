import { Component, OnInit, NgZone } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AdminService } from '../../services/admin.service';
import { DatePipe } from '@angular/common';
import { ProfitReportResponse, GOResponse, PaymentsReportResponse, TransactionsReportResponse, LoanMissedPaymentResponse, DebtAgingResponse, 
  UserListVM, PrintTemplate, MyWalletReportItem} from '../../models/app.model';
import * as XLSX from 'xlsx'; 
import * as moment from 'moment/moment'
import { PrintReportService } from '../../services/print-report.service';
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.css'],
  providers:[DatePipe]
})
export class ReportComponent implements OnInit {
title:string;
daterangepickerModel:Date[]=[];
transaction_types:any[]=[
  'Deposit',
'Custody Account Creation',
'Custody Account Inter Transfer',
'Ecosystem Credit',
'Ecosystem Debit',
'Loan Disbursement',
'Loan Repayment',
'Refund',
'Withdraw'
];
platform:any[]=[
  'Cash',
'Great Oaks',
'System',
'Mobile Money'
]
headers:any={
'Loan Disbursement':['Loan ID', 'Borrower Name','Loan Request','Amount Owed','Request Date','Date Approved','Duration (Hrs)','Status'],
'Loan Repayment':['Loan ID', 'Borrower Name','Loan Amount','Amount Paid','Balance','Initiated At','Completed At','Status'],
'Deposit':['Loan ID', 'Borrower Name','Amount Initiated','Amount Credited','Charges','Initiated At','Completed At','Platform','Account No','Status'],
'Withdraw':['Loan ID', 'Borrower Name','Amount Initiated','Charges','Amount Debited','Initiated At','Completed At','Platform','Account No','Status'],
'Ecosystem Credit':['Account','Amount','Date'],
'Ecosystem Debit':['Account','Amount','Date'],
'Refund':['Borrower Name','Amount Refunded','Initiated At','Completed At','Duration (Hrs)'],
'Custody Account Creation':['Account','Amount','Date'],
'Custody Account Inter Transfer':['Account From','Account To','Amount','Date']
};
ReportFilter:FormGroup;
  data: ProfitReportResponse;
  payments_data: PaymentsReportResponse;
  transactions_data: TransactionsReportResponse;
  printData:PrintTemplate;
  ranges: IRange[] = [{
    value: [new Date(new Date().setDate(new Date().getDate() - 30)), new Date()],
    label: 'Last 30 Days'
  }, 
  {
    value: [new Date(new Date().setDate(new Date().getDate() - 60)), new Date()],
    label: 'Last 60 Days'
  },
  {
    value: [new Date(new Date().setDate(new Date().getDate() - 90)), new Date()],
    label: 'Last 90 Days'
  }];

  ranges_next: IRange[] = [{
    value: [ new Date(),new Date(new Date().setDate(new Date().getDate() + 30))],
    label: 'Next 30 Days'
  }, 
  {
    value: [ new Date(),new Date(new Date().setDate(new Date().getDate() + 60))],
    label: 'Next 60 Days'
  },
  {
    value: [new Date(),new Date(new Date().setDate(new Date().getDate() + 90))],
    label: 'Next 90 Days'
  }];
  loan_payments_data: any=[];
  debt_aging_data: DebtAgingResponse;
  view_headers:any[]=[];
  borrowers: UserListVM[]=[];
  payments_due_data: PaymentsReportResponse;
  my_wallet_report: MyWalletReportItem[]=[];
  status_list:any[]=[
    {name:'All',value:null},
    {name:'Pending Collateral Review',value:'Pending Collateral Review'},
    {name:'Pending Approval', value:'Pending Approval'},
    {name:'Ongoing', value:'Ongoing'},    
    {name:'Paid', value:'Paid'},    
    {name:'Rejected', value:'Rejected'},
    {name:'Bad Debt', value:'Bad Debt'},
    {name:'Frozen',value:'Frozen'}
  ]
  isPhone: boolean;
  phone_collapse:boolean=true;
  constructor(private route:ActivatedRoute, private fb:FormBuilder, private api:AdminService,private printService:PrintReportService,
    private ngZone:NgZone,private breakpointObserver:BreakpointObserver) {
      //  console.log(this.route)
      this.breakpointObserver.observe([
        Breakpoints.XSmall,
        Breakpoints.Small,
        Breakpoints.Medium,
        Breakpoints.Large,
        Breakpoints.XLarge
      ]).subscribe( (state: BreakpointState) => {
        //console.log(state)
        if(state.breakpoints[Breakpoints.Small] || state.breakpoints[Breakpoints.XSmall]){
             this.isPhone=true;
        }else{
          this.isPhone=false;
        }
      });
    this.ReportFilter= this.initFormFilter();
this.route.params.subscribe(x=>{
  this.title=x['type'];
  if(this.title=='Transactions'){
    this.TransactionsReport();
  } if(this.title=='Borrowers'){
    this.loadBorrowers();
  }
})
   }
SetValidation(){
  let typeControl= this.ReportFilter.get('type');
  let currencyControl = this.ReportFilter.get('currency')
  let toControl = this.ReportFilter.get('to')
  let nameControl = this.ReportFilter.get('name');
  let transactionControl= this.ReportFilter.get('transaction')
if(this.title=='Interest'){
  typeControl.setValidators([Validators.required]); 
  currencyControl.setValidators([Validators.required]); 

  toControl.setValidators([Validators.required])
  typeControl.valueChanges.subscribe(x=>{
if(x=='Single'){
  nameControl.setValidators([Validators.required]) 
}else{
  nameControl.setValidators([])
}
nameControl.updateValueAndValidity();
  })
  
}
if(this.title=='Payment Due'){
  currencyControl.setValidators([Validators.required]); 
  toControl.setValidators([Validators.required])
}
 if(this.title=='Missed Payments'){
  currencyControl.setValidators([Validators.required]); 
 // toControl.setValidators([Validators.required])
} 
if(this.title=='Debt Aging'){
  currencyControl.setValidators([Validators.required]); 
}
if(this.title=='Transactions'){
transactionControl.setValidators([Validators.required]); 
toControl.setValidators([Validators.required]);
currencyControl.setValidators([Validators.required]);
this.ReportFilter.get('status').setValue('All');
}
transactionControl.updateValueAndValidity();
typeControl.updateValueAndValidity();
currencyControl.updateValueAndValidity();
toControl.updateValueAndValidity();
nameControl.updateValueAndValidity();
this.ReportFilter.get('status').updateValueAndValidity();
}
   initFormFilter(){
     return this.fb.group({
       type:[''],
       to:[''],
       from:[''],
       currency:[''],
       name:[''],
       status:[''],
       transaction:[''],
       platform:[''],
       account:['']
     })     
   }
   ResetValidation(){
    this.ReportFilter.get('type').setValidators([]);
    this.ReportFilter.get('currency')
    this.ReportFilter.get('to')
    this.ReportFilter.get('name')
   }
   isValid(field) { 
    return (!this.ReportFilter.controls[field]?.touched || this.ReportFilter.controls[field]?.valid)&& this.isSubmitted; 
  }
  isSubmitted:boolean=false;
  ngOnInit(): void {
    this.SetValidation();
  }
  ProfitReport(){
    
    this.api.GeProfitReport(this.ReportFilter.get('to').value,this.ReportFilter.get('from').value,this.ReportFilter.get('currency').value,this.ReportFilter.get('name').value).subscribe((x:GOResponse<ProfitReportResponse>)=>{
this.data=x.data;
if(this.isPhone){
  this.phone_collapse=false
}
this.setPrintData(x);
    })
  }
  LoanStatusReport(){
    this.api.GeProfitReport(this.ReportFilter.get('to').value,this.ReportFilter.get('from').value,this.ReportFilter.get('currency').value,this.ReportFilter.get('name').value,this.ReportFilter.get('status').value).subscribe((x:GOResponse<ProfitReportResponse>)=>{
      this.data=x.data;
      if(this.isPhone){
        this.phone_collapse=false
      }
      this.setPrintData(x);
      
          })
  }
  ActiveLoansReport(){
    
    this.api.ActiveLoansReport(this.ReportFilter.get('to').value,this.ReportFilter.get('from').value,this.ReportFilter.get('currency').value,this.ReportFilter.get('name').value).subscribe((x:GOResponse<ProfitReportResponse>)=>{
this.data=x.data;
if(this.isPhone){
  this.phone_collapse=false
}
this.setPrintData(x);
    })
  }
  MissedPayments(){
    this.api.GetMissedPayments(this.ReportFilter.get('to').value,this.ReportFilter.get('from').value,this.ReportFilter.get('currency').value,this.ReportFilter.get('name').value,'Missed').subscribe((x:GOResponse<PaymentsReportResponse>)=>{
this.payments_data=x.data;
this.setPrintData(x);
    })
  }
  MyWalletReport(){
    this.api.MyWAlletReport(this.ReportFilter.get('currency').value).subscribe((x:GOResponse<any>)=>{
      this.my_wallet_report=x.data;
      if(this.isPhone){
        this.phone_collapse=false
      }
      this.setPrintData(x);
    })
  }
  LoanMissedPayments(){
    this.api.GetMissedPaymentsByLoans(this.ReportFilter.get('to').value,this.ReportFilter.get('from').value,this.ReportFilter.get('currency').value,this.ReportFilter.get('name').value,'Missed').subscribe((x:GOResponse<LoanMissedPaymentResponse>)=>{
this.loan_payments_data=x.data;

if(this.isPhone){
  this.phone_collapse=false
}
this.setPrintData(x);
    })
  }
  PaymentsDue(){
    this.api.GetMissedPayments(this.ReportFilter.get('to').value,this.ReportFilter.get('from').value,this.ReportFilter.get('currency').value,this.ReportFilter.get('name').value,'Due').subscribe((x:GOResponse<PaymentsReportResponse>)=>{
      this.payments_due_data=x.data;
      if(this.isPhone){
        this.phone_collapse=false
      }
      this.setPrintData(x);
          })
  }
  
  TransactionsReport(){
    this.api.GetTransactionsReport(this.ReportFilter.get('from').value,this.ReportFilter.get('to').value,this.ReportFilter.get('currency').value,this.ReportFilter.get('name').value,this.ReportFilter.get('status').value,this.ReportFilter.get('transaction').value,this.ReportFilter.get('platform').value).subscribe((x:GOResponse<TransactionsReportResponse>)=>{
this.transactions_data=x.data;
if(this.isPhone){
  this.phone_collapse=false
}
this.setPrintData(x);
    })
  }
  DebtAging(){
    this.api.GetDebtAgingReport(this.ReportFilter.get('currency').value,this.ReportFilter.get('name').value).subscribe((x:GOResponse<DebtAgingResponse>)=>{
      x.data.headers.forEach(h=>{
        this.view_headers.push(true);
      })
      this.debt_aging_data=x.data;
      if(this.isPhone){
        this.phone_collapse=false
      }
      this.setPrintData(x);
     
          })
  }
  loadBorrowers(){
    this.api.GetUsers('borrower').subscribe((x:GOResponse<UserListVM[]>)=>{             
      this.borrowers=x.data;
      //this.setPrintData(x);
      this.printData={ filter:{
        currency:this.ReportFilter.get('currency').value,
        from:this.ReportFilter.get('from').value,
        to:this.ReportFilter.get('to').value,
        name:this.ReportFilter.get('name').value,
        type:this.ReportFilter.get('type').value
      },
      response:{
      details:x.data
      }}
    })
    }
dateChange(event:Date[]){
  this.ngZone.run(() => {
     this.ReportFilter.get('to').setValue(new DatePipe('en-US').transform(event[1],'yyyy-MM-dd').valueOf())
 this.ReportFilter.get('from').setValue(new DatePipe('en-US').transform(event[0],'yyyy-MM-dd').valueOf())

  });

}
exportTransactionsexcel(): void 
    {
       /* table id is passed over here */   
       let element = document.getElementById('transaction-table'); 
       const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element);

       /* generate workbook and add the worksheet */
       const wb: XLSX.WorkBook = XLSX.utils.book_new();
       XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

       /* save to file */
       XLSX.writeFile(wb, 'TransactionTableExcel'+moment().toString()+'.xlsx');
			
    }
    exportExcel(){
         /* table id is passed over here */   
         let element = document.getElementById(this.title.toString()); 
         const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element);
  
         /* generate workbook and add the worksheet */
         const wb: XLSX.WorkBook = XLSX.utils.book_new();
         XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
  
         /* save to file */
         XLSX.writeFile(wb, this.title.replace(" ",'_')+moment().toString()+'.xlsx');
    }
onSearchChanged($event){
  this.ReportFilter.get('name').setValue($event)
}

Print(){
 
  this.printService.printDocument(this.printData,this.title)
//this.printService.onDataReady();
}
PDF(){
  this.printService.printDocument(this.printData,'pdf')
}
setPrintData(x){
  this.printData={ filter:{
    currency:this.ReportFilter.get('currency').value,
    from:this.ReportFilter.get('from').value,
    to:this.ReportFilter.get('to').value,
    name:this.ReportFilter.get('name').value,
    type:this.ReportFilter.get('type').value
  },
  response:x.data}
}
 get getHeaders(){
   if(this.ReportFilter.get('transaction').value){
     return this.headers[this.ReportFilter.get('transaction').value];
   }else{
     return []
   }
   
 }
}
interface IRange {
  value: Date[];
  label: string;
}
