import { Component, OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import jsPDF from 'jspdf';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';

import html2canvas from 'html2canvas';
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import htmlToPdfmake from 'html-to-pdfmake';
import { PrintReportService } from '../../services/print-report.service';

@Component({
  selector: 'app-print-layout',
  templateUrl: './print-layout.component.html',
  styleUrls: ['./print-layout.component.css']
})
export class PrintLayoutComponent implements OnInit,AfterViewInit {
  images = ['assets/go_logo.png'];
  constructor(private printService:PrintReportService) {
   
   }

  ngOnInit(): void {
  }
  ngAfterViewInit(){
     this.printService.fileTypes.subscribe(x=>{
      if(x=='pdf'){
        //this.downloadAsPDF();
        this.convetToPDF()
      }
    })
  }
  @ViewChild('printTable') pdfTable: ElementRef;
  
  public async downloadAsPDF() {
    const doc = new jsPDF();
   
    const pdfTable = this.pdfTable.nativeElement;
   
    var html = htmlToPdfmake(pdfTable.innerHTML);
     
    const documentDefinition = { content: html,image: await this.getBase64ImageFromURL("../../assets/go_logo.png")};
    pdfMake.createPdf(documentDefinition).open(); 
     
  }
  getBase64ImageFromURL(url) {
    return new Promise((resolve, reject) => {
      var img = new Image();
      img.setAttribute("crossOrigin", "anonymous");
    
      img.onload = () => {
        var canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;
    
        var ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0);
    
        var dataURL = canvas.toDataURL("image/png");
    
        resolve(dataURL);
      };
    
      img.onerror = error => {
        reject(error);
      };
    
      img.src = url;
    });}
    public convetToPDF() {
      var data = document.getElementById('printTable');
      html2canvas(data).then(canvas => {
          // Few necessary setting options
          var imgWidth = 208;
          var pageHeight = 295;
          var imgHeight = canvas.height * imgWidth / canvas.width;
          var heightLeft = imgHeight;

          const contentDataURL = canvas.toDataURL('image/png')
          let pdf = new jsPDF('p', 'mm', 'a4'); // A4 size page of PDF
          var position = 0;
          pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight)
          pdf.save('new-file.pdf'); // Generated PDF
      });
  }
}
