import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-support',
  templateUrl: './support.component.html',
  styleUrls: ['./support.component.css']
})
export class SupportComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
  isCollapsed: boolean = false;

  collapsed(event: any): void {
    // console.log(event);
  }

  expanded(event: any): void {
    // console.log(event);
  }
  support:any[]=[];
  supports:any[]=[
    {q:'How long will it take to get my money?',a:'This will vary from instantly to maximum 8 hours.'},
    {q:'How do I apply for a loan?',a:'Please click the apply button and follow the prompts. Remember to attach photographs or scans of your collateral.'},
    {q:'What information should I provide so I can sign up?',a:'You should provide your full biodata, copy of national ID, passport photo and details of your next of kin.'},
    {q:'I have lost my password. What should I do?',a:'On the login page, click the Forgot Password link. When you enter your username and a One-Time password (OTP) will be emailed to you. Use this OTP for your first time login and then update it to a password which you can remember.'},
    {q:'What password format is allowed?',a:'All passwords should be 5-digit PINs.'},
    {q:'How do I withdraw money from my wallet?',a:'You can withdraw cash at the teller or transfer money from your wallet to Mobile Money or to your bank account.'},
    {q:'How do I deposit money in my wallet?',a:'You can transfer money from your Mobile Money into the app wallet. Alternatively, please go to the nearest teller with cash and it will be deposited onto your wallet.'},
    {q:'How do I pay for my loan?',a:'Click the Pay Due button and follow the prompts.'},
    {q:'Is it okay to pay less than the amount owed in that month?',a:'Yes it is okay. However, you will receive a penalty on the balance owed in that month. To avoid penalties, please pay the money owed in full.'},
    {q:'Can I pay my loan back earlier than I agreed?',a:'Yes you can. There will not be any changes in the costs initially agreed on. GreatOaks Finance encourages you to pay your loan back at the earliest time possible.'},
    {q:'Can I borrow a loan for a friend?',a:'GreatOaks Finance will register the loan as yours and all collateral provided must belong to you. You will be liable for the loan borrowed.'},
    {q:'Does the loan require a cosigner?',a:'No. The borrower is fully liable for the loan.'}
  ];
  clickedSupport(i){
    
    if(!this.support[i]){
this.support=[];      
this.support[i]=true;
    }else{
      this.support[i]=false;
    }
    
  }
}
