import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ActiveLoansComponent } from './active-loans/active-loans.component';
import { AccountsComponent } from './admin/accounts/accounts.component';
import { BorrowersComponent } from './admin/borrowers/borrowers.component';
import { CollateralTypesComponent } from './admin/collateral-types/collateral-types.component';
import { CustodyAccountComponent } from './admin/custody-account/custody-account.component';
import { LoanProductsComponent } from './admin/loan-products/loan-products.component';
import { ReportsComponent } from './admin/reports/reports.component';
import { UsersComponent } from './admin/users/users.component';
import { AllLoansComponent } from './all-loans/all-loans.component';
import { ApplyComponent } from './apply/apply.component';

// Import Containers
import { DefaultLayoutComponent } from './containers';
import { HomeComponent } from './home/home.component';
import { LoanApplicationComponent } from './loan-application/loan-application.component';
import { LoanCollateralComponent } from './loan-collateral/loan-collateral.component';
import { LoanHistoryComponent } from './loan-history/loan-history.component';
import { LoanScheduleComponent } from './loan-schedule/loan-schedule.component';
import { LoanTrackerComponent } from './loan-tracker/loan-tracker.component';
import { LoansComponent } from './loans/loans.component';
import { ProductPageComponent } from './product-page/product-page.component';
import { ProfileComponent } from './profile/profile.component';
import { AuthGuard } from './services/auth-guard.service';
import { StatusComponent } from './status/status.component';
import { SupportComponent } from './support/support.component';
import { TransactionHistoryComponent } from './transaction-history/transaction-history.component';
import { TransactionsComponent } from './transactions/transactions.component';

import { P404Component } from './views/error/404.component';
import { P500Component } from './views/error/500.component';
import { LoginComponent } from './views/login/login.component';
import { RegisterComponent } from './views/register/register.component';
import { WalletTransferComponent } from './wallet-transfer/wallet-transfer.component';
import { WalletComponent } from './wallet/wallet.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { ApprovalsComponent } from './approvals/approvals.component';
import { BorrowerDetailComponent } from './borrower-detail/borrower-detail.component';
import { BorrowerFindComponent } from './borrower-find/borrower-find.component';
import { LockscreenComponent } from './lockscreen/lockscreen.component';
import { EndOfDayComponent } from './end-of-day/end-of-day.component';
import { ProfileEditComponent } from './profile-edit/profile-edit.component';
import { ReportComponent } from './admin/report/report.component';
import { PrintLayoutComponent } from './common/print-layout/print-layout.component';
import { InterestReportComponent } from './report-layout/interest-report/interest-report.component';
import { MissedPaymentsReportComponent } from './report-layout/missed-payments/missed-payments.component';
import { DebtAgingReportComponent } from './report-layout/debt-aging-report/debt-aging-report.component';
import { PaymentsDueReportComponent } from './report-layout/payments-due-report/payments-due-report.component';
import { BorrowersReportComponent } from './report-layout/borrowers-report/borrowers-report.component';
import { HelpComponent } from './help/help.component';
import { ActiveLoansReportComponent } from './report-layout/active-loans-report/active-loans-report.component';
import { MobileProfileComponent } from './mobile-profile/mobile-profile.component';

export const routes: Routes = [
 /*  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full',
  }, 
  {
    path: 'home',
    component: HomeComponent,
    data: {
      title: 'Home'
    }
  },*/
    {
      path: '',
      component: HomeComponent,
      data: {
        title: 'Home'
      },
    canActivate:[AuthGuard],
    children:[
     /**/  {
        path: '',
        redirectTo:'find-borrower',
        pathMatch:'full'
      }, 
      {
        path:'as',
        children:[
          {
        path: 'a-loans',
        component: ActiveLoansComponent,
        data: {
          title: 'Home'
        }
      },
      {
        path: 'apply',
        component: ApplyComponent,
        data: {
          title: 'Home'
        }
      },
      {
        path: 'apply/:id',
        component: ApplyComponent,
        data: {
          title: 'Home'
        }
      },      
      {
        path: 'wallet',
        component: WalletComponent,
        data: {
          title: 'Home'
        }
      },
      {
        path: 'support',
        component: SupportComponent,
        data: {
          title: 'Home'
        }
      }, 
       
      {
        path: 'history/:id',
        component: LoanScheduleComponent,
        data: {
          title: 'Home'
        }
      },
      
      {
        path: 'history',
        component: LoanHistoryComponent,
        data: {
          title: 'Home'
        }
      },
      {
        path: 'loan-collateral',
        component: LoanCollateralComponent,
        data: {
          title: 'Home'
        }
      },
      {
        path: 'notifications',
        component: NotificationsComponent,
        data: {
          title: 'Notifications Page'
        }
      },
      {
        path: 't-history',
        component: TransactionHistoryComponent,
        data: {
          title: 'Home'
        }
      },
      {
        path: 't-history/:user_id/:account_id',
        component: TransactionHistoryComponent,
        data: {
          title: 'Home'
        }
      },
      {
        path: 't-history/:account_id',
        component: TransactionHistoryComponent,
        data: {
          title: 'Home'
        }
      },
      {
        path: 'products/:id',
        component: ProductPageComponent,
        data: {
          title: 'Products'
        }
      }
        ]
      },
      {
        path: 'apply/admin/:borrower/:f/:l',
        component: ApplyComponent,
        data: {
          title: 'Home'
        }
      },
      {
        path: 'all-loans',
        component: AllLoansComponent,
        data: {
          title: 'Home'
        }
      },
      {
        path: 'all-loans/:loan_id',
        component: LoanApplicationComponent,
        data: {
          title: 'Home'
        }
      },
      /* {
        path: ':loan_id',
        component: LoanApplicationComponent,
        data: {
          title: 'Home'
        }
      }, */
      
      {
        path: 'transfer/:transaction/:to',
        component: WalletTransferComponent,
        data: {
          title: 'Home'
        }
      },
      
      {
        path: 't-history/:user_id/:f_name',
        component: TransactionHistoryComponent,
        data: {
          title: 'Home'
        }
      },
      
      
      {
        path: 'active-schedule',
        component: LoanScheduleComponent,
        data: {
          title: 'Home'
        }
      },
      {
        path: 'approvals',
        component: ApprovalsComponent,
        data: {
          title: 'Home'
        }
      },
      {
        path: 'help',
        component: HelpComponent,
        data: {
          title: 'Home'
        }
      },
      {
        path: 'loan-collateral/:new',
        component: LoanCollateralComponent,
        data: {
          title: 'Home'
        }
      },
      {
        path: 'track-loan',
        component: LoanTrackerComponent,
        data: {
          title: 'Home'
        }
      },
      
      {
        path: 'transactions',
        component: TransactionsComponent,
        data: {
          title: 'Transactions Page'
        }
      },
      {
        path: 'loans',
        component: LoansComponent,
        data: {
          title: 'Loans Page'
        }
      },
      {path:'lock-otp-exp/:username',component:LockscreenComponent},
      {
        path: 'users',
        component: UsersComponent,
        data: {
          title: 'Users'
        }
      },
      {
        path: 'borrowers',
        component: BorrowersComponent,
        data: {
          title: 'Borrowers'
        }
      },
      {
        path: 'loan-products',
        component: LoanProductsComponent,
        data: {
          title: 'Loans Products'
        }
      },
      {
        path: 'collateral-types',
        component: CollateralTypesComponent,
        data: {
          title: 'Collateral Types'
        }
      },
      {
        path: 'reports',
        component: ReportsComponent,
        data: {
          title: 'Reports'
        }
      },
      {
        path: 'reports/:type',
        component: ReportComponent,
        data: {
          title: 'Reports'
        }
      },
      {
        path: 'accounts',
        component: AccountsComponent,
        data: {
          title: 'Accounts'
        }
      },
      {
        path: 'accounts/:id',
        component: CustodyAccountComponent,
        data: {
          title: 'Custody'
        }
      },
      
      {
        path: 'settings',
        component: ProfileComponent,
        data: {
          title: 'Profile'
        }
      },
      {
        path: 'edit-profile',
        component: ProfileEditComponent,
        data: {
          title: 'Profile'
        }
      },
      
      {
        path: 'borrowers/:id',
        component: BorrowerDetailComponent,
        data: {
          title: 'Home'
        },
        outlet:'borrower'
      },
      {
        path:'find-borrower',
        component:BorrowerFindComponent
      },
      {
        path: 'find-borrower/:id',
        component: BorrowerDetailComponent,
        data: {
          title: 'Home'
        }
      },
      {
        path: 'end-day',
        component: EndOfDayComponent,
        data: {
          title: 'Home'
        }
      },
      {
        path: 'mobile-profile',
        component: MobileProfileComponent,
        data: {
          title: 'Home'
        }
      }
    ]
  },
  {
    path:'borrower',
    component:HomeComponent,
    children:[
{
    path: 'borrowers/:id',
    component: BorrowerDetailComponent,
    data: {
      title: 'Home'
    },
    outlet:'borrower'
  }
    ]
  },
  {path:'lock-otp-exp/:username/:otp/:fullname',component:LockscreenComponent},
  {
    path: '404',
    component: P404Component,
    data: {
      title: 'Page 404'
    }
  },
  {
    path: '500',
    component: P500Component,
    data: {
      title: 'Page 500'
    }
  },
  {
    path: 'login',
    component: LoginComponent,
    data: {
      title: 'Login Page'
    }
  },
  {path:'lock-otp-exp/:username/:otp/:fullname/:token',component:LockscreenComponent},
  {
    path: 'register',
    component: RegisterComponent,
    data: {
      title: 'Register Page'
    }
  },
  {
    path:'print',        
    outlet:'print',
    component:PrintLayoutComponent,
    children:[
      {path:'Interest',component:InterestReportComponent},
      {path:'Active Loans',component:ActiveLoansReportComponent},
      {path:'Missed Payments',component:MissedPaymentsReportComponent},
      {path:'Debt Aging',component:DebtAgingReportComponent},
      {path:'Payment Due',component:PaymentsDueReportComponent},
      {path:'Borrowers',component:BorrowersReportComponent},
      {path:'interest/pdf',component:InterestReportComponent},
      
    ]
  },
  
  {
    path: 'sample',
    component: DefaultLayoutComponent,
    data: {
      title: 'Home'
    },
    children: [
      {
        path: 'base',
        loadChildren: () => import('./views/base/base.module').then(m => m.BaseModule)
      },
      {
        path: 'buttons',
        loadChildren: () => import('./views/buttons/buttons.module').then(m => m.ButtonsModule)
      },
      {
        path: 'charts',
        loadChildren: () => import('./views/chartjs/chartjs.module').then(m => m.ChartJSModule)
      },
      {
        path: 'dashboard',
        loadChildren: () => import('./views/dashboard/dashboard.module').then(m => m.DashboardModule)
      },
      {
        path: 'icons',
        loadChildren: () => import('./views/icons/icons.module').then(m => m.IconsModule)
      },
      {
        path: 'notifications',
        loadChildren: () => import('./views/notifications/notifications.module').then(m => m.NotificationsModule)
      },
      {
        path: 'theme',
        loadChildren: () => import('./views/theme/theme.module').then(m => m.ThemeModule)
      },
      {
        path: 'widgets',
        loadChildren: () => import('./views/widgets/widgets.module').then(m => m.WidgetsModule)
      }
    ]
  },
  { path: '**', component: P404Component }
];

@NgModule({
  imports: [ RouterModule.forRoot(routes, {}) ],
  exports: [ RouterModule ]
})
export class AppRoutingModule {}
