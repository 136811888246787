import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { Observable, Subject, BehaviorSubject, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { INavData } from '@coreui/angular';

@Injectable({
  providedIn: 'root'
})
export class AppSideBarService {
  items$: Observable<INavData[]>= Observable.create((observer) => {
    observer.next([]);
});

  constructor() {
    /*if(user.currentUser.role=='Admin')
    this.items$ = Observable.create()  this.user.state$.pipe(
      map(user => this.getSidebarItems(user)),
    );*/
  }

  private getSidebarItems(): INavData[] {
    // logic depending on user context
    return [ /* sidebar items */];
  }
  setNav(nav:INavData[]){
    this.items$= new Observable(o=>o.next(nav))
  }
  getNav(){
    return this.items$;
  }
}
