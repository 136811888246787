import { Component, OnInit } from '@angular/core';
import { environment } from '../../environments/environment';
import { AuthService } from '../services/auth.service';
import { User } from '../models/user.model';

@Component({
  selector: 'app-mobile-profile',
  templateUrl: './mobile-profile.component.html',
  styleUrls: ['./mobile-profile.component.css']
})
export class MobileProfileComponent implements OnInit {
  borrowerUrl=`${environment.borrwerUrl}`;
  currentUser: User;
  constructor(private authService:AuthService) {
    this.currentUser=this.authService.currentUser;
   }

  ngOnInit(): void {
  }

}
