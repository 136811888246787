import { ChangeDetectorRef, Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, FormArray, Validators, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { TabsetComponent } from 'ngx-bootstrap/tabs';
import { Subscription } from 'rxjs';
import { ConfirmationDialogComponent } from '../common/confirmation-dialog/confirmation-dialog.component';
import { LoanProduct, CollateralTypeDTO, CollateralVM, GOResponse, CollateralDto, PaymentScheduleVM, VerificationVM, LoanProfile, CollateralTypeCaptureDetail, LoanInitiationResponse } from '../models/app.model';
import { FieldModel } from '../models/custom-form.base.model';
import { AdminService } from '../services/admin.service';
import { AuthService } from '../services/auth.service';
import { FormControlService } from '../services/form-control.service';
import { MenuService } from '../services/menu-service.service';
import { PublicService } from '../services/public.service';
import { User } from '../models/user.model';
import { UserProfileDetailVM } from '../admin/borrowers/borrowers.component';
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';

@Component({
  selector: 'app-apply',
  templateUrl: './apply.component.html',
  styleUrls: ['./apply.component.css']
})
export class ApplyComponent implements OnInit,OnDestroy {
  TransactionModalRef:BsModalRef;
  LoanRequestForm:FormGroup;
  CollateralSubmissionForm:FormGroup;
  isRequestSubmitted:boolean=false;
  isVerified:boolean=false;

  customer:string='new';

  subscriptions: Subscription[] = [];

  loan_id:string//="732208d3-d71a-47c6-b2ea-b917e823150f";

  @ViewChild('staticApplyTabs', { static: false }) staticTabs: TabsetComponent;

  enabledTabs:any[]=[];
  products: LoanProduct[]=[];

  isSaving:boolean=false;
  init_message:any;
  collateral_message:any;
  collateralTypes: CollateralTypeDTO[]=[];
  //loan_collateral_id: string="94bb7c69-66b4-400f-aff5-d38c0265f9d6"
  collaterals: CollateralVM[]=[];
  CollateralForms:FormGroup;

  isColCollapsed:any[]=[];
  isColSaving:any[]=[];

  isLoading:boolean=false;
  loan_profile: LoanProfile;
  modalRef: BsModalRef;
  instantLoan: boolean;
  isPhone: boolean;
  @ViewChild('loanselect') select;

  constructor(private modalService: BsModalService,private fb:FormBuilder,private router:Router,private changeDetection: ChangeDetectorRef,
    private api:AdminService, private publicapi:PublicService,private formControlService: FormControlService, private authService:AuthService,
    private routes:ActivatedRoute,private menuService:MenuService,private breakpointObserver:BreakpointObserver) {
      //  console.log(this.route)
      this.breakpointObserver.observe([
        Breakpoints.XSmall,
        Breakpoints.Small,
        Breakpoints.Medium,
        Breakpoints.Large,
        Breakpoints.XLarge
      ]).subscribe( (state: BreakpointState) => {
        //console.log(state)
        if(state.breakpoints[Breakpoints.Small] || state.breakpoints[Breakpoints.XSmall]){
             this.isPhone=true;
        }else{
          this.isPhone=false;
        }
      });
   // this.router.navigate(['/a-loans']);
   this.customer=this.authService.currentUser?.general_status;
    this.LoanRequestForm=this.initLoanApplication();
    this.CollateralSubmissionForm= this.initCollateralSubmission();
    this.CollateralForms= this.InitCollateralSubmissions();
    this.menuService.setMenu('applying');
  }

  borrower_id:string;
  name:any;
  ngOnInit(): void {
    this.enabledTabs[1]=true;
    this.enabledTabs[2]=true;
    this.enabledTabs[3]=true;
    this.isColCollapsed[0]=true;
    this.loadCollaterals(); ///Use onChanges
    this.loadCollateralTypes();
      this.loadProducts();
   
      this.routes.params.subscribe(x=>{
        if(x['id']){
          this.LoanRequestForm.get('loan_product').setValue(x['id'])
        }
        if(x['borrower']){
          this.borrower_id=x['borrower'];
          this.LoanRequestForm.get('borrower').setValue(this.borrower_id)
this.name={
f:x['f'],
l:x['l']
}
          console.log(x['borrower'])
        }
      })
      this.getLoan();
    /* this.api.ApplyForLoan('6b3179d5-703e-45c8-ab6b-8420f3a0e593').subscribe(x=>{
      
    }) */
    //this.router.navigate(['/find-borrower/',this.borrower_id]);
  }
  getLoan(addNew?:boolean){
    if(this.loan_id){
     this.loan_profile=null;
    this.api.GetLoanDetails(this.loan_id).subscribe((x:GOResponse<LoanProfile>)=>{    
      this.loan_profile=x.data
      this.SetForm(addNew);
    }) 
    }
    
  }
  ngAfterViewInit(){
    
  }
  get selectedProductName(){
    return this.LoanRequestForm.get('loan_product')?.value? this.products.filter(x=>x.id==this.LoanRequestForm.get('loan_product')?.value)[0]?.product_name:''
  }

  SetForm(addNew?:boolean){
    
    this.LoanRequestForm.patchValue(this.loan_profile.profile);
    this.LoanRequestForm.get('loan_product').setValue(this.loan_profile.profile.loan_product.id);
    this.LoanRequestForm.get('loan_currency').setValue(this.loan_profile.profile.loan_account);
   this.LoanRequestForm.get('principal_amount').setValue(this.loan_profile.profile.principal_amount)
   this.LoanRequestForm.get('loan_period').setValue(this.loan_profile.profile.loan_period)
   this.LoanRequestForm.get('check_form').setValue(true)
       this.getCollaterals.clear();
    
       if(this.loan_profile.collateral.length==0){
        this.getCollaterals.push(this.initCollateralSubmission()); 
        this.UnToggleAll();
        
            }
           
    this.loan_profile.collateral.forEach((x,i)=>{
     
        this.getCollaterals.push(this.initCollateralSubmission());  
    
    
      let colObj={
        collateral_type:x.collateral_type_details.id,
        collateral_value:x.collateral_value,
        check_form:true,
        id:x.id,
        loan:this.loan_id,
        collateral_description:x.collateral_description,
        collateral_identification_number:x.collateral_identification_number,
        collateral_file:x.collateral_file,
        collateral_captured_details: x.collateral_bank?x.collateral_bank_details.collateral_type.collateral_captured_details:x.collateral_type_details.collateral_captured_details      
      }      
       this.SetCustomFields(colObj.collateral_type,i,x.custom_information);
     //  console.log(x)

     /* if(this.CollatForm){
       
        this.CollatForm.patchValue(colObj.collateral_captured_details);  
     } */
   
      this.getCollaterals.at(i).patchValue(colObj);
      if(addNew){
     
        console.log("adding new colla")
        this.AddNewColl(i)
      }
     
    })
  }
  isCollapsed: boolean = true;

  collapsed(event: any): void {
    // console.log(event);
  }

  expanded(event: any): void {
    // console.log(event);
  }
  ngOnDestroy(){
    this.menuService.resetMenu();
  }
InitCollateralSubmissions(){
  return this.fb.group({
    items:this.fb.array([this.initCollateralSubmission()])
  })
}
get getCollaterals():FormArray{
  return <FormArray>this.CollateralForms.get('items');
}
AddNewColl(i:number,col?){ 
  
 // let prevObj= this.getCollaterals.at(this.getCollaterals?.length-1);
 if(this.getCollaterals.length==1&&col){

  this.SaveCollateral(col,i,true);
              }
  this.isColCollapsed[this.getCollaterals.length - 1] = false;
            this.getCollaterals.push(this.initCollateralSubmission())
            this.isColCollapsed[this.getCollaterals.length - 1] = true;
            this.currentTab = this.getCollaterals.length - 1
            if(this.getCollaterals.length==1){
              this.isColSaving.push(false); 
             }
             if(this.getCollaterals.length==this.isColSaving.length){
              this.isColSaving[i]=false;
             }else{
              this.isColSaving.push(false);
             }
            
            
            
 //
}
RemoveColl(i,id){
  if(id){
  this.DeleteCol(id,i);
}else{
  this.getCollaterals.removeAt(i);
  if(this.isColSaving.length>1){
    this.isColSaving.splice(i,1)
  }
}



if(this.getCollaterals.length==1){
  this.isColCollapsed[0]=true;
}

}

  initCollateralSubmission(){
    return this.fb.group({
      id:[''],
      loan:[''],
      collateral_type:['',Validators.required],     
      check_form:['', Validators.requiredTrue],
     /*  leaf_number:[''],
      cheque_name:[''],
      bank:[''],
      number_plate:[''],
      car_picture:[''],
      location:[''], */
      collateral_description:[''],
      collateral_identification_number:[''],
      collateral_value:[''],
      collateral_file:['',Validators.required],
      custom_information:this.fb.array([]),
      collateral_bank:[''],
      isSaved:[false]
    })
  }
  initLoanApplication(){
    return this.fb.group({
   
      loan_product:['',Validators.required],
      loan_currency:['',Validators.required],
      principal_amount:['',Validators.required],
      loan_period:['',Validators.required],
      check_form:['', Validators.requiredTrue],
      t_c:new FormControl({value: false, disabled: true}, Validators.requiredTrue),
      borrower:[''],
      source:['admin']
    })
  }
  setInstantLoanForm(){
    let instant_id:any[]= this.products.filter(x=>x.product_name=='Instant Loan');
    console.log("istant",instant_id)
if(instant_id?.length>0){
  
    this.LoanRequestForm.get('loan_product').valueChanges.subscribe(x=>{

      if(x==instant_id[0]?.id){
        this.instantLoan=true;
        this.LoanRequestForm.get('principal_amount').setValue(500000);
        this.LoanRequestForm.get('loan_period').setValue(1);
        this.LoanRequestForm.get('loan_currency').setValue('UGX');
        this.LoanRequestForm.get('check_form').setValue(true);
      }else{
        this.instantLoan=false;
        this.LoanRequestForm.get('principal_amount').setValue('');
        this.LoanRequestForm.get('loan_period').setValue('');
        this.LoanRequestForm.get('loan_currency').setValue('');
        this.LoanRequestForm.get('check_form').setValue('');
      }
    })
}
  }
  Today(setmonth?:number){
    return moment().add(setmonth,'months');
  }
  tabSelected=0;
  Next(){
   
    if(this.instantLoan){
      if(this.tabSelected==3){
        this.tabSelected=2;
         setTimeout(() => {        
          (console.log(this.staticTabs.tabs))  
           this.staticTabs.tabs[this.tabSelected].active = true;   
         //  this.enabledTabs[this.tabSelected-1]=true;  
          }, 500);
          if(this.LoanRequestForm.get('t_c').disabled &&this.verification_result){
            this.LoanRequestForm.get('t_c').enable();
           }
       }
      if(this.tabSelected==0){
              this.tabSelected=1  
        this.enabledTabs[3]=false;
      setTimeout(() => {
        (console.log(this.staticTabs.tabs))
        this.staticTabs.tabs[this.tabSelected].active = true;   
      //  this.enabledTabs[this.tabSelected-1]=true;  
       }, 500);
      }

  
     // this.enabledTabs[this.tabSelected] = false; 
    }else{

    
    if(this.tabSelected<4){  
       this.tabSelected=this.tabSelected+1   
        this.enabledTabs[this.tabSelected] = false; 
        if(this.tabSelected==1){
         /// this.TestCalc();
        //  this.verifyApplication();
      
        }
        if(this.tabSelected==3){
         
this.isVerified=true
          this.isRequestSubmitted=false;
          this.LoanRequestForm.get('t_c').enable();
          this.LoanRequestForm.get('t_c').setValue(false);
          if(this.getCollaterals.length==1){
          let prevObj= this.getCollaterals.at(this.getCollaterals.length-1);
 this.SaveCollateral(prevObj,(this.getCollaterals.length-1),false); 
          
          }else{
            this.is_verified=false
            this.verifyApplication(); 
          }
          
        }
        if(this.LoanRequestForm.get('t_c').disabled &&this.verification_result){
          this.LoanRequestForm.get('t_c').enable();
         }
       console.log(document.getElementsByName('tab'))
        setTimeout(() => {
         this.staticTabs.tabs[this.tabSelected].active = true;   
         this.enabledTabs[this.tabSelected-1]=true;  
        }, 500);        
      }
     /* */
    }   
 //
 window.scrollTo(0, 0)
  }
  compareFn(c1, c2): boolean {
    return c1 && c2 ? c1.id === c2.id : c1 === c2;
}
  DeleteCol(id,i){
    this.modalRef = this.modalService.show(ConfirmationDialogComponent, {
      initialState: {
        prompt: `Are you sure you want to delete this collateral?`,
        has_reason:false,
        callback: (result) => {
          if (result.confirmation == 'yes'){
    this.api.DeleteLoanCollateral(id).subscribe(x=>{

      this.getLoan();
      this.getCollaterals.removeAt(i);
      if(this.isColCollapsed.length>1){
        this.isColCollapsed.splice(i,1);
        this.UnToggleAll();
      }
      if(this.isColSaving.length>1){
        this.isColSaving.splice(i,1)
      }
     
    })
  }
}
},
backdrop:'static',
keyboard:false
})
  }

  Back(){
   
    if(this.tabSelected==2){
this.getLoan();
    }
      this.tabSelected=this.tabSelected-1   
       this.enabledTabs[this.tabSelected] = false; 
       if(this.isRequestSubmitted){
        this.isRequestSubmitted=false;
       }
       if(this.LoanRequestForm.get('t_c').enabled &&!this.verification_result){
        this.LoanRequestForm.get('t_c').disable()
       }
       if(this.LoanRequestForm.get('t_c').disabled &&this.verification_result){
        this.LoanRequestForm.get('t_c').enable();
       }
       
       setTimeout(() => {
        this.staticTabs.tabs[this.tabSelected].active = true; 
          this.enabledTabs[this.tabSelected+1]=true;
       }, 500);
   
  
   
    /*  */
   
  }
  TransactionDetailCancelled(){
   // this.TransactionModalRef.hide();
    this.tabSelected=0;
    this.enabledTabs=[];
    this.LoanRequestForm=this.initLoanApplication();
    if(this.borrower_id){
      this.LoanRequestForm.get('borrower').setValue(this.borrower_id)
    }
    this.CollateralSubmissionForm= this.initCollateralSubmission();
    this.CollateralForms= this.InitCollateralSubmissions();
    this.loadProducts();
    setTimeout(() => {
      this.staticTabs.tabs[this.tabSelected].active = true; 
        this.enabledTabs[this.tabSelected+1]=true;
     }, 500);
      }
      ViewTransactionDetail(template: TemplateRef<any>,product_type?:any){
        this.loadProducts();
        if(product_type){

        }else{
         this.LoanRequestForm=this.initLoanApplication(); 
        }
      

    this.TransactionModalRef= this.modalService.show(template,{class:'modal-lg',backdrop:'static',keyboard:false});
  this.enabledTabs[1]=true;
  this.enabledTabs[2]=true;
  this.isColCollapsed[0]=true;
// this.staticTabs.tabs[3].disabled=true;
     
   
      }
      loanResponse:LoanInitiationResponse;
      Save(){
/*         if(this.loan_id){
         // this.CollateralSubmissionForm.get('loan').setValue(this.loan_id);
         // this.loadCollateralTypes();
          this.Next();
        }else{ */
          
this.api.InitialiseLoanApplication(this.LoanRequestForm.value).subscribe((x:GOResponse<LoanInitiationResponse>)=>{
  this.loan_id= x.data.loan_id;
  this.loanResponse= x.data;
  this.collateralTypes=x.data.acceptable_collateral;
  console.log(x);
},(e:GOResponse<any>)=>{this.isSaving=false;this.init_message=e.error.message;},
()=>{
  //this.loadCollateralTypes();
  this.Next();
  if(this.selectedProductName=='Instant Loan'){
     this.verifyApplication()
  }
 
  this.isRequestSubmitted=false;
});
 //}
   }

  SaveCollateral(col,i, addNewCol: boolean) {
    this.isRequestSubmitted=false;
    if(this.getCollaterals.length>1||addNewCol){
      this.isColSaving[this.getCollaterals.length-1]=true;
    }
    
    /*  if(this.loan_collateral_id){
       this.verifyApplication();
       this.Next();

     }else{ */
      console.log(this.getCollaterals.length)
    if (col.get('collateral_bank').value) {

      this.api.UseExistingCollateral(this.loan_id, col.get('collateral_bank').value).subscribe((x: any) => {
this.UnToggleAll();

      }, (e: GOResponse<any>) => { this.isSaving = false; this.collateral_message = e.error.message; console.log(e); this.Back() },
        () => {
         // col.get('isSaved').setValue(true);

        /*   if (addNewCol) {
            this.isColCollapsed[this.getCollaterals.length - 1] = false
            this.getCollaterals.push(this.initCollateralSubmission())
            this.isColCollapsed[this.getCollaterals.length - 1] = true;
            this.currentTab = this.getCollaterals.length - 1
          } */

        })
    } else {

      if(this.CollatForm?.value){
       col.get('custom_information').push(this.CollatForm) 
      }
     
     
      let colObj: CollateralDto = {
id:col.get('id').value,
        loan: this.loan_id,
        collateral_description: col.get('collateral_description').value,
        collateral_file: col.get('collateral_file').value,
        collateral_identification_number: '123456',
        collateral_type: col.get('collateral_type').value,
        collateral_value: col.get('collateral_value').value,
        custom_information: this.CollatForm?.value?JSON.stringify([this.CollatForm.value]):''
      }
      console.log(colObj)
      col.get('isSaved').setValue(true);
      this.api.PostCollateral(colObj).subscribe((x: any) => {
        if(x?.body){
          col.get('id').setValue(x?.body?.data?.loan_collateral_id);
         
        }       if(!addNewCol){

             this.UnToggleAll() 
        }
     this.isColSaving[this.getCollaterals.length-1]=false;
        console.log(x);
      }, (e: GOResponse<any>) => { this.isSaving = false; this.collateral_message = e.error.message; console.log(e) },
        () => {
          if(!addNewCol&& this.getCollaterals.length==1){
            this.is_verified=false
            this.verifyApplication(); 
          }
          this.getLoan(addNewCol);
          this.isColSaving[this.getCollaterals.length-1]=false;
         /*  if (addNewCol) {
            this.isColCollapsed[this.getCollaterals.length - 1] = false;
            this.getCollaterals.push(this.initCollateralSubmission())
            this.isColCollapsed[this.getCollaterals.length - 1] = true;
            this.currentTab = this.getCollaterals.length - 1
          } */

        })
    }

    //   }

  }
  onFileChange(event, col) {
    console.log(event)
    if (event.target.files.length > 0) {
      const file = event.target.files[0];

      col.patchValue({
        collateral_file: file
      });

    }
  }
  TATP: number;
  CalcualateTATP() {
    //console.log(this.staticTabs)
    this.TATP = this.LoanRequestForm.get('principal_amount').value * (1 + (0.1 * this.LoanRequestForm.get('loan_period').value))
  }
  ScheduleTabledata: PaymentScheduleVM[] = [];
  GenerateTable() {
    /*  // user defined length
     this.ScheduleTabledata = [];
    for(var i = 0; i < this.LoanRequestForm.get('loan_period').value; i++) {
    
        this.ScheduleTabledata.push({month:i+1,opening:((this.LoanRequestForm.get('principal_amount').value/this.LoanRequestForm.get('loan_period').value)*(this.LoanRequestForm.get('loan_period').value-i)),expected:this.TATP/this.LoanRequestForm.get('loan_period').value});
    } */
  }
TestCalc(){
  this.CalcualateTATP();
  this.GenerateTable();
}
/* confirmTabSwitch($event) {
  if (this.disableSwitching) {
    const confirm = window.confirm('Discard changes and switch tab?');
    if (confirm) {
     // this.disableSwitching = false;
      const liArr = Array.from(this.staticTabs.nativeElement.querySelectorAll('ul li'));
      let tabIndex;
      liArr.forEach((li, i) => {
        if(li.contains($event.target)) {
          tabIndex = i;
        }
      });
      console.log('tab index', tabIndex);
      setTimeout(() => {
        this.tabset.tabs[tabIndex].active = true;
      });
    }
  }
} */

     banks:any[]=[
      {
        "name": "ABC Capital Bank Uganda Limited"
      },
      {
        "name": "Absa Bank Uganda Limited"
      },
      {
        "name": "Afriland First Bank Uganda Limited"
      },
      {
        "name": "Bank of Africa Uganda Limited"
      },
      {
        "name": "Bank of Baroda Uganda Limited"
      },
      {
        "name": "Bank of India Uganda Limited"
      },
      {
        "name": "BRAC Bank Limited"
      },
      {
        "name": "Cairo International Bank"
      },
      {
        "name": "Centenary Rural Development Bank Limited"
      },
      {
        "name": "Citibank Uganda Limited"
      },
      {
        "name": "DFCU Bank"
      },
      {
        "name": "Diamond Trust Bank Limited"
      },
      {
        "name": "Ecobank Uganda Limited"
      },
      {
        "name": "Equity Bank Uganda Limited"
      },
      {
        "name": "Exim Bank Uganda"
      },
      {
        "name": "Finance Trust Bank"
      },
      {
        "name": "Guaranty Trust Bank Uganda Limited"
      },
      {
        "name": "Housing Finance Bank Limited"
      },
      {
        "name": "KCB Bank Uganda Limited"
      },
      {
        "name": "NCBA Bank Uganda"
      },
      {
        "name": "Opportunity Bank Uganda Limited"
      },
      {
        "name": "Orient Bank Limited"
      },
      {
        "name": "PostBank Uganda Limited"
      },
      {
        "name": "Pride Microfinance Limited (MDI)"
      },
      {
        "name": "Stanbic Bank Uganda Limited"
      },
      {
        "name": "Standard Chartered Bank Uganda Limited"
      },
      {
        "name": "Top Finance Bank (TFB)"
      },
      {
        "name": "Tropical Bank Limited"
      },
      {
        "name": "United Bank for Africa"
      }
     ];
     loadProducts(){
      this.api.GetProducts('public').subscribe((x:GOResponse<LoanProduct[]>)=>{
        this.products=x.data;
        this.api.GetUser(this.borrower_id).subscribe((u:GOResponse<UserProfileDetailVM>)=>{
          if(!u.data.profile.can_get_instant_loan){
             this.products=this.products.filter(x=>x.product_name!='Instant Loan')
          }
          this.LoanRequestForm.get('loan_product').reset();
          console.log(this.select)
         // this.select.nativeElement.innerText=''
        })
       
        this.setInstantLoanForm();
      })
    }
    loadCollateralTypes(){
      this.api.GetCollateralTypes('admin').subscribe((x:GOResponse<CollateralTypeDTO[]>)=>{
        this.collateralTypes=x.data
      })
    }
  verification_result: boolean = false;
  verification_failure;
  is_verified=false;
  verifyApplication() {
    this.isLoading=true;
    this.is_verified=false;
    this.verification_result = false;
      this.verification_failure=null;
    this.api.VerifyLoanApplication({ id: this.loan_id }).subscribe((x: GOResponse<VerificationVM>) => {

      this.verification_result = x.data.result
      this.verification_failure=null;

      if (x.data.result) {
        this.ScheduleTabledata = x.data.payment_schedule.data
      }
      this.is_verified=true;
      this.isLoading=false;
    },(e:GOResponse<any>)=>{this.isSaving=false;this.isLoading=false;this.is_verified=true; this.verification_failure=e.error.message;})
  }
SubmitLoan(){
  this.api.ApplyForLoan(this.loan_id).subscribe(x=>{
    this.TransactionDetailCancelled();
    if(this.borrower_id){
      this.router.navigate(['/find-borrower/',this.borrower_id]);
    }else{
     this.router.navigate(['/as/a-loans']); 
    }
  
  })
}
SetCustomFields(t,i,collateral_custom_fields?:any[]){

  if(t!='Existing'){  
 let col:CollateralTypeDTO=this.collateralTypes.filter(x=>x.id==t)[0]

this.fields[i]=[]
if(col.collateral_captured_details?.length>0){
  col.collateral_captured_details.forEach((c_f,index)=>{
   const c_o=collateral_custom_fields?.length>0?collateral_custom_fields[0]:null
  
   const t= c_f.title.toLocaleLowerCase().replace(/ /g,"_");
   console.log(c_o)
if(collateral_custom_fields){
  console.log(c_o[t])
}
this.fields[i].push( new FieldModel(
  {label:c_f.title, id:`${c_f.title.toLocaleLowerCase().replace(/ /g,"_")}`,
  controlType:c_f.type,required:c_f.required?c_f.required:false,
  errorMessage:`${c_f.title} is required`,
value:c_o?c_o[t]:''
}))
})
this.CollatForm = this.formControlService.getFormGroupObject(this.fields[i]);
}

//console.log(this.fields);

}else{
  this.fields[i]=[]
}
}
fields: FieldModel[][]=[[]];
CollatForm: FormGroup;
loadCollaterals(){
  this.api.GetCollateral().subscribe((x:GOResponse<CollateralVM[]>)=>{
this.collaterals=x.data;
  })
}
currentTab=0;
ToggleForm(level:number,col){
  let a= Array.from(Array(this.getCollaterals.length).keys())
  a.forEach(el => {
    if(el==level){
      this.isColCollapsed[level]=!this.isColCollapsed[level];
      this.currentTab=level;
      this.SetCustomFields(col.get('collateral_type').value,level,this.loan_profile?.collateral[level]?.custom_information);
      if(col.get('custom_information')?.value?.length>0){
        this.CollatForm.patchValue(col.get('custom_information').value[0]) 
      }
     
    }else{
      this.isColCollapsed[el]=false
    }
  });
}
UnToggleAll(){
  let a= Array.from(Array(this.getCollaterals.length).keys())
  a.forEach((el,i) => {
    this.isColCollapsed[i]=false;
  })
}
get allCollapsed(){
return this.isColCollapsed.filter(x=>!x).length==this.getCollaterals.length
}
getCollateralName(id,existing?:boolean):any{
  if(id){
    if(!existing){
      let coltype=this.collateralTypes.filter(x=>x.id===id)[0]
      return this.isPhone?(coltype.short_name?coltype.short_name:coltype.collateral_type):coltype.collateral_type;
    }else{
    return  this.collaterals.filter(x=>x.id==id)[0];
    }
      
  }
}
getFieldName(name,col){
  return  this.collateralTypes.filter(c=>c.id==col.get('collateral_type').value)[0].collateral_captured_details.filter(x=>x.title.toLocaleLowerCase().replace(/ /g,"_")==name)[0]?.title
  }
 get isAllCollateralSaved(){
    if(this.loan_profile){
      return this.loan_profile.collateral.length==this.getCollaterals.length; 
    }else{
      return false
    }
   
  }
}
