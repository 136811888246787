import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-static-loading',
  templateUrl: './static-loading.component.html',
  styleUrls: ['./static-loading.component.css']
})
export class StaticLoadingComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
