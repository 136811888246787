
import { Component, KeyValueDiffer, KeyValueDiffers, OnInit, ViewChild, AfterViewInit, ElementRef, TemplateRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { INavData, AppSidebarComponent, AppSidebarNavComponent } from '@coreui/angular';
import { User } from '../models/user.model';
import { AuthService } from '../services/auth.service';
import { LocalStoreManager } from '../services/local-store-manager.service';
import { MenuService } from '../services/menu-service.service';
import { INavBadge } from '@coreui/angular/lib/sidebar/app-sidebar-nav';
import { UserSummaryService } from '../services/user-summary.service';
import { AppSideBarService } from '../services/app-side-bar.service';
import * as cloneDeep from 'lodash/cloneDeep';
import { GOResponse, UserListVM, UserSummary, LoanProfile, AccountDetail } from '../models/app.model';
import { AdminService } from '../services/admin.service';
import { SafeHtml, DomSanitizer } from '@angular/platform-browser';
import { Utilities } from '../services/utilities.service';
import { BsModalService, ModalDirective } from 'ngx-bootstrap/modal';
import { Observable, Subject, of } from 'rxjs';
import { debounce, map, debounceTime, switchMap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  borrowerUrl=`${environment.borrwerUrl}`;
  @ViewChild('#appSidebarNav',{static:true}) sideBar:AppSidebarNavComponent;
  @ViewChild('appSidebar',{static:true}) sideBarT:AppSidebarComponent
  public sidebarMinimized = false;
  private menuDiffers:KeyValueDiffer<string,any>;
  public adminNavItems:INavData[]=[
    {
      name: 'Find Borrower',
      url: '/find-borrower',
      icon: 'cui-magnifying-glass',
      class:'mt-5'
    },{
      name: 'Loans',
      url: '/all-loans',
      icon: 'icon-notebook'
    },
    /* {
      name: 'Approvals',
      url: '/approvals',
      icon: 'icon-control-pause',
    }, */
    {
      name: 'Users',
      url: '/users',
      icon: 'icon-user'
    },   
    {
      name: 'Loan Products',
      url: '/loan-products',
      icon: 'icon-puzzle'
    },
    {
      name: 'Collateral Types',
      url: '/collateral-types',
      icon: 'icon-drawer'
    },
    {
      name: 'Reports',
      url: '/reports',
      icon: 'icon-book-open'
    },
    {
      name: 'Accounts',
      url: '/accounts',
      icon: 'icon-briefcase'
    }/* ,
    {
      name: 'Profile Settings',
      url: '/settings',
      icon: 'icon-settings'
    } */

  ]
  public navItems: INavData[] = []
  navBadge:INavBadge={
    text:'',
    variant:'primary',
    class:'primary'
  }
  public borrowerItems: INavData[] = [
   /*  {
      title: true,
      name: 'Community Credit',
      url:'/',
      class:'text-primary'
    } , */
   {
      name: 'Loans History',
      url: '/as/history',
      icon: 'icon-drawer',
      class:'mt-5'     
    },
    
    /**/ {
      name: 'Loan Collateral',
      url: '/as/loan-collateral',
      icon: 'icon-briefcase'
    }, 
    {
      name: 'Notifications',
      url: '/as/notifications',
      icon: 'icon-bell'
    } /*,
    {
      name: 'Profile Settings',
      url: '/settings',
      icon: 'icon-settings'
    },{
      name: 'Give Feedback',
      url: '/feedback',
      icon: 'icon-speech'
    }  ,
    {
      name: 'Transactions',
      url: '/transactions',
      icon: 'icon-refresh'
    },
    {
      name: 'Loan Book',
      url: '/loans',
      icon: 'icon-notebook'
    },
    {
      name: 'Reports',
      url: '/reports',
      icon: 'icon-graph' 
    },*/
    
  ]
  menu: any='';
  isPhone: boolean;

  toggleMinimize(e,s?) {
    this.sidebarMinimized = e;
    console.log(`the side bar triggered by ${s} is `,e);
    
    
  }
  toggleMenu(){
    if (document.body.classList.contains('sidebar-show')) {
      document.body.classList.toggle('sidebar-show');
     // this.sideBarT.toggleMinimized();
    }else{
         document.body.classList.add('sidebar-show'); 
    }
    this.sideBarT.toggleMinimized();
  }
  isAdmin:boolean=true;
  switchtoAdmin(){
   // console.log("switching to admin tabs");
      this.isAdmin=true
        this.setNav();     
      
    this.router.navigate(['/find-borrower']) 
  }
  switchtoBorrower(){
   // console.log("switching to borrower tabs");
     this.isAdmin=false;  
   this.setNav();
   this.router.navigate(['/as/a-loans']) 
}
currentUser:User;

public borrowers:UserListVM[] = [];
public borrowersCache:UserListVM[] = [];
results$: Observable<any[]>=of([])

    subject = new Subject();
  constructor(private router:Router,private authService:AuthService,private localStoreManager:LocalStoreManager,private menuService:MenuService,
    private differs:KeyValueDiffers,private userSummaryapi:UserSummaryService, private _sanitizer: DomSanitizer, public navService:AppSideBarService,private api:AdminService,
    private modalService:BsModalService,private breakpointObserver:BreakpointObserver) {
      //this.menuService.setMenu('home')
    if(router.url=='/'){
      this.router.navigate(['/find-borrower']) 
    }
    if(router.url.includes('/as/')){
      this.isAdmin=false;
    }
    this.currentUser=this.authService.currentUser;
this.userSummaryapi.SaveSummary();
    
    

    
    this.menuService.getMenu().subscribe((data:any)=>{
      this.menu= data.menulink;
      this.menuDiffers= this.differs.find(this.navItems).create();
    })
    this.breakpointObserver.observe([
      Breakpoints.XSmall,
      Breakpoints.Small,
      Breakpoints.Medium,
      Breakpoints.Large,
      Breakpoints.XLarge
    ]).subscribe( (state: BreakpointState) => {
      //console.log(state)
      if(state.breakpoints[Breakpoints.Small] || state.breakpoints[Breakpoints.XSmall]){
           this.isPhone=true;
         //  this.sidebarMinimized=true;
      }else{
        this.isPhone=false;
      }
   /*    if (state.breakpoints[Breakpoints.XSmall]) {
        console.log( 'Matches XSmall viewport');
   } */
   if (state.breakpoints[Breakpoints.Small]) {
        console.log( 'Matches Small viewport');
   }
   if (state.breakpoints[Breakpoints.Medium]) {
        console.log( 'Matches Medium  viewport');
   }
   if (state.breakpoints[Breakpoints.Large]) {

       console.log( 'Matches Large viewport');
   }
   if (state.breakpoints[Breakpoints.XLarge]) {

      console.log( 'Matches XLarge viewport');   
   }
    });
   }
   loadBorrowers(){
    this.api.GetUsers().subscribe((x:GOResponse<UserListVM[]>)=>{
    
    
      this.borrowers=x.data;
      this.borrowersCache=x.data;
    })
    }
setNav(){
  if(!this.isAdmin){
    //console.log("is admin is ",isadmin);
    this.navItems=this.borrowerItems;
    this.navItems = cloneDeep(this.borrowerItems);
   
    this.navService.setNav(this.navItems);
    
    
    
  }else{
  //  console.log("is admin is ",isadmin);
    this.navItems=this.adminNavItems;
       this.navItems = cloneDeep(this.adminNavItems);
        if(this.authService.currentUser.roles==('Teller'||'Reviewer')){
      this.navItems.splice(2,1);
    }
    this.navService.setNav(this.navItems);
  //  console.log(this.navItems)
     
  } 
}
navclicked($event){
  console.log($event.target.localName)
  if(this.isPhone){
      if($event.target.localName=='app-sidebar-nav-link-content'||$event.target.localName=='a'){
  this.sideBarT.toggleMinimized();
  }
  }

}
hideMobile() {
  if (this.isPhone) {
      if (document.body.classList.contains('sidebar-show')) {
          document.body.classList.toggle('sidebar-show');
          this.sideBarT.toggleMinimized();
      }
  }
};
checkLogo(event){
  console.log("nav",event)
}
getNot(isadmin?:boolean){
  
  this.userSummaryapi.getSummary().subscribe(x=>{
    //console.log("get_notification_set")
   // this.navItems=this.borrowerItems;
   this.currentUser=x.user_profile;
    this.borrowerItems[2]={
      name: 'Notifications',
      url: '/as/notifications',
      icon: 'icon-bell',
      badge: {
        text:x.user_profile.unread_notifications==0?"":x.user_profile.unread_notifications.toLocaleString(),
        variant:'primary',
        class:'primary'
      }
    }
 
     this.setNav() 

    
    
  },e=>{console.log(e)},()=>{
    console.log(this.navItems)
  
  })
 
 
}
  ngOnInit(): void {
    this.getNot(this.isAdmin);
 //   this.loadBorrowers();
    /*this.results$ = of(this.borrowers).pipe(debounceTime(3000),switchMap(s=>this.api.observableSource)) this.subject.pipe(
      debounceTime(3000),
      map(searchText => this.api.observableSource(searchText))
  )*/
    
    
   /* if(this.router.url.includes('/all-loans')){
      this.switchtoAdmin();
    }
    if(this.router.url.includes('/a-loans')){
      this.switchtoBorrower();
    }
 
     if(this.isAdmin==false){
      this.router.navigate(['/a-loans'])  
    } */
  }
  ngDoCheck():void{
    /*   if(this.navItems){
     const changes= this.menuDiffers.diff(this.navItems);
    if(changes){
    if(this.menu=='applying'){
        this.borrowerItems.unshift({
      name: 'Application',
      url: '/apply',
      icon: 'icon-form bg-success',
      class:'mt-5'
        })
      }else{
        if(this.borrowerItems[0].name=='Application'){
          this.borrowerItems.splice(0,1);
        } 
      }
      console.log("changed")
      if(!this.isAdmin){
        Object.assign(this.navItems,this.borrowerItems)
      }else{
        Object.assign(this.navItems,this.adminNavItems)
      }
      
      this.menuService.setMenu(this.navItems)
    } 
    }*/
    
  }
 Logout(){
this.authService.logout();
this.authService.redirectLogoutUser();
this.router.navigate(['/login'])
 }
 ngDestroy(){
  this.menuService.resetMenu();
 }
 summary:UserSummary;
 loan_profile;
 isCollapsed:boolean;
 account_viewed:AccountDetail;
 getLoan(id){
  this.api.GetLoanDetails(id).subscribe((x:GOResponse<LoanProfile>)=>{    
    this.loan_profile=x.data
  })
}
 onValueChange($event:any){

if(this.borrowers.length==0){
  this.loadBorrowers();
}else{

  if( instanceOfUser($event)){
    this.router.navigate(['/borrower',{outlets: {borrower: ['borrowers',$event.id] } }])
   /*  console.log($event)
    this.selected=$event;
  
    this.api.GetUserSummary(this.selected.id).subscribe((x:GOResponse<UserSummary>)=>{
      this.summary=null;
      this.account_viewed=null;
      this.loan_profile=null;
      this.summary= x.data;
      if(this.summary.user_profile?.general_status=='Existing'){
        this.getLoan(this.summary.user_profile.loan_reference);       
      }
      this.account_viewed=x.data.accounts[0]
    })
    this.modal.show();
    console.log("call back executed"); */
  }else{
    this.subject.next($event)
  }
  this.borrowers = this.borrowersCache.filter(r => Utilities.searchArray($event.value, false, r.first_name, r.last_name));
}
 }
 @ViewChild("searchBorrowerInput")
 searchInput: ElementRef;

 clear() {
     this.searchInput.nativeElement.value = '';
     this.onValueChange(this.searchInput.nativeElement.value);
 }
 autocompleListFormatter = (data: UserListVM) : SafeHtml => {
  let html = `<span>${data.first_name} ${data.last_name}</span>`;
  return this._sanitizer.bypassSecurityTrustHtml(html);
}
autocompleValueFormatter(data: UserListVM): string {
 
//this.modal.show()
  return `${data?.first_name} ${data?.last_name}`;
}
customCallback($event){
 
}
get canApprove(){
  return this.authService.currentUser?.roles!='Teller'
}
}
function instanceOfUser(data: any): data is UserListVM {
  
  return 'id' in data;
}