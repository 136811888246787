import { Component, OnInit } from '@angular/core';
import { AdminService } from '../services/admin.service';
import { GOResponse, GOFNotification } from '../models/app.model';
import { UserSummaryService } from '../services/user-summary.service';
import { PaginationInstance } from 'ngx-pagination';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.css']
})
export class NotificationsComponent implements OnInit {
  notifys: GOFNotification[]=[];
collapses:any[]=[];
isLoading:boolean=false;
public configSub: PaginationInstance = {
  id: 'notifications',
  itemsPerPage: 20,
  currentPage: 1
};
  constructor(private api:AdminService, private user_summary:UserSummaryService) {
        this.loadNotifications();
   }

  ngOnInit(): void {

  }
loadNotifications(){
  this.isLoading=true;
  this.api.GetNotifications().subscribe((x:GOResponse<GOFNotification[]>)=>{
  this.notifys= x.data
  this.isLoading=false;

  })
 
}
prevSelected:number=0;
collapseNot(i:number,n:GOFNotification){
  
  if(!n.read){
    this.api.FlaggingAsRead(n.id).subscribe(x=>{
      this.user_summary.SaveSummary();
      this.loadNotifications();
    })
  }
    if(this.collapses[i]){
      this.collapses[i]=!this.collapses[i]
    }else{
      this.collapses[i]=true
    }
    if(this.prevSelected){
      if(this.prevSelected!=i){
        this.collapses[this.prevSelected]=false;
      }
    }else{
      if(this.prevSelected!=i){
        this.collapses[this.prevSelected]=false;
      }
    }
    this.prevSelected=i;
}
collapsePrev(){
  this.collapses[this.prevSelected]=false; 
}
}
