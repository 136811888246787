import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { GOResponse, UserSummary } from '../models/app.model';
import { AdminService } from './admin.service';

@Injectable({
  providedIn: 'root'
})
export class UserSummaryService {
private summary = new Subject<UserSummary>();
has_summary:boolean=false;
current_summary:UserSummary;
  constructor(private api:AdminService) { }

  SaveSummary(){
 return this.api.GetCurrentUserSummary().subscribe((x:GOResponse<UserSummary>)=>{
   this.summary.next(x.data);
   this.has_summary=true;
   this.current_summary=x.data;
 });
  }
  setCurrentSummary(summary:UserSummary){
    this.summary.next(summary);
  }
  getSummary(){
    return this.summary.asObservable();
  }
  clearSummary(){
    return this.summary.next();
  }
}
