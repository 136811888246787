import { Component, OnInit, TemplateRef } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-transactions',
  templateUrl: './transactions.component.html',
  styleUrls: ['./transactions.component.css']
})
export class TransactionsComponent implements OnInit {
TransactionModalRef:BsModalRef
  constructor(private modalService: BsModalService) { }

  ngOnInit(): void {
  }
  TransactionDetailCancelled(){
this.TransactionModalRef.hide();
  }
  ViewTransactionDetail(template: TemplateRef<any>){
this.TransactionModalRef= this.modalService.show(template)
  }
}
