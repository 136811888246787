import { Component, OnInit, TemplateRef, ViewChild, AfterViewInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ConfirmationDialogComponent } from '../common/confirmation-dialog/confirmation-dialog.component';
import { CollateralVM, GOResponse, UserSummary } from '../models/app.model';
import { AdminService } from '../services/admin.service';
import { UserSummaryService } from '../services/user-summary.service';
import { ActivatedRoute } from '@angular/router';
import { Breakpoints, BreakpointObserver, BreakpointState } from '@angular/cdk/layout';

@Component({
  selector: 'app-loan-collateral',
  templateUrl: './loan-collateral.component.html',
  styleUrls: ['./loan-collateral.component.css']
})
export class LoanCollateralComponent implements OnInit,AfterViewInit {

  message:any;
  isSaving:boolean=false;

 CollateralSubmissionForm:FormGroup;
 CustomFields:FormGroup;

  isSubmitted:boolean=false;
  CollateralModalRef: any;
  collaterals: CollateralVM[]=[];
  modalRef: any;
  CollateralViewModalRef: any;
  user_summary: UserSummary;
  @ViewChild('NewCollateralModal', { static: true }) newCollateralModal: TemplateRef<any>;
  isPhone: boolean;

  constructor(private api:AdminService,private modalService: BsModalService,private fb:FormBuilder,
    private route:ActivatedRoute, private userSummaryapi:UserSummaryService,private breakpointObserver:BreakpointObserver) {
      this.breakpointObserver.observe([
        Breakpoints.XSmall,
        Breakpoints.Small,
        Breakpoints.Medium,
        Breakpoints.Large,
        Breakpoints.XLarge
      ]).subscribe( (state: BreakpointState) => {
        //console.log(state)
        if(state.breakpoints[Breakpoints.Small] || state.breakpoints[Breakpoints.XSmall]){
             this.isPhone=true;
        }else{
          this.isPhone=false;
        }
      });
   
   }
   GetUserProfile(){
    this.api.GetCurrentUserSummary().subscribe((x:GOResponse<UserSummary>)=>{
   
      this.user_summary=x.data;
      //console.log(x.data);
      
    })
   }
   initCollateralSubmission(){
    return this.fb.group({
      id:[''],
      loan:[''],
      collateral_type:['',Validators.required],     
      check_form:['', Validators.requiredTrue],
      leaf_number:[''],
      cheque_name:[''],
      bank:[''],
      number_plate:[''],
      car_picture:[''],
      location:[''],
      collateral_description:['CUSTOM_DESCRIPTION'],
      collateral_identification_number:['CUSTOM_NUMBER'],
      collateral_value:['',Validators.required],
      collateral_file:[''],
     // custom_information:this.fb.array([])
    })
  }
  ngAfterViewInit(){
    console.log(this.newCollateralModal)
    this.route.params.subscribe(x=>{
      if(x['new']){
        console.log(x)
        this.CreateNewCollateral(this.newCollateralModal);
      }
    })
  }
  ngOnInit(): void {
   
    this.GetUserProfile();
  
    this.loadCollaterals();
    
  }
  loadCollaterals(){
    this.api.GetCollateral().subscribe((x:GOResponse<CollateralVM[]>)=>{
this.collaterals=x.data;
    })
  }
  CreateNewCollateral(template: TemplateRef<any>){
    this.CollateralSubmissionForm= this.initCollateralSubmission();
    this.CollateralModalRef= this.modalService.show(template,{backdrop:'static',class:"modal-lg"+this.isPhone?'':" w-50"});
  }
CancelNewCollateralModal(){
  this.isSubmitted=false;
  this.CollateralModalRef.hide();
}
SaveCollateral(){
  let colObject=this.CollateralSubmissionForm.value;
  if(this.CustomFields.value){
    Object.assign(colObject, {custom_information: JSON.stringify([this.CustomFields.value])}); 
  }
  this.api.PosttoCollateralBank(colObject).subscribe(x=>{
    this.loadCollaterals();
  })
}
selected:any;
DeleteCollateral(c_t:CollateralVM){
  this.selected=c_t;
  this.modalRef = this.modalService.show(ConfirmationDialogComponent, {
    initialState: {
      prompt: `Are you sure you want to delete <b>${c_t?.collateral_type.collateral_type}</b>?`,
      has_reason:true,
      reason_required:true,
      callback: (result) => {
        if (result.confirmation == 'yes'){
          this.api.DeleteCollateral(c_t?.id,result?.reason).subscribe(x=>{
            this.loadCollaterals();
            this.selected=null;
          })
        }
        if (result.confirmation == 'no'){
          this.selected=null;
        }
      }
    },
    backdrop:'static',
    keyboard:false
  })
}
collateralView:CollateralVM;
View(c:CollateralVM,template: TemplateRef<any>){
  this.collateralView=c;
  this.CollateralViewModalRef= this.modalService.show(template,{backdrop:'static',class:"modal-lg"+this.isPhone?'':" w-50"});
}
CloseCollateralViewModel(){
  this.CollateralViewModalRef.hide();
}
getFieldName(name){
return  this.collateralView.collateral_type.collateral_captured_details.filter(x=>x.title.toLocaleLowerCase().replace(/ /g,"_")==name)[0]?.title
}
}
