import { Component, OnInit } from '@angular/core';
import { PrintReportService } from '../../services/print-report.service';
import { ReportFilter } from '../../models/app.model';

@Component({
  selector: 'app-debt-aging-report',
  templateUrl: './debt-aging-report.component.html',
  styleUrls: ['./debt-aging-report.component.css']
})
export class DebtAgingReportComponent implements OnInit {

  reportData:any;
  filter:ReportFilter;
  constructor( private printService:PrintReportService) { 

    this.reportData=printService.printTemplate.response;
    this.filter=this.printService.printTemplate.filter;
    this.printService.onDataReady();
  }

  ngOnInit(): void {
  }

}
