import { Component, OnInit, TemplateRef } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-loans',
  templateUrl: './loans.component.html',
  styleUrls: ['./loans.component.css']
})
export class LoansComponent implements OnInit {

  TransactionModalRef:BsModalRef
  constructor(private modalService: BsModalService) { }

  ngOnInit(): void {
  }
  TransactionDetailCancelled(){
this.TransactionModalRef.hide();
  }
  ViewTransactionDetail(template: TemplateRef<any>){
this.TransactionModalRef= this.modalService.show(template,{class:'modal-lg'})
  }
}
